import {
  ResponseApiException,
  ResponseApiType,
} from "../models/responseApiType"
import { fr } from "date-fns/locale"
import { differenceInMinutes, format } from "date-fns"
import { StatusType } from "../models/partnerRequest"

export function getMessageFromType(resp: ResponseApiException | any) {
  if (resp.data) {
    switch (resp.data.type) {
      case ResponseApiType.BadCredentials:
        return "Identifiants invalides"
      case ResponseApiType.TokenExpired:
        return "Votre session a expiré"
      case ResponseApiType.CustomError:
        return resp.data?.detail
      default:
        let msg =
          resp.status === 403
            ? "Accès refusé"
            : "Une erreur interne s'est produite"
        return msg
    }
  }

  return "Une erreur s'est produite"
}

export function strToDate(dateStr: string) {
  return Date.parse(dateStr) ?? null
}

export function formatStrToDate(
  dateStr?: string,
  formatStr: string = "dd/MM/yyyy"
) {
  if (!dateStr) return ""
  let date = strToDate(dateStr)

  return date ? format(date, formatStr, { locale: fr }) : null
}

export function cutText(text: string, length: number = 100) {
  return text.length >= length ? `${text.substring(0, length)}[...]` : text
}

export function getDifferenceInMinutes(dateStr: string) {
  let date = strToDate(dateStr)
  const minutes = differenceInMinutes(date, new Date())
  return minutes
}

export enum RoleType {
  RoleSuperAdmin = "ROLE_SUPER_ADMIN",
  RoleAdmin = "ROLE_ADMIN",
  RolePartner = "ROLE_PARTNER",
}

export function isGranted(roles: string[] | undefined, matchRole: RoleType) {
  if (!roles) return
  if (roles.includes(matchRole)) return true

  if (matchRole === RoleType.RoleAdmin) {
    return roles.includes(RoleType.RoleSuperAdmin)
  }

  if (matchRole === RoleType.RolePartner) {
    return (
      roles.includes(RoleType.RoleSuperAdmin) ||
      roles.includes(RoleType.RoleAdmin)
    )
  }
}

export function getFirstDayOfYear(year: number) {
  return new Date(year, 0, 1)
}

export function getFirstDayOfCurrentYear() {
  const currentYear = new Date().getFullYear()
  return getFirstDayOfYear(currentYear)
}

export function getLastDayOfYear(year: number) {
  return new Date(year, 11, 31)
}

export function getLastDayOfCurrentYear() {
  const currentYear = new Date().getFullYear()
  return getLastDayOfYear(currentYear)
}

export function getStatusColor(status: StatusType) {
  switch (status) {
    case StatusType.TO_HANDLE:
      return "grey"
    case StatusType.PENDING:
      return "orange"
    case StatusType.ARCHIVED:
      return "#2b2b5e"
    case StatusType.TRAITED:
      return "green"
    default:
      return "white"
  }
}

export function getStatusLabel(status: StatusType | string) {
  switch (status) {
    case StatusType.TO_HANDLE:
      return "À traiter"
    case StatusType.PENDING:
      return "En cours"
    case StatusType.TRAITED:
      return "Traitée"
    case StatusType.ARCHIVED:
      return "Archivée"
    default:
      return status
  }
}

export function formatDecimal(num: number, nbDecimal: number = 2) {
  return (Math.round(num * 100) / 100).toFixed(nbDecimal)
}

export function formatPrice(num: number | undefined, space = false) {
  if (num === undefined) return ""
  let nb = formatDecimal(num)
  if (!nb || nb == "NaN") {
    return ""
  }

  let str = nb.toString()

  let numbers = str?.split(".")

  if (!numbers || numbers.length === 0) {
    return ""
  }
  if (space)
    return numbers.length > 1
      ? `${parseInt(numbers[0]).toLocaleString()}€${numbers[1]}`
      : numbers[0]
  else return numbers.length > 1 ? `${numbers[0]}€${numbers[1]}` : numbers[0]
}
