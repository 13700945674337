import PartnerSubscription from "../models/partnerSubscription"
import PartnerSubscriptionPagination from "../models/partnerSubscriptionPagination"
import { apiSlice, TAG_PARTNER_SUB } from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubInfos: builder.query<PartnerSubscription, number>({
      query: (id) => ({
        url: `/admin/administrator/partner-subscriptions/${id}`,
      }),
      providesTags: (result) => [
        { type: TAG_PARTNER_SUB, id: result?.id },
        { type: TAG_PARTNER_SUB, id: "LIST" },
      ],
    }),
    deleteSub: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `/admin/administrator/partner-subscriptions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TAG_PARTNER_SUB, id: "LIST" },
      ],
    }),
    getPartnerSubscriptionsWithPagination: builder.query<
      PartnerSubscriptionPagination,
      {
        limit: number
        page: number
        orderBy: string
        direction: string
        from: string
        to: string
      }
    >({
      query: (param) => {
        return {
          url: `admin/administrator/partner-subscriptions`,
          params: param,
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: `${TAG_PARTNER_SUB}` as const,
                id,
              })),
              { type: TAG_PARTNER_SUB, id: "LIST" },
            ]
          : [{ type: TAG_PARTNER_SUB, id: "LIST" }],
    }),
  }),
})

export const {
  useGetPartnerSubscriptionsWithPaginationQuery,
  useGetSubInfosQuery,
  useDeleteSubMutation,
} = extendedApiSlice
