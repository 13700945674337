import { Fragment, useEffect, useState } from "react"
import {
  IconButton,
  InputBase,
  ListItem,
  Paper,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import styled from "@emotion/styled"
import colors from "../../../style/colors"
import GlobalBgStyle from "../../../style/GlobalBgStyle"
import Loader from "../../../components/Loader"
import { css } from "@emotion/css"
import InternError from "../../../components/InternError"
import {
  useDeleteSubMutation,
  useGetPartnerSubscriptionsWithPaginationQuery,
} from "../../../features/partnerSubscriptionSlice"
import PartnerSubscription, {
  getStatusTypeLabel,
  StatusSubType,
} from "../../../models/partnerSubscription"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import frLocale from "date-fns/locale/fr"
import {
  formatStrToDate,
  getFirstDayOfCurrentYear,
  getLastDayOfCurrentYear,
  getMessageFromType,
} from "../../../utils/utils"
import { format } from "date-fns"
import { useNavigate } from "react-router-dom"
import ValidateDialog from "../../../components/ValidateDialog"
import { toast } from "react-toastify"
import { ResponseApiException } from "../../../models/responseApiType"

const StyledDiv = styled.div`
  p {
    margin: 0;
    padding: 0;
  }
  h1 {
    font-size: 1.4rem;
    color: ${colors.blue};
    text-align: center;
    margin-bottom: 2rem;
  }

  @media all and (min-width: 768px) {
    h1 {
      font-size: 1.7rem;
    }
  }
`

const AlignItemsList = ({
  subs,
  onItemClick,
  onRemove,
}: {
  subs: PartnerSubscription[]
  onItemClick: (id: number) => void
  onRemove: (sub: PartnerSubscription) => void
}) => (
  <div className="lg:w-2/3 w-full">
    {subs.map((sub: PartnerSubscription) => (
      <Fragment key={sub.id}>
        <div className="flex flex-row bg-white items-center">
          <ListItem
            alignItems="flex-start"
            className="justify-center flex-wrap md:flex-nowrap cursor-pointer p-0 my-2"
            onClick={() => onItemClick(sub.id)}
          >
            <div className="bg-white px-4 py-2 flex flex-col items-center w-full space-y-2">
              <div className="w-full flex lg:flex-row flex-col lg:space-y-0 space-y-3">
                <div className="lg:w-1/2 w-full flex flex-col items-start">
                  <p className="text-lg font-semibold">{sub.companyName}</p>
                  <p>{sub.email}</p>
                  <p>{sub.phone}</p>
                  <p>
                    Demande effetué le{" "}
                    {formatStrToDate(sub.createdAt, "dd/MM/yyyy")}
                  </p>
                </div>

                <div className="lg:w-1/2 w-full flex lg:justify-end justify-center items-center">
                  {" "}
                  {sub.status === "ToHandle" ? (
                    <p className="bg-[#777777] text-white rounded-full px-2">
                      {getStatusTypeLabel(StatusSubType.TO_HANDLE)}
                    </p>
                  ) : sub.status === "Approved" ? (
                    <p className="bg-[#007700] text-white rounded-full px-2">
                      {getStatusTypeLabel(StatusSubType.APPROVED)}
                    </p>
                  ) : (
                    <p className="bg-[#CC0000] text-white rounded-full px-2">
                      {getStatusTypeLabel(StatusSubType.DISAPPROVED)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </ListItem>
          <div className="px-4">
            <Tooltip title="Supprimer">
              <IconButton
                onClick={() => onRemove(sub)}
                aria-label="supprimer"
                color="error"
              >
                <FontAwesomeIcon icon={faTrash} color="#e70202" size="xs" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Fragment>
    ))}
  </div>
)

function Subscriptions() {
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState("")
  const [skip, setSkip] = useState(false)
  const [order, setOrder] = useState({
    orderBy: "companyName",
    direction: "ASC",
  })

  const [from, setFrom] = useState<Date | null>(getFirstDayOfCurrentYear())
  const [to, setTo] = useState<Date | null>(getLastDayOfCurrentYear())
  const [fromDate, setFromDate] = useState<string | null>()
  const [toDate, setToDate] = useState<string | null>()

  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [subSelected, setSubSelected] = useState<PartnerSubscription | null>(
    null
  )

  const [deleteSub, { isLoading: isDeleteLoading }] = useDeleteSubMutation()

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    setOrder({
      orderBy: selectedColumn.sortField,
      direction: sortDirection,
    })
  }

  const handleDelete = () => {
    if (subSelected?.id) {
      deleteSub(subSelected?.id)
        .unwrap()
        .then(() => {
          setShowConfirmDelete(false)
          toast.success("La demande de partenaire a bien été supprimée")
        })
        .catch((error: ResponseApiException) => {
          let msg = getMessageFromType(error)
          toast.error(msg)
        })
    }
  }

  const handleRemove = (sub: PartnerSubscription) => {
    setSubSelected(sub)
    setShowConfirmDelete(true)
  }

  const {
    data: subPaginate = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetPartnerSubscriptionsWithPaginationQuery(
    {
      limit: rowsPerPage,
      page: page,
      orderBy: order.orderBy,
      direction: order.direction,
      search: searchTerm,
      from: fromDate,
      to: toDate,
    },
    { skip: skip || !fromDate || !toDate }
  )

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSkip(false)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  useEffect(() => {
    try {
      let date1 = from ? format(from, "yyyy-MM-dd HH:mm:ss") : null
      let date2 = to ? format(to, "yyyy-MM-dd HH:mm:ss") : null
      setFromDate(date1)
      setToDate(date2)
      console.log(fromDate, toDate)
    } catch (error) {
      console.error(error)
    }
  }, [from, to])

  const navigate = useNavigate()
  let content

  if (isLoading) {
    content = <Loader />
  } else if (isError) {
    content = <InternError />
  } else if (isSuccess) {
    content =
      subPaginate.items && subPaginate.items.length > 0 ? (
        <AlignItemsList
          subs={subPaginate.items}
          onItemClick={(id: number) => navigate(`/admin/inscriptions/${id}`)}
          onRemove={(sub: PartnerSubscription) => handleRemove(sub)}
        />
      ) : (
        "Aucun résultat"
      )
  }

  return (
    <StyledDiv className="container">
      <GlobalBgStyle />
      <h1>Demandes d'inscription</h1>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          maxWidth: 400,
          marginBottom: "1rem",
        }}
        className="ms-auto"
        onSubmit={(e: any) => {
          e.preventDefault()
          setSkip(false)
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, width: "100%" }}
          placeholder="Rechercher"
          inputProps={{ "aria-label": "rechercher" }}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setSkip(true)
          }}
        />
        <IconButton
          type="submit"
          sx={{ p: "10px" }}
          aria-label="recherche"
          onClick={() => setSkip(false)}
        >
          <FontAwesomeIcon icon={faSearch} />
        </IconButton>
      </Paper>
      <div className="my-4">
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={frLocale}
        >
          <div className="d-flex flex-wrap gap-5 justify-content-center">
            <DatePicker
              label="Du"
              value={from}
              onChange={(newValue: any) => {
                setFrom(newValue)
              }}
              className="datePicker"
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "jj/mm/aaaa",
                  }}
                />
              )}
            />
            <DatePicker
              label="au"
              value={to}
              onChange={(newValue: any) => {
                setTo(newValue)
              }}
              className="datePicker"
              minDate={from}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "jj/mm/aaaa",
                  }}
                />
              )}
            />
          </div>
        </LocalizationProvider>
      </div>
      <div className="w-full flex justify-center">{content}</div>
      <TablePagination
        component="div"
        count={subPaginate?.totalItems ?? 0}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 20, 50, 100]}
        sx={{
          flexWrap: { xs: "wrap" },
          justifyContent: "center",
          pt: 4,
        }}
        className={css`
          .MuiTablePagination-toolbar {
            @media (max-width: 600px) {
              flex-wrap: wrap;
              justify-content: center;
            }
            align-items: baseline;
          }
        `}
      />
      <ValidateDialog
        show={showConfirmDelete}
        handleClose={() => {
          setSubSelected(null)
          setShowConfirmDelete(false)
        }}
        modalTitle="Suppression définitive"
        handleConfirm={handleDelete}
        loading={isDeleteLoading}
      >
        Êtes-vous sûr de vouloir supprimer l'utilisateur{" "}
        <span className="font-bold">{subSelected?.companyName}</span> ? La
        suppression est définitive.
      </ValidateDialog>
    </StyledDiv>
  )
}

export default Subscriptions
