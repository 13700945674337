import { Link } from "react-router-dom"

function NoMatch() {
  return (
    <div className="container mx-auto">
      <h2>La page n'a pas été trouvée</h2>
      <p>
        <Link to="/">Retour à la page d'accueil</Link>
      </p>
    </div>
  )
}

export default NoMatch
