import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Step,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import HelpIcon from "@mui/icons-material/Help"
import { useEffect, useState } from "react"
import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/bg-diags.png"
import arrow from "../../assets/down-arrow.png"
import gif from "../../assets/thumbs-up.gif"
import callImg from "../../assets/call.png"
import colors from "../../style/colors"
import ConstructionIcon from "@mui/icons-material/Construction"
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt"
import CloudIcon from "@mui/icons-material/Cloud"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { Form } from "react-bootstrap"
import { LoadingButton } from "@mui/lab"
import { API } from "../../utils/API"
import { toast } from "react-toastify"
import { ResponseApiException } from "../../models/responseApiType"
import { getMessageFromType } from "../../utils/utils"
import File from "../../models/file"

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(1 99 152) 0%, #2993cd 50%, #6bcbff 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(1 99 152) 0%, #2993cd 50%, #6bcbff 100%)",
  }),
}))

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  const icons: { [index: string]: React.ReactElement } = {
    1: <HelpIcon />,
    2: <ConstructionIcon />,
    3: <ElectricBoltIcon />,
    4: <CloudIcon />,
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

function Diagnostics() {
  const [activeStep, setActiveStep] = useState(0)

  const [formState, setFormState] = useState({} as any)
  const [isLoading, setIsLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [postDocuments, setPostDocuments] = useState([] as File[])

  const navigate = useNavigate()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
    setFormState({})
    setValidated(false)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
      return
    } else {
      setIsLoading(true)
      API.post("public-request", { ...formState, documents: postDocuments })
        .then((response: any) => {
          toast.success(
            "Un mail contenant les diagnostics à réaliser vous a été envoyé."
          )
          setValidated(false)
          setIsSuccess(true)
        })
        .catch((error: any) => {
          let response: ResponseApiException = error.response
          let msg = getMessageFromType(response)
          toast.error(msg)
          setIsSuccess(false)
        })
        .finally(() => setIsLoading(false))
    }
  }

  const convertToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleDocumentsUpload = (e: any) => {
    setPostDocuments([])
    const files = e.target.files
    Object.entries(files).forEach(async ([key, file]) => {
      await convertToBase64(file)
        .then((result) => {
          setPostDocuments((imgArr) => [
            ...imgArr,
            {
              data: result,
              name: "",
              alt: "",
              id: 0,
              fileBlob: file,
            } as File,
          ])
        })
        .catch((error) => console.error(error))
    })
  }

  useEffect(() => {
    console.log(activeStep)
  }, [activeStep])

  let content = (
    <Box
      sx={{
        width: {
          xs: 300,
          lg: 600,
        },
      }}
    >
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step index={0}>
          <StepLabel
            StepIconComponent={ColorlibStepIcon}
            optional={
              formState.action ? (
                <Typography variant="caption">
                  {formState.action === "rent"
                    ? `Louer ${
                        formState.type === "housel"
                          ? "maison"
                          : formState.type === "flatl"
                          ? "appartement"
                          : formState.type === "prol"
                          ? "bâtiment pro / immeuble"
                          : ""
                      }`
                    : formState.action === "sell"
                    ? `Vendre ${
                        formState.type === "housev"
                          ? "maison"
                          : formState.type === "flatv"
                          ? "appartement"
                          : formState.type === "prov"
                          ? "bâtiment pro / immeuble"
                          : ""
                      }`
                    : ""}
                </Typography>
              ) : null
            }
          >
            <span className="text-xl fw-semibold">Location ou vente</span>
          </StepLabel>
          <StepContent>
            <Typography>Vous souhaitez...</Typography>
            <div className="flex gap-2 my-2">
              <ToggleButtonGroup
                value={formState.action}
                exclusive
                onChange={(event, newValue) => {
                  setFormState((prev: any) => ({ ...prev, action: newValue }))
                }}
                aria-label="text alignment"
              >
                <ToggleButton value="sell" aria-label="vendre" color="info">
                  Vendre
                </ToggleButton>
                <ToggleButton value="rent" aria-label="louer" color="info">
                  Louer
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            {formState.action === "sell" && (
              <>
                <Typography>Quel type de bien vendez-vous ?</Typography>
                <div className="flex gap-2 my-2">
                  <ToggleButtonGroup
                    value={formState.type}
                    exclusive
                    onChange={(event, newValue) => {
                      setFormState((prev: any) => ({
                        ...prev,
                        type: newValue,
                      }))
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value="housev"
                      aria-label="vendre"
                      color="info"
                    >
                      Maison
                    </ToggleButton>
                    <ToggleButton
                      value="flatv"
                      aria-label="vendre"
                      color="info"
                    >
                      Appartement
                    </ToggleButton>
                    <ToggleButton value="prov" aria-label="vendre" color="info">
                      Bâtiment pro ou immeuble
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </>
            )}
            {formState.action === "rent" && (
              <>
                <Typography>Quel type de bien louez-vous ?</Typography>
                <div className="flex gap-2 my-2">
                  <ToggleButtonGroup
                    value={formState.type}
                    exclusive
                    onChange={(event, newValue) => {
                      setFormState((prev: any) => ({
                        ...prev,
                        type: newValue,
                      }))
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value="housel"
                      aria-label="louer"
                      color="info"
                    >
                      Maison
                    </ToggleButton>
                    <ToggleButton value="flatl" aria-label="louer" color="info">
                      Appartement
                    </ToggleButton>
                    <ToggleButton value="prol" aria-label="louer" color="info">
                      Bâtiment pro ou immeuble
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </>
            )}
            {!(
              formState.action &&
              (formState.type === "prov" || formState.type === "prol")
            ) && (
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="outlined"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={
                      !formState.action ||
                      (formState.action === "sell" && !formState.type)
                    }
                    startIcon={<FontAwesomeIcon icon={faArrowRight} />}
                  >
                    Suivant
                  </Button>
                  <Button disabled onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    Retour
                  </Button>
                </div>
              </Box>
            )}
          </StepContent>
        </Step>
        {!(
          formState.action === "sell" &&
          (formState.type === "prov" || formState.type === "prol")
        ) && (
          <>
            {(activeStep > 0 || formState.construct) && (
              <Step index={1}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  optional={
                    formState.construct ? (
                      <Typography variant="caption">
                        {formState.construct === "before"
                          ? "Avant 1949"
                          : formState.construct === "pending"
                          ? `Entre 1949 et le 30/06/1997 ${
                              formState.action === "rent" &&
                              formState.buildingCo
                                ? " - Immeuble collectif"
                                : ""
                            }`
                          : formState.construct === "after"
                          ? `Après le 30/06/1997 ${
                              formState.action === "rent" &&
                              formState.buildingCo
                                ? " - Immeuble collectif"
                                : ""
                            }`
                          : ""}
                      </Typography>
                    ) : null
                  }
                >
                  <span className="text-xl fw-semibold">
                    Date de construction
                  </span>
                </StepLabel>
                <StepContent>
                  <Typography>Quand a été construit votre bien ?</Typography>
                  <div className="flex gap-2 my-2">
                    <ToggleButtonGroup
                      value={formState.construct}
                      exclusive
                      onChange={(event, newValue) => {
                        setFormState((prev: any) => ({
                          ...prev,
                          construct: newValue,
                        }))
                      }}
                      aria-label="text alignment"
                    >
                      <ToggleButton
                        value="before"
                        aria-label="avant 1949"
                        color="info"
                      >
                        Avant 1949
                      </ToggleButton>
                      <ToggleButton
                        value="pending"
                        aria-label="entre 1949 et 30/06/1997"
                        color="info"
                      >
                        Entre 1949 et le 30/06/1997
                      </ToggleButton>
                      <ToggleButton
                        value="after"
                        aria-label="après le 30/06/1997"
                        color="info"
                      >
                        Après le 30/06/1997
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  {formState.action === "rent" &&
                    (formState.construct === "after" ||
                      formState.construct === "pending") && (
                      <>
                        <Typography>Est-ce un immeuble collectif ? </Typography>
                        <div className="flex gap-2 myb-2">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={formState.buildingCo ?? false}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFormState((prev: any) => ({
                                    ...prev,
                                    buildingCo: event.target.checked,
                                  }))
                                }}
                              />
                            }
                            label={formState.buildingCo ? "Oui" : "Non"}
                          />
                        </div>
                      </>
                    )}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="outlined"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={!formState.construct}
                        startIcon={<FontAwesomeIcon icon={faArrowRight} />}
                      >
                        Suivant
                      </Button>
                      <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Retour
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            )}
            {(activeStep > 1 || formState.install) && (
              <Step index={2}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  optional={
                    <Typography variant="caption">
                      {formState.elec ? (
                        <>
                          Installation électrique présente
                          {formState.elec15
                            ? " depuis plus de 15 ans"
                            : " depuis moins de 15 ans"}
                        </>
                      ) : (
                        "Pas d'intallation électrique"
                      )}
                    </Typography>
                  }
                >
                  <span className="text-xl fw-semibold">
                    Installation électrique
                  </span>
                </StepLabel>
                <StepContent>
                  <Typography>
                    Possédez-vous une installation électrique ?
                  </Typography>
                  <div className="flex gap-2 myb-2">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formState.elec ?? false}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFormState((prev: any) => ({
                              ...prev,
                              elec: event.target.checked,
                            }))
                          }}
                        />
                      }
                      label={formState.elec ? "Oui" : "Non"}
                    />
                  </div>
                  {formState.elec ? (
                    <>
                      <Typography>
                        Cette installation a-t-elle de plus de 15 ans ?
                      </Typography>
                      <div className="flex gap-2 myb-2">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formState.elec15 ?? false}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFormState((prev: any) => ({
                                  ...prev,
                                  elec15: event.target.checked,
                                }))
                              }}
                            />
                          }
                          label={formState.elec15 ? "Oui" : "Non"}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="outlined"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                        startIcon={<FontAwesomeIcon icon={faArrowRight} />}
                      >
                        Suivant
                      </Button>
                      <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Retour
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            )}
            {(activeStep > 2 || formState.install) && (
              <Step index={3}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  optional={
                    <Typography variant="caption">
                      {formState.gaz ? (
                        <>
                          Installation de gaz fixe présente
                          {formState.gaz15
                            ? " depuis plus de 15 ans"
                            : " depuis moins de 15 ans"}
                        </>
                      ) : (
                        "Pas d'intallation de gaz fixe"
                      )}
                    </Typography>
                  }
                >
                  <span className="text-xl fw-semibold">
                    Installation de gaz
                  </span>
                </StepLabel>
                <StepContent>
                  <Typography>
                    Possédez-vous une installation gaz fixe ?
                  </Typography>
                  <div className="flex gap-2 myb-2">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formState.gaz ?? false}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFormState((prev: any) => ({
                              ...prev,
                              gaz: event.target.checked,
                            }))
                          }}
                        />
                      }
                      label={formState.gaz ? "Oui" : "Non"}
                    />
                  </div>
                  {formState.gaz ? (
                    <>
                      <Typography>
                        Cette installation a-t-elle de plus de 15 ans ?
                      </Typography>
                      <div className="flex gap-2 myb-2">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formState.gaz15 ?? false}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFormState((prev: any) => ({
                                  ...prev,
                                  gaz15: event.target.checked,
                                }))
                              }}
                            />
                          }
                          label={formState.gaz15 ? "Oui" : "Non"}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="outlined"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                        startIcon={<FontAwesomeIcon icon={faArrowRight} />}
                      >
                        Suivant
                      </Button>
                      <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Retour
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            )}
          </>
        )}
      </Stepper>
      {formState.action === "sell" && formState.type === "prov" && (
        <div className="flex flex-col items-center my-4">
          <p>
            Ceci étant un cas plus spécifique, nous ne pouvons pas vous dire les
            diagnostics nécessaires. Vous pouvez cependant nous contacter via
            notre formulaire de contact, nous serons ravis de répondre à vos
            questions.
          </p>
          <img src={callImg} alt="contactez nous" className="w-25 mb-2" />
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/contact")}
          >
            Contactez-nous
          </Button>
        </div>
      )}
      {formState.action === "rent" && formState.type === "prol" && (
        <div className="flex flex-col items-center my-4">
          <p>
            Ceci étant un cas plus spécifique, nous ne pouvons pas vous dire les
            diagnostics nécessaires. Vous pouvez cependant nous contacter via
            notre formulaire de contact, nous serons ravis de répondre à vos
            questions.
          </p>
          <img src={callImg} alt="contactez nous" className="w-25 mb-2" />
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/contact")}
          >
            Contactez-nous
          </Button>
        </div>
      )}
      {activeStep === 4 && (
        <Paper square elevation={0} sx={{ p: 3, mt: 2 }}>
          {!isSuccess && (
            <>
              <Typography>
                Vous avez répondu à toutes les questions. Afin de recevoir par
                mail les diagnostics à réaliser merci de renseigner les
                informations suivantes :
              </Typography>{" "}
              <Form
                validated={validated}
                onSubmit={handleSubmit}
                noValidate
                className="mt-3"
              >
                <Form.Group className="mb-3">
                  <Form.Label>Email *</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Votre email"
                    required
                    onChange={({ target }) => {
                      setFormState((prev: any) => ({
                        ...prev,
                        email: target.value,
                      }))
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Ce champ est requis
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className=" mb-3">
                  <Form.Label>Nom *</Form.Label>
                  <Form.Control
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder="Votre nom"
                    required
                    onChange={({ target }) => {
                      setFormState((prev: any) => ({
                        ...prev,
                        lastname: target.value,
                      }))
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Ce champ est requis
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className=" mb-3">
                  <Form.Label>Prénom *</Form.Label>
                  <Form.Control
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder="Votre prénom"
                    required
                    onChange={({ target }) => {
                      setFormState((prev: any) => ({
                        ...prev,
                        firstname: target.value,
                      }))
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Ce champ est requis
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className=" mb-3">
                  <Form.Label>Numéro de téléphone </Form.Label>
                  <Form.Control
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="optionnel"
                    onChange={({ target }) => {
                      setFormState((prev: any) => ({
                        ...prev,
                        phone: target.value,
                      }))
                    }}
                  />
                </Form.Group>
                <Form.Group className=" mb-3">
                  <Form.Check type="checkbox" id="consent">
                    <Form.Check.Input
                      type="checkbox"
                      name="consent"
                      required
                      onChange={({ target }) => {
                        setFormState((prev: any) => ({
                          ...prev,
                          consent: target.value === "on",
                        }))
                      }}
                    />
                    <Form.Check.Label>
                      En soumettant ce formulaire, j'accepte que les
                      informations saisies soient exploitées dans le cadre de la
                      demande de contact et de la relation commerciale qui peut
                      en découler.*
                    </Form.Check.Label>
                    <Form.Control.Feedback type="invalid">
                      Vous devez accepter l'envoi de vos données
                    </Form.Control.Feedback>
                  </Form.Check>
                </Form.Group>
                <div className="text-center">
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Valider
                  </LoadingButton>
                  <Button onClick={handleReset} sx={{ mr: 1 }}>
                    Réinitialiser
                  </Button>
                </div>{" "}
              </Form>
            </>
          )}
          {isSuccess && (
            <div className="flex flex-col items-center">
              <img src={gif} alt="" className="w-20" />
              Un mail contenant les diagnostics que vous devez réaliser vous a
              été envoyé
            </div>
          )}
        </Paper>
      )}
    </Box>
  )
  return (
    <div>
      <WaveHeader title="Quels diagnostics ?" image={bgImage} />
      <section className="flex items-center flex-col p-4 mx-4 mb-8">
        <h2 className="text-center text-2xl" style={{ color: colors.blue }}>
          Vous comptez vendre ou louer un bien et vous ne savez pas quels
          diagnostics vous devez faire ?
        </h2>
        <p className="text-xl">
          Répondez au questionaire ci-dessous afin de recevoir la réponse
        </p>
        <img src={arrow} alt="" className="w-20 mb-5" />
        <div>{content}</div>
      </section>
    </div>
  )
}

export default Diagnostics
