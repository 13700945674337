import styled from "@emotion/styled"
import {
  faCircleCheck,
  faPencilAlt,
  faSortDown,
  faSortUp,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Breadcrumbs,
  Button,
  Divider,
  List,
  ListItem,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import InternError from "../../../components/InternError"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import Loader from "../../../components/Loader"
import {
  useEditPartnerDiscountMutation,
  useGetPartnerInfosQuery,
} from "../../../features/userSlice"
import PartnerRequest, { StatusType } from "../../../models/partnerRequest"
import User from "../../../models/user"
import colors from "../../../style/colors"
import GlobalBgStyle from "../../../style/GlobalBgStyle"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import frLocale from "date-fns/locale/fr"
import {
  formatStrToDate,
  getFirstDayOfCurrentYear,
  getLastDayOfCurrentYear,
  getMessageFromType,
  getStatusColor,
  getStatusLabel,
} from "../../../utils/utils"
import { format } from "date-fns"
import { css } from "@emotion/css"
import { useGetPartnerRequestsWithPaginationQuery } from "../../../features/partnerRequestSlice"
import { LoadingButton } from "@mui/lab"
import { toast } from "react-toastify"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../../models/responseApiType"

const StyledStatus = styled.span<{ status: StatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  text-align: center;
  align-self: center;
  white-space: nowrap;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  margin-left: 0.5rem;
`

const StyledDiv = styled.div`
  h1 {
    font-size: 1.4rem;
    text-align: center;
  }

  @media all and (min-width: 768px) {
    h1 {
      font-size: 1.7rem;
    }
  }

  h2 {
    color: ${colors.blue};
  }

  h3 {
    font-size: 1.3rem;
    font-weight: bolder;
    color: ${colors.blue};
    text-decoration: underline;
  }
`

const StyledSpan = styled.span`
  font-weight: bold;
`

const NoneSpan = styled.span`
  color: darkgrey;
  font-style: italic;
`

export function AlignItemsList({
  items,
  loading,
  direction,
  handleFilter,
  onItemClick,
}: {
  items: PartnerRequest[]
  loading: boolean
  direction: string
  handleFilter: () => void
  onItemClick: (id: number) => void
}) {
  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 700,
        bgcolor: "rgba(255,255,255,0.9)",
      }}
    >
      <div className="flex justify-end flex-wrap gap-1 items-baseline py-1 pe-3">
        <span>Trier par : </span>
        <Button
          color="info"
          endIcon={
            <FontAwesomeIcon
              icon={direction === "DESC" ? faSortDown : faSortUp}
            />
          }
          aria-label="tri par date"
          size="small"
          onClick={handleFilter}
        >
          Date
        </Button>
      </div>
      <Divider />
      {items.map((item: PartnerRequest) => (
        <Fragment key={item.id}>
          <ListItem
            onClick={() => onItemClick(item.id)}
            alignItems="flex-start"
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-between w-full">
              <div className="flex flex-col p-0 md:p-1">
                <div className="fw-bold" style={{ color: colors.darkBlue }}>
                  Demande n° {item.number}
                </div>
                <div className="">
                  Nb diagnostics: {item.diagnostics?.length}
                </div>
                <div className="text-sm">
                  Créée le{" "}
                  {formatStrToDate(item.createdAt, "dd MMMM yyyy 'à' HH'h'mm")}
                </div>
              </div>
              <div>
                <StyledStatus status={item.status}>
                  {getStatusLabel(item.status)}
                </StyledStatus>
              </div>
            </div>
          </ListItem>
          <Divider variant="middle" component="li" />
        </Fragment>
      ))}
    </List>
  )
}

function PartnerDetail() {
  let { id } = useParams()

  const [from, setFrom] = useState<Date | null>(getFirstDayOfCurrentYear())
  const [to, setTo] = useState<Date | null>(getLastDayOfCurrentYear())
  const [fromDate, setFromDate] = useState<string | null>()
  const [isUpdate, setIsUpdate] = useState(false)
  const [toDate, setToDate] = useState<string | null>()
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [errors, setErrors] = useState({} as any)
  const [discount, setDiscount] = useState<number | undefined>()

  const [order, setOrder] = useState({
    orderBy: "createdAt",
    direction: "DESC",
  })
  const toggleOrderDirection = () => {
    if (order.direction === "DESC") {
      setOrder((prev: any) => ({
        ...prev,
        direction: "ASC",
      }))
    } else {
      setOrder((prev: any) => ({
        ...prev,
        direction: "DESC",
      }))
    }
  }

  const {
    data: partner,
    isSuccess,
    isError,
    isLoading,
  }: {
    data: User
    isSuccess: boolean
    isError: boolean
    isLoading: boolean
  } = useGetPartnerInfosQuery(id)

  const {
    data: partnerRequestPaginate = [],
    isLoading: diagLoading,
    isSuccess: diagSuccess,
    isError: diagError,
  } = useGetPartnerRequestsWithPaginationQuery(
    {
      id: id,
      params: {
        limit: rowsPerPage,
        page: page,
        orderBy: order.orderBy,
        direction: order.direction,
        //status: status?.join("-"),
        from: fromDate,
        to: toDate,
      },
    },
    { skip: !fromDate || !toDate }
  )

  useEffect(() => {
    try {
      let date1 = from ? format(from, "yyyy-MM-dd HH:mm:ss") : null
      let date2 = to ? format(to, "yyyy-MM-dd HH:mm:ss") : null
      setFromDate(date1)
      setToDate(date2)
      console.log(fromDate, toDate)
    } catch (error) {
      console.error(error)
    }
  }, [from, to])

  useEffect(() => {
    setDiscount(partner?.discount)
  }, [partner])

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const [editDiscount, { isLoading: isUpdateLoading }] =
    useEditPartnerDiscountMutation()

  let diagContent = diagSuccess ? (
    <>
      <div className="flex flex-col justify-center items-center">
        {partnerRequestPaginate.items?.length > 0 ? (
          <>
            <AlignItemsList
              items={partnerRequestPaginate.items}
              loading={isLoading}
              direction={order.direction}
              handleFilter={toggleOrderDirection}
              onItemClick={(id: number) => console.log(id)}
            />
            <TablePagination
              component="div"
              count={partnerRequestPaginate.totalItems}
              page={page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              sx={{
                flexWrap: { xs: "wrap" },
                justifyContent: "center",
                pt: 4,
              }}
              className={css`
                .MuiTablePagination-toolbar {
                  @media (max-width: 600px) {
                    flex-wrap: wrap;
                    justify-content: center;
                  }
                  align-items: baseline;
                }
              `}
            />
          </>
        ) : (
          <NoneSpan className="mt-3">Aucune demande</NoneSpan>
        )}
      </div>
    </>
  ) : diagError ? (
    <InternError />
  ) : diagLoading ? (
    <Loader />
  ) : (
    ""
  )

  const handleDiscountChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement> | any) => {
    let newValue: any = undefined
    if (value) {
      newValue = parseFloat(value?.replace(",", "."))
      if (isNaN(newValue)) {
        let err: any = []
        err["[discount]"] = "Format invalide"
        setErrors(err)
      } else {
        setErrors({})
      }
    } else {
      setErrors({})
    }

    setDiscount(newValue)
  }

  const updateDiscount = () => {
    console.log(discount)
    editDiscount({ id: partner?.id, body: discount ?? 0 })
      .unwrap()
      .then((response: any) => {
        toast.success("La remise a bien été modifiée")
        setIsUpdate(false)
      })
      .catch((error: ResponseApiException) => {
        let msg = getMessageFromType(error)
        if (error.data?.type === ResponseApiType.FormError) {
          setErrors(error.data?.detail)
        } else toast.error(msg)
      })
  }

  let content

  if (isLoading) {
    content = <Loader />
  } else if (isSuccess) {
    content = (
      <>
        <h2 className="text-center mb-4">{partner?.companyName}</h2>
        <div className="shadow bg-white p-2 p-sm-4">
          <h3>Informations</h3>
          <div className="my-4">
            <div>
              <StyledSpan>Nom société : </StyledSpan> {partner.companyName}
            </div>
            <div>
              {partner.isActive ? (
                <span className="fw-bold flex gap-1 align-items-center sm:inline-flex sm:align-middle">
                  Compte actif{" "}
                  <FontAwesomeIcon icon={faCircleCheck} color="green" />
                </span>
              ) : (
                <span className="fw-bold flex gap-1 align-items-center sm:inline-flex sm:align-middle">
                  Compte désactivé{" "}
                  <FontAwesomeIcon icon={faXmarkCircle} color="darkred" />
                </span>
              )}
            </div>
            <div>
              <StyledSpan>Email : </StyledSpan> {partner.email}
            </div>
            <div>
              <StyledSpan>Contact : </StyledSpan>{" "}
              {partner.firstname || partner.lastname ? (
                `${partner.firstname} ${partner.lastname}`
              ) : (
                <NoneSpan>Aucun indiqué</NoneSpan>
              )}
            </div>
            <div>
              <StyledSpan>Téléphone : </StyledSpan>{" "}
              {partner.phone ? (
                partner.phone
              ) : (
                <NoneSpan>Aucun indiqué</NoneSpan>
              )}
            </div>
            <div>
              <StyledSpan>Dernière connexion : </StyledSpan>{" "}
              {partner.lastConnection ? (
                formatStrToDate(partner.lastConnection, "dd/MM/yyyy HH:mm")
              ) : (
                <NoneSpan>Jamais connecté</NoneSpan>
              )}
            </div>
            <div>
              <StyledSpan>Création du compte : </StyledSpan> le{" "}
              {formatStrToDate(partner.createdAt, "dd/MM/yyyy")}
            </div>
          </div>
          <h3>Paramètres</h3>
          <div className="my-4 flex flex-wrap justify-between">
            <div>
              {isUpdate ? (
                <div>
                  <TextField
                    autoFocus
                    margin="normal"
                    id="discount"
                    label="Remise appliquée (%)"
                    type="text"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    fullWidth
                    variant="standard"
                    name="discount"
                    defaultValue={partner.discount}
                    onChange={handleDiscountChange}
                    error={errors["[discount]"]}
                    helperText={
                      Array.isArray(errors["[discount]"])
                        ? errors["[discount]"].map((err: any) => `${err}. `)
                        : errors["[discount]"]
                    }
                  />
                </div>
              ) : (
                <>
                  <StyledSpan>Remise appliquée : </StyledSpan>
                  {partner.discount ? (
                    `${partner.discount}%`
                  ) : (
                    <NoneSpan>Aucune remise</NoneSpan>
                  )}
                </>
              )}
            </div>
            {isUpdate ? (
              <div className="d-flex flex-wrap gap-1 justify-content-center mt-4 w-100">
                <LoadingButton
                  variant="outlined"
                  color="success"
                  loading={isUpdateLoading}
                  disabled={isUpdateLoading}
                  startIcon={<FontAwesomeIcon icon={faCircleCheck} />}
                  onClick={updateDiscount}
                >
                  Valider
                </LoadingButton>
                <Button onClick={() => setIsUpdate(false)} variant="outlined">
                  Annuler
                </Button>
              </div>
            ) : (
              <Button
                variant="contained"
                startIcon={
                  <FontAwesomeIcon icon={faPencilAlt} className="text-sm" />
                }
                onClick={() => setIsUpdate(true)}
              >
                Modifier la remise
              </Button>
            )}
          </div>
          <h3>Demandes de diagnostics</h3>
          <div className="my-4">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={frLocale}
            >
              <div className="d-flex flex-wrap gap-5 justify-content-center">
                <DatePicker
                  label="Du"
                  value={from}
                  onChange={(newValue: any) => {
                    setFrom(newValue)
                  }}
                  className="datePicker"
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "jj/mm/aaaa",
                      }}
                    />
                  )}
                />
                <DatePicker
                  label="au"
                  value={to}
                  onChange={(newValue: any) => {
                    setTo(newValue)
                  }}
                  className="datePicker"
                  minDate={from}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "jj/mm/aaaa",
                      }}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
            {diagContent}
          </div>
        </div>
      </>
    )
  } else if (isError) {
    content = <InternError />
  }

  //getuserdata
  return (
    <StyledDiv className="container">
      <GlobalBgStyle />
      <Breadcrumbs>
        <Link to="/admin/prescripteurs" style={{ color: colors.darkBlue }}>
          Prescripteurs
        </Link>
        <Typography color="text.primary">Fiche détaillée</Typography>
      </Breadcrumbs>
      <h1>Fiche détaillée</h1>
      {content}
    </StyledDiv>
  )
}

export default PartnerDetail
