import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/bg-contact.jpg"
import styled from "@emotion/styled"
import { Form } from "react-bootstrap"
import React, { useState } from "react"
import { API } from "../../utils/API"
import { toast } from "react-toastify"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../models/responseApiType"
import { LoadingButton } from "@mui/lab"
import colors from "../../style/colors"
import { useNavigate } from "react-router-dom"

const StyledDiv = styled.div`
  p {
    margin: 0;
    padding: 0;
  }
`

function Contact() {
  const [validated, setValidated] = useState(false)
  const [errors, setErrors] = useState({} as any)
  const [formState, setFormState] = useState<any>({
    name: null,
    firstname: null,
    mail: null,
    phone: null,
    subject: null,
    message: null,
    check: false,
  })
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev: any) => ({
      ...prev,
      [name]: value,
    }))
  }
  const handleMail = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
      return
    } else {
      setIsLoading(true)
      API.post("contact", formState)
        .then((response: any) => {
          toast.success("Votre demande de contact a été envoyée")
          setValidated(false)
        })
        .catch((error: any) => {
          let response: ResponseApiException = error.response
          if (response.data?.type === ResponseApiType.FormError) {
            setErrors(response.data?.detail)
            setValidated(true)
          } else {
            toast.error("Une erreur s'est produite, veuillez réessayer")
          }
        })
        .finally(() => setIsLoading(false))
    }
    navigate("/")
  }
  return (
    <div>
      <WaveHeader title="Contact" image={bgImage} />

      <StyledDiv className="mt-10 mb-16 flex justify-center text-white lg:text-lg text-sm">
        <div className="lg:w-2/3 w-11/12 bg-[#006297] rounded-lg p-3">
          <Form
            onSubmit={handleMail}
            className="flex flex-col space-y-4"
            noValidate
            validated={validated}
          >
            <div className="flex lg:flex-row flex-col lg:space-x-3 space-x-0 space-y-2 lg:space-y-0">
              <div className="lg:w-2/5 w-full flex flex-col space-y-2">
                <div className="flex flex-col">
                  <label htmlFor="name">Nom*</label>
                  <Form.Control
                    type="text"
                    className="text-black rounded-sm"
                    id="name"
                    name="name"
                    required
                    isInvalid={validated && errors?.name}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="bg-red-100 border-2 border-red-600 text-center rounded-sm"
                  >
                    Le champ "Nom" est requis
                  </Form.Control.Feedback>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="firstname">Prénom*</label>
                  <Form.Control
                    type="text"
                    className="text-black rounded-sm"
                    id="firstname"
                    name="firstname"
                    required
                    isInvalid={validated && errors?.firstname}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="bg-red-100 border-2 border-red-600 text-center rounded-sm"
                  >
                    Le champ "Prénom" est requis
                  </Form.Control.Feedback>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="mail">Adresse email*</label>
                  <Form.Control
                    type="text"
                    className="text-black rounded-sm"
                    id="mail"
                    name="mail"
                    required
                    isInvalid={validated && errors?.mail}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="bg-red-100 border-2 border-red-600 text-center rounded-sm"
                  >
                    Le champ "Adresse mail" est requis
                  </Form.Control.Feedback>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="phone">Téléphone</label>
                  <Form.Control
                    type="text"
                    className="text-black rounded-sm"
                    id="phone"
                    name="phone"
                    onChange={handleChange}
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="subject">Sujet*</label>
                  <Form.Control
                    type="text"
                    className="text-black rounded-sm"
                    id="subject"
                    name="subject"
                    required
                    isInvalid={validated && errors?.subject}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="bg-red-100 border-2 border-red-600 text-center rounded-sm"
                  >
                    Le champ "Sujet" est requis
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="lg:w-3/5 w-full flex flex-col">
                <div className="flex flex-col lg:h-full h-28">
                  <label htmlFor="message">Message*</label>
                  <Form.Control
                    as="textarea"
                    className="h-full text-black rounded-sm"
                    id="message"
                    name="message"
                    required
                    isInvalid={validated && errors?.message}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="bg-red-100 border-2 border-red-600 text-center rounded-sm"
                  >
                    Le champ "Message" est requis
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center space-x-2 lg:text-sm text-xs">
              <Form.Check type="checkbox" id="check">
                <Form.Check.Input
                  type="checkbox"
                  className="text-black rounded-sm"
                  name="check"
                  required
                  isInvalid={validated && errors?.check}
                  onChange={handleChange}
                />
                <Form.Check.Label className="text-white">
                  En soumettant ce formulaire, j'accepte que les informations
                  saisies soient exploitées dans le cadre de la demande de
                  contact et de la relation commerciale qui peut en découler.*
                </Form.Check.Label>
                <Form.Control.Feedback
                  type="invalid"
                  className="bg-red-100 border-2 border-red-600 text-center rounded-sm"
                >
                  Veuillez valider la réquisition de vos données
                </Form.Control.Feedback>
              </Form.Check>
            </div>

            <p className="lg:text-sm text-xs">
              Ce site est protégé par reCAPTCHA de Google, d'une Politique de
              Confidentialité et de Conditions d'utilisation.
            </p>

            <div className="space-y-1">
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                className="bg-white text-[#006297] rounded px-3 py-1"
              >
                Envoyer
              </LoadingButton>
              <p className="lg:text-sm text-xs">*Champs obligatoires</p>
            </div>
          </Form>
        </div>
      </StyledDiv>

      <StyledDiv className="w-full bg-[#006297] text-white flex flex-row">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d683.6427286777841!2d5.0215971162987625!3d49.690253891507794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ea6591673b5be9%3A0xcd0b8330634231d5!2s5%20Pl.%20Guy%20de%20Montagnac%2C%2008140%20Bazeilles!5e0!3m2!1sfr!2sfr!4v1665498594537!5m2!1sfr!2sfr"
          className="lg:w-2/3 w-1/2"
        ></iframe>

        <div className="lg:w-1/3 w-1/2 lg:p-3 p-2 space-y-2 lg:text-base text-sm">
          <div>
            <p className="underline">Adresse :</p>
            <p>5 Place Guy de Montagnac, 08150, Rubécourt</p>
          </div>

          <div>
            <p className="underline">Téléphone :</p>
            <p>03.24.56.92.10</p>
          </div>

          <div>
            <p className="underline">Email :</p>
            <p>contact@immotech08.com</p>
          </div>
        </div>
      </StyledDiv>
    </div>
  )
}

export default Contact
