import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import {
  Link,
  useLocation,
  BrowserRouter as Router,
  Route,
} from "react-router-dom"
import { useState } from "react"
import styled from "@emotion/styled"
import colors from "../../style/colors"
import { useAuth } from "../../utils/hooks/useAuth"
import User from "../../models/user"

const StyledLink = styled(Link)`
  color: #006297;
  font-size: 1.1rem;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-weight: 600;

  &:hover,
  &.active {
    color: dimgrey !important;
  }
`

const StyledLinkOther = styled(Link)`
  color: white !important;
  font-size: 1.1rem;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-weight: 600;

  &:hover,
  &.active {
    color: #f4ffb1 !important;
  }
`

const StyledNav = styled(Navbar)`
  z-index: 200;
  padding: ${(props) => (props.open ? "0.2rem 0rem 1.2rem 0rem" : "inherit")};
  background-color: ${(props) =>
    props.open ? "rgba(3,98,151, 0.95)" : "inherit"};

  position: ${(props) => (props.open ? "fixed" : "inherit")};

  a {
    color: ${(props) => (props.open ? "white" : colors.grey)};
  }
  button.navbar-toggler {
    padding: 0.4rem;
    background-color: #ffffff;
    border: 1px solid;
    border-color: ${(props) => (props.open ? colors.grey : colors.grey)};
    &:focus {
      outline: none;
      box-shadow: none;
    }
    .navbar-toggler-icon {
      width: 0.8em;
      height: 0.8em;
      background-image: ${(props) =>
        props.open
          ? "url(\"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(50,50,50)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E\");"
          : "url(\"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(50,50,50)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E\");"};
    }
  }
`

function Header() {
  const [expanded, setExpanded] = useState(false)
  const location = useLocation()
  const {
    loggedUser: { user },
  }: {
    loggedUser: { user: User | undefined }
  } = useAuth()

  const close = () => {
    setExpanded(false)
  }

  return (
    <>
      <div className="bg-[#333333] py-2 flex flex-row lg:justify-end justify-center items-center lg:px-10 space-x-10">
        <Link to="/partenaire" className="text-white">
          Devenir partenaire
        </Link>
        {user ? (
          <Link
            to="/admin"
            className="text-white no-underline bg-[#006297] px-2 rounded"
          >
            {user.email}
          </Link>
        ) : (
          <Link
            to="/login"
            className="text-white no-underline bg-[#006297] px-2 rounded"
          >
            Connexion
          </Link>
        )}
      </div>
      <StyledNav
        collapseOnSelect
        expand="lg"
        className="absolute w-100 navbar-light pt-2 lg:pl-8"
        open={expanded}
        onToggle={() => setExpanded(!expanded)}
      >
        <Container fluid className="flex-row">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-center lg:justify-start"
          >
            <Nav className="me-auto">
              <Nav.Link
                as={location.pathname === "/" ? StyledLink : StyledLinkOther}
                to="/"
                href="/"
              >
                Accueil
              </Nav.Link>
              <Nav.Link
                as={location.pathname === "/" ? StyledLink : StyledLinkOther}
                to="/diagnostics"
                href="/diagnostics"
              >
                Quels diagnostics ?
              </Nav.Link>
              <Nav.Link
                as={location.pathname === "/" ? StyledLink : StyledLinkOther}
                to="/presentation"
                href="/presentation"
              >
                Présentation
              </Nav.Link>
              <Nav.Link
                as={location.pathname === "/" ? StyledLink : StyledLinkOther}
                to="/services"
                href="/services"
              >
                Nos services
              </Nav.Link>
              <Nav.Link
                as={location.pathname === "/" ? StyledLink : StyledLinkOther}
                to="/blog"
                href="/blog"
              >
                Blog
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                className="text-white text-center mx-4 px-4 bg-[#333333] rounded-3xl "
                href="/contact"
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </StyledNav>
    </>
  )
}

export default Header
