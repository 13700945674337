import { Form } from "react-bootstrap"
import { useState } from "react"
import { ResponseApiException } from "../../models/responseApiType"
import { getDifferenceInMinutes, getMessageFromType } from "../../utils/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import styled from "@emotion/styled"
import { LoadingButton } from "@mui/lab"
import { API } from "../../utils/API"
import { toast } from "react-toastify"
import colors from "../../style/colors"
import bgLogin from "../../assets/bg-login.jpg"

const StyledDiv = styled.div`
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90vw;
  align-self: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.5) 4px 4px 15px;

  @media (min-width: 576px) {
    padding: 2rem;
    width: 30rem;
  }

  h2 {
    color: ${colors.blue};
    font-weight: bold;
  }
`

const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${colors.blue};
  background-image: linear-gradient(
      180deg,
      rgba(0, 99, 152, 0.6),
      rgba(0, 99, 152, 0.5)
    ),
    url(${bgLogin});
  background-repeat: no-repeat;
  background-position: left 0 top 0, center center;
  background-size: cover, 100%;
  min-height: 100vh; ;
`

function ResetPassword() {
  const [email, setEmail] = useState<string>()
  const [validated, setValidated] = useState(false)
  const [msg, setMsg] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const canSubmit = email && !isLoading

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    } else {
      setValidated(true)
      //reset
      setIsLoading(true)
      API.post(`reset-password`, { email: email })
        .then((result: any) => {
          let response = result.data
          if (response) {
            let expiredAt = response.resetToken?.expiresAt
            setMsg(expiredAt)
          }
        })
        .catch((error: any) => {
          let response: ResponseApiException = error.response
          let msg = getMessageFromType(response)
          toast.error(msg)
        })
        .finally(() => setIsLoading(false))
    }
  }

  return (
    <StyledContainer>
      <h1
        className="text-center p-4 pb-5 text-uppercase"
        style={{ color: "white" }}
      >
        Immotech
      </h1>
      <StyledDiv>
        <h2 className="text-center pb-3 fs-4 violet">
          Réinitialisation du mot de passe
        </h2>
        <Form onSubmit={(e) => handleSubmit(e)} validated={validated}>
          {!msg && (
            <div className="mb-3">
              Veuillez indiquer votre adresse email et nous vous enverrons un
              lien pour réinitialiser votre mot de passe.
            </div>
          )}
          {msg && (
            <div className="mb-3 fst-italic">
              <FontAwesomeIcon icon={faCheckCircle} color="green" /> Si un
              compte correspond à cette adresse mail, alors un mail a été envoyé
              afin que vous puissiez réinitaliser votre mot de passe. Le lien
              expirera dans {getDifferenceInMinutes(msg)} minutes. Si vous
              n'avez pas reçu d'email merci de vérifier vos spams ou réessayer.
            </div>
          )}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              required
              type="email"
              placeholder="Entrez votre email"
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              name="email"
            />
          </Form.Group>

          <div className="d-flex justify-content-center mt-4 w-100">
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              disabled={!canSubmit}
              color="info"
            >
              Valider
            </LoadingButton>
          </div>
        </Form>
      </StyledDiv>
    </StyledContainer>
  )
}

export default ResetPassword
