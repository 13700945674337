import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/bg-services.jpg"
import styled from "@emotion/styled"
import check from "../../assets/home/verifier.png"
import certif from "../../assets/certif.png"

const StyledDiv = styled.div`
    p {
        margin: 0;
        padding: 0;
    }
`

function Services() {
    return(
        <StyledDiv>
            <WaveHeader title="Services" image={bgImage} />

            <div className="flex flex-col items-center w-full my-10 lg:text-base text-sm">
                <div className="w-11/12 lg:space-y-20 space-y-10">
                    <div className="space-y-6">
                        <div className="flex flex-col lg:space-y-4 space-y-2">
                            <p className="text-[#006297] font-black text-sm">IMMOTECH</p>

                            <div className="flex flex-col space-y-1">
                                <p className="text-[#181E4B] lg:text-5xl text-xl font-bold">Certifications</p>
                                <div className="bg-gradient-to-r from-[#006297] to-[#6CB7E2] h-1 lg:w-60 w-32 rounded"></div>
                            </div>
                        </div>

                        <div className="w-full flex justify-center">
                            <div className="w-11/12 flex lg:flex-row flex-col items-center">
                                <div className="lg:w-2/3 w-full">
                                    <p>Pour toutes les activités qui sont citées ci-dessous, en tant que diagnostiqueurs certifiés et membre de la FNAIM, nous garantissons une veille règlementaire et technique régulière nous permettant d’être au fait des dernières évolutions de la législation en vigueur.</p>
                                </div>

                                <div className="w-1/3 lg:flex hidden justify-center">
                                    <img src={certif} alt="Certification" className="w-1/3" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Transation / Location */}
                    <div className="space-y-6">
                        <div className="flex flex-col lg:space-y-4 space-y-2">
                            <p className="text-[#006297] font-black text-sm">IMMOTECH</p>

                            <div className="flex flex-col space-y-1">
                                <p className="text-[#181E4B] lg:text-5xl text-xl font-bold">Transaction / Location</p>
                                <div className="bg-gradient-to-r from-[#006297] to-[#6CB7E2] h-1 lg:w-60 w-32 rounded"></div>
                            </div>
                        </div>

                        <div className="flex justify-center w-full">
                            <div className="grid lg:grid-rows-4 grid-rows-1 lg:grid-flow-col w-11/12 gap-2">
                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="w-[6%]" />
                                    <p>DPE (tous types de biens)</p>
                                </div>

                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="w-[6%]" />
                                    <p>Repérage amiante et Dossier technique amiante (tous types de bien)</p>
                                </div>

                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="w-[6%]" />
                                    <p>Constat de risque d’exposition au plomb (CREP)</p>
                                </div>

                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="w-[6%]" />
                                    <p>Contrôle des installations gaz</p>
                                </div>

                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="w-[6%]" />
                                    <p>Contrôle des installation électriques</p>
                                </div>
                                
                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="w-[6%]" />
                                    <p>Certificat de mesurage</p>
                                </div>

                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="w-[6%]" />
                                    <p>Etat parasitaire & diagnostics mérule et champignons</p>
                                </div>

                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="w-[6%]" />
                                    <p>Diagnostic assainissement (via notre partenaire TP Réseau) soumis à restrictions géographiques</p>
                                </div>

                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="w-[6%]" />
                                    <p>Diagnostic qualité de l’air intérieur (Etablissements recevant du public)</p>
                                </div>

                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="w-[6%]" />
                                    <p>Diagnostic Technique Global (dans le cadre de mise en copropriété)</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Avant travaux / Démolition */}
                    <div className="space-y-6">
                        <div className="flex flex-col lg:space-y-4 space-y-2">
                            <p className="text-[#006297] font-black text-sm">IMMOTECH</p>

                            <div className="flex flex-col space-y-1">
                                <p className="text-[#181E4B] lg:text-5xl text-xl font-bold">Avant travaux / Démolition</p>
                                <div className="bg-gradient-to-r from-[#006297] to-[#6CB7E2] h-1 lg:w-60 w-32 rounded"></div>
                            </div>
                        </div>

                        <div className="flex justify-center w-full">
                            <div className="grid lg:grid-rows-4 grid-rows-1 lg:grid-flow-col w-11/12 gap-2">
                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="lg:w-[4%] w-[6%]" />
                                    <p>Repérage amiante</p>
                                </div>

                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="lg:w-[4%] w-[6%]" />
                                    <p>Constat de Risque d’exposition au plomb</p>
                                </div>

                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="lg:w-[4%] w-[6%]" />
                                    <p>Diagnostic gestion/valorisation des déchets issus de la démolition</p>
                                </div>

                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="lg:w-[4%] w-[6%]" />
                                    <p>Carottages routiers (via notre partenaire TP Réseau)</p>
                                </div>

                                <div className="flex flex-row items-start justify-start">
                                    <img src={check} alt="Check" className="lg:w-[4%] w-[6%]" />
                                    <p>Détection de réseaux enterrés (via notre partenaire TP Réseaux)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledDiv>
    )
}

export default Services