export enum StatusSubType {
  TO_HANDLE = "ToHandle",
  APPROVED = "Approved",
  DISAPPROVED = "Disapproved",
  ARCHIVED = "Archived",
}

export function getStatusSubOrder() {
  return [
    StatusSubType.TO_HANDLE,
    StatusSubType.APPROVED,
    StatusSubType.DISAPPROVED,
  ]
}

export function getStatusTypeLabel(status: StatusSubType | string) {
  switch (status) {
    case StatusSubType.TO_HANDLE:
      return "À traiter"
    case StatusSubType.APPROVED:
      return "Acceptée"
    case StatusSubType.DISAPPROVED:
      return "Refusée"
    default:
      return status
  }
}

export default interface PartnerSubscription {
  id: number
  email?: string
  phone?: string
  firstname?: string
  lastname?: string
  status: StatusSubType
  number: string
  companyName?: string
  createdAt?: string
}
