import { useEffect, useState } from "react"
import { useAuth } from "../../utils/hooks/useAuth"
import logo from "../../assets/logo.png"
import bgLogin from "../../assets/bg-login.jpg"
import styled from "@emotion/styled"
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import colors from "../../style/colors"
import { toast, ToastContainer } from "react-toastify"
import { getMessageFromType } from "../../utils/utils"
import { Link } from "react-router-dom"

interface State {
  username: string
  password: string
  showPassword: boolean
}

const StyledDiv = styled.div`
  margin-top: 4rem;
  background-color: #fafafa;
  padding: 0.8rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-self: center;
  border-radius: 2px;
  min-height: 22rem;
  @media (min-width: 576px) {
    padding: 0.8rem 0.8rem;
    width: 22rem;
  }
`

const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${colors.blue};
  background-image: linear-gradient(
      180deg,
      rgba(0, 99, 152, 0.6),
      rgba(0, 99, 152, 0.5)
    ),
    url(${bgLogin});
  background-repeat: no-repeat;
  background-position: left 0 top 0, center center;
  background-size: cover, 100%;
  min-height: 100vh; ;
`

const StyledLink = styled(Link)`
  border: none;
  color: grey;
  font-style: italic;
  background-color: transparent;
  text-align: right;
`

function Login() {
  const {
    tryLogin: { login, isLoading, loginError, isLoginRejected },
  } = useAuth()

  const [values, setValues] = useState<State>({
    password: "",
    username: "",
    showPassword: false,
  })

  const canSubmit = values.username && values.password && !isLoading

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    login(values)
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  useEffect(() => {
    if (isLoginRejected) {
      let msg = getMessageFromType(loginError)
      toast.error(msg)
    }
  }, [isLoginRejected, loginError])

  return (
    <StyledContainer>
      <StyledDiv className="shadow-inner">
        <img src={logo} alt="Logo immotech" className="rounded w-100 mx-auto" />

        <form onSubmit={handleSubmit} className="mt-7">
          <FormControl className="w-full" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="email"
              value={values.username}
              onChange={handleChange("username")}
              label="email"
            />
          </FormControl>
          <FormControl sx={{ my: 3 }} className="w-full" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Mot de passe
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <FontAwesomeIcon icon={solid("eye-slash")} />
                    ) : (
                      <FontAwesomeIcon icon={solid("eye")} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Mot de passe"
            />
            <div className="flex flex-row justify-between">
              <StyledLink to="/reset-password">
                Mot de passe oublié ?
              </StyledLink>
              <StyledLink to="/">Retour à l'accueil</StyledLink>
            </div>
          </FormControl>

          <div className="text-center mt-4 w-full">
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              disabled={!canSubmit}
              color="primary"
              className="bg-sky-600"
            >
              Se connecter
            </LoadingButton>
          </div>
        </form>
      </StyledDiv>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </StyledContainer>
  )
}

export default Login
