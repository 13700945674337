import { Col, Form, Modal, Row } from "react-bootstrap"
import { faAt, faLock, faXmark } from "@fortawesome/free-solid-svg-icons"
import { Button, Divider } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useAuth } from "../../../utils/hooks/useAuth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { API } from "../../../utils/API"
import styled from "@emotion/styled"
import colors from "../../../style/colors"
import { toast } from "react-toastify"
import { ResponseApiException } from "../../../models/responseApiType"
import { getMessageFromType } from "../../../utils/utils"
import { LoadingButton } from "@mui/lab"
import GlobalBgStyle from "../../../style/GlobalBgStyle"

const StyledMain = styled.div`
  h1 {
    font-size: 1.4rem;
    color: ${colors.blue};
    text-align: center;
    margin-bottom: 2rem;
  }

  @media all and (min-width: 768px) {
    h1 {
      font-size: 1.7rem;
    }
  }
`

const StyledDiv = styled(Row)`
  background-color: white;
  font-size: 1rem;
  box-shadow: 3px 3px 10px lightgray;
  width: 100%;
  margin: 0rem 0.5rem;
  padding: 1rem 0.3rem;

  @media all and (min-width: 768px) {
    max-width: 40rem;
    padding: 1rem 1rem 2rem 1rem;
  }
  @media all and (min-width: 1200px) {
    max-width: 40rem;
  }
`
const StyledCol = styled(Col)`
  font-weight: bold;
  font-size: 1.1rem;
  color: ${colors.blue};
`

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  .modal-title {
    font-size: 1rem !important;
    font-weight: 300 !important;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem !important;
    }
  }

  .modal-header {
    background-color: ${colors.blue};
    color: white;
  }
`
interface ModalProps {
  show: boolean
  handleClose: () => void
}

function ChangeMailModal(modalProps: ModalProps) {
  const [validated, setValidated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPassword, setCurrentPassword] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const { logout } = useAuth()

  useEffect(() => {
    if (!modalProps.show) {
      setValidated(false)
      setEmail("")
      setCurrentPassword("")
    }
  }, [modalProps.show])

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
      return
    } else {
      setIsLoading(true)
      //reset
      API.post(`admin/change-email`, {
        email: email,
        currentPassword: currentPassword,
      })
        .then((response: any) => {
          toast.success(
            "L'adresse email a bien été changée, merci de vous reconnecter."
          )
          logout()
          modalProps.handleClose()
        })
        .catch((error: any) => {
          let response: ResponseApiException = error.response
          let msg = getMessageFromType(response)
          toast.error(msg)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <StyledModal
      show={modalProps.show}
      onHide={modalProps.handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-width"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Modifier l'adresse email</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Form onSubmit={handleSubmit} validated={validated} noValidate>
          <div className="mb-3">
            Veuillez indiquer votre mot de passe actuel
          </div>

          <Form.Group className="mb-3" controlId="formBasicCurrentPassword">
            <Form.Control
              required
              type="password"
              placeholder="Mot de passe actuel"
              onChange={(e) => {
                setCurrentPassword(e.target.value)
              }}
              name="currentPassword"
            />
            <Form.Control.Feedback type="invalid">
              Le mot de passe actuel est requis
            </Form.Control.Feedback>
          </Form.Group>
          <div className="mb-3">
            Veuillez indiquer votre nouvelle adresse mail
          </div>

          <Form.Group className="mb-3" controlId="formMail">
            <Form.Control
              required
              type="email"
              placeholder="Adresse mail"
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              name="email"
            />
            <Form.Control.Feedback type="invalid">
              Une adresse email valide est requise
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex flex-wrap gap-1 justify-content-center mt-4 w-100">
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              disabled={isLoading}
              color="info"
            >
              Valider
            </LoadingButton>
            <Button onClick={modalProps.handleClose} variant="outlined">
              Annuler
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </StyledModal>
  )
}

function ChangePasswordModal(modalProps: ModalProps) {
  const [validated, setValidated] = useState(false)
  const [msgError, setMsgError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [match, setMatch] = useState(false)
  const [currentPassword, setCurrentPassword] = useState<string>("")

  const [formState, setFormState] = useState<any>({
    plainPassword: {
      first: "",
      second: "",
      currentPassword: "",
    },
  })

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev: any) => ({
      ...prev,
      plainPassword: { ...prev.plainPassword, [name]: value },
    }))
  }
  useEffect(() => {
    setMatch(formState.plainPassword?.first === formState.plainPassword?.second)
  }, [formState])

  useEffect(() => {
    if (!modalProps.show) {
      setMsgError("")
      setValidated(false)
      setFormState({
        plainPassword: {
          first: "",
          second: "",
        },
      })
      setCurrentPassword("")
    }
  }, [modalProps.show])

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
      return
    } else {
      setIsLoading(true)
      //reset
      console.log(formState)
      API.post(`admin/change-password`, {
        form: formState,
        currentPassword: currentPassword,
      })
        .then((response: any) => {
          toast.success("Le mot de passe a bien été changé")
          modalProps.handleClose()
        })
        .catch((error: any) => {
          let response: ResponseApiException = error.response
          let msg = getMessageFromType(response)
          setMsgError(msg)
          toast.error(msg)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <StyledModal
      show={modalProps.show}
      onHide={modalProps.handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-width"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Modifier le mot de passe</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Form onSubmit={handleSubmit} validated={validated} noValidate>
          <div className="mb-3">
            Veuillez indiquer votre mot de passe actuel
          </div>

          <Form.Group className="mb-3" controlId="formBasicCurrentPassword">
            <Form.Control
              required
              type="password"
              placeholder="Mot de passe actuel"
              onChange={(e) => {
                setCurrentPassword(e.target.value)
              }}
              name="currentPassword"
            />
            <Form.Control.Feedback type="invalid">
              Le mot de passe actuel est requis
            </Form.Control.Feedback>
          </Form.Group>
          <div className="mb-3">
            Veuillez indiquer votre nouveau mot de passe (min. 8 caractères)
          </div>

          <Form.Group className="mb-3" controlId="formBasicPlainPassword">
            <Form.Control
              required
              minLength={8}
              type="password"
              placeholder="Nouveau mot de passe"
              onChange={handleChange}
              name="first"
              isInvalid={
                validated && formState.plainPassword?.first?.length < 8
              }
            />
            <Form.Control.Feedback type="invalid">
              Le mot de passe doit faire au moins 8 caractères
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPlainPassword2">
            <Form.Control
              required
              minLength={8}
              type="password"
              placeholder="Répétez votre mot de passe"
              onChange={handleChange}
              name="second"
              isInvalid={validated && !match}
            />
            <Form.Control.Feedback type="invalid">
              Les mots de passe ne correspondent pas
            </Form.Control.Feedback>
          </Form.Group>
          {msgError && (
            <div className="mb-3 fst-italic text-center">
              <FontAwesomeIcon icon={faXmark} color="darkred" /> {msgError}
            </div>
          )}
          <div className="d-flex flex-wrap gap-1 justify-content-center mt-4 w-100">
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              disabled={isLoading}
              color="info"
            >
              Valider
            </LoadingButton>
            <Button onClick={modalProps.handleClose} variant="outlined">
              Annuler
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </StyledModal>
  )
}

function Parameters() {
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [showMailModal, setShowMailModal] = useState(false)
  const {
    loggedUser: { user },
  } = useAuth()
  let content = (
    <StyledDiv className="gap-3 justify-content-center">
      <Row className="justify-content-center pt-3">
        <StyledCol sm={6} md={5}>
          Email :
        </StyledCol>
        <Col sm={6} md={7}>
          {user?.email}
        </Col>
      </Row>
      <Divider />
      <Row className="justify-content-center">
        <StyledCol sm={6} md={5}>
          Mot de passe :
        </StyledCol>
        <Col sm={6} md={7}>
          ********
        </Col>
      </Row>

      <div className="flex flex-wrap justify-end gap-1 mt-4">
        <Button
          variant="contained"
          color="info"
          onClick={() => setShowPasswordModal(true)}
          startIcon={<FontAwesomeIcon icon={faLock} />}
        >
          Modifier le mot de passe
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => setShowMailModal(true)}
          startIcon={<FontAwesomeIcon icon={faAt} />}
        >
          Modifier l'adresse mail
        </Button>
      </div>
    </StyledDiv>
  )

  return (
    <StyledMain className="container-fluid px-0">
      <GlobalBgStyle />
      <h1>Paramètres</h1>
      <div className="flex justify-center">{content}</div>
      <ChangePasswordModal
        show={showPasswordModal}
        handleClose={() => {
          setShowPasswordModal(false)
        }}
      />
      <ChangeMailModal
        show={showMailModal}
        handleClose={() => {
          setShowMailModal(false)
        }}
      />
    </StyledMain>
  )
}

export default Parameters
