import { Link } from "react-router-dom"

function Footer() {
  return (
    <div className="bg-[#333333] py-2 flex flex-col justify-center items-center px-10 space-x-10 text-white text-sm lg:text-base">
      <p className="m-0">
        <Link
          to="/mentions-legales"
          className="text-white no-underline hover:underline text-center"
        >
          Mentions légales
        </Link>{" "}
        -{" "}
        <Link
          to="/politique-confidentialite"
          className="text-white no-underline hover:underline"
        >
          Politique de confidentialité
        </Link>
      </p>
      <p className="m-0">
        Réalisé par{" "}
        <a
          href="https://comsea.fr/"
          target="_blank"
          rel="noreferrer"
          className="text-white no-underline hover:underline text-center"
        >
          COMSEA
        </a>
      </p>
    </div>
  )
}

export default Footer
