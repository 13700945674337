import { useState } from "react"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../models/responseApiType"
import styled from "@emotion/styled"
import colors from "../../style/colors"
import { LoadingButton } from "@mui/lab"
import { getMessageFromType } from "../../utils/utils"
import { toast } from "react-toastify"
import User from "../../models/user"
import { useAddAdminUserMutation } from "../../features/userSlice"

interface ModalProps {
  show: boolean
  handleClose: () => void
}

const StyledModal = styled(Dialog)`
  font-size: 0.9rem;
  .modal-title {
    font-size: 1rem !important;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem !important;
    }
  }

  .modal-header {
    background-color: ${colors.blue};
    color: white;
  }
`

function AdminUserModal(modalProps: ModalProps) {
  const [addAdminUser, { isLoading }] = useAddAdminUserMutation()
  const [user, setUser] = useState<User | null>()
  const [errors, setErrors] = useState({} as any)

  const handleAddUser = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    addAdminUser(user)
      .unwrap()
      .then((response: any) => {
        let msg =
          response?.msg ??
          "L'admin a bien été créé, un mail contenant ses identifiants lui a été envoyé."

        toast.success(msg)
        modalProps.handleClose()
      })
      .catch((error: ResponseApiException) => {
        let msg = getMessageFromType(error)
        if (error.data?.type === ResponseApiType.FormError) {
          setErrors(error.data?.detail)
        } else toast.error(msg)
      })
  }

  const handleUserChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement> | any) => {
    setUser((prev: any) => ({ ...prev, [name]: value?.trim() }))
  }

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <>
      <StyledModal
        fullWidth
        fullScreen={fullScreen}
        open={modalProps.show}
        onClose={modalProps.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
      >
        <form noValidate onSubmit={handleAddUser}>
          <DialogTitle id="responsive-dialog-title" sx={{ color: colors.blue }}>
            Ajout d'un utilisateur Admin
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Veuillez renseigner les informations suivantes
            </DialogContentText>
            <TextField
              autoFocus
              margin="normal"
              id="email"
              label="Email"
              type="email"
              fullWidth
              required
              variant="standard"
              name="email"
              onChange={handleUserChange}
              error={errors.email}
              helperText={
                Array.isArray(errors?.email)
                  ? errors.email.map((err: any) => err)
                  : errors?.email
              }
            />

            <TextField
              autoFocus
              margin="normal"
              id="firstname"
              label="Prénom"
              type="text"
              fullWidth
              required
              variant="standard"
              name="firstname"
              onChange={handleUserChange}
              error={errors.firstname}
              helperText={errors?.firstname ?? ""}
            />
            <TextField
              autoFocus
              margin="normal"
              id="lastname"
              label="Nom"
              type="text"
              fullWidth
              required
              variant="standard"
              name="lastname"
              onChange={handleUserChange}
              error={errors.lastname}
              helperText={errors?.lastname ?? ""}
            />
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "flex-end",
              paddingBottom: 2,
            }}
          >
            <Button onClick={modalProps.handleClose}>Annuler</Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              disabled={isLoading || !user}
              color="info"
            >
              Sauvegarder
            </LoadingButton>
          </DialogActions>
        </form>
      </StyledModal>
    </>
  )
}

export default AdminUserModal
