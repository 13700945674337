import PartnerSubscription from "../models/partnerSubscription"
import User from "../models/user"
import UsersPagination from "../models/usersPagination"
import {
  apiSlice,
  TAG_ADMIN_USER,
  TAG_PARTNER_SUB,
  TAG_PARTNER_USER,
  TAG_PERSONAL_INFOS,
  TAG_USER,
} from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfos: builder.query<User, void>({
      query: () => ({ url: "admin/personal-infos" }),
      providesTags: (result) => [
        { type: TAG_PERSONAL_INFOS, id: result?.id },
        { type: TAG_PERSONAL_INFOS, id: "LIST" },
      ],
    }),
    getPartnerInfos: builder.query<User, number>({
      query: (id) => ({ url: `admin/administrator/users/partner/${id}` }),
      providesTags: (result) => [
        { type: TAG_PARTNER_USER, id: result?.id },
        { type: TAG_PARTNER_USER, id: "LIST" },
      ],
    }),
    postUserInfos: builder.mutation<number, User>({
      query: (body) => ({
        url: `admin/personal-infos`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: TAG_PERSONAL_INFOS, id: "LIST" },
        { type: TAG_USER },
      ],
    }),
    getAdminUsersWithPagination: builder.query<
      UsersPagination,
      {
        from: string
        to: string
        limit: number
        page: number
        orderBy: string
        direction: string
        search: string
      }
    >({
      query: (param) => {
        return { url: "admin/administrator/users/admin", params: param }
      },
      providesTags: (result) =>
        result && result.items
          ? [
              ...result.items.map(({ id }) => ({
                type: `${TAG_ADMIN_USER}` as const,
                id,
              })),
              { type: TAG_ADMIN_USER, id: "LIST" },
            ]
          : [{ type: TAG_ADMIN_USER, id: "LIST" }],
    }),
    getPartnerUsersWithPagination: builder.query<
      UsersPagination,
      {
        from: string
        to: string
        limit: number
        page: number
        orderBy: string
        direction: string
        search: string
      }
    >({
      query: (param) => {
        return { url: "admin/administrator/users/partner", params: param }
      },
      providesTags: (result) =>
        result && result.items
          ? [
              ...result.items.map(({ id }) => ({
                type: `${TAG_PARTNER_USER}` as const,
                id,
              })),
              { type: TAG_PARTNER_USER, id: "LIST" },
            ]
          : [{ type: TAG_PARTNER_USER, id: "LIST" }],
    }),
    deleteUser: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `superadmin/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TAG_ADMIN_USER, id: "LIST" },
        { type: TAG_PARTNER_USER, id: "LIST" },
      ],
    }),
    toggleActiveUser: builder.mutation<
      boolean,
      { id: number; active: boolean }
    >({
      query: (param) => ({
        url: `admin/administrator/users/${param.id}/active/${
          param.active ? 1 : 0
        }`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TAG_ADMIN_USER, id: "LIST" },
        { type: TAG_PARTNER_USER, id: "LIST" },
      ],
    }),
    addAdminUser: builder.mutation<User, User>({
      query: (body) => ({
        url: `admin/administrator/users/admin`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: TAG_ADMIN_USER, id: "LIST" }],
    }),
    addPartnerUser: builder.mutation<User, User>({
      query: (body) => ({
        url: `admin/administrator/users/partner`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: TAG_PARTNER_USER, id: "LIST" }],
    }),
    addPartnerBySub: builder.mutation<User, number>({
      query: (id) => ({
        url: `admin/administrator/add-sub/partner/${id}`,
        method: "POST",
      }),
      invalidatesTags: [
        { type: TAG_PARTNER_SUB, id: "LIST" },
        { type: TAG_PARTNER_USER, id: "LIST" },
      ],
    }),
    removePartnerBySub: builder.mutation<User, number>({
      query: (id) => ({
        url: `admin/administrator/remove-sub/partner/${id}`,
        method: "POST",
      }),
      invalidatesTags: [
        { type: TAG_PARTNER_SUB, id: "LIST" },
        { type: TAG_PARTNER_USER, id: "LIST" },
      ],
    }),
    editPartnerDiscount: builder.mutation<boolean, { id: number; body: any }>({
      query: ({ id, body }) => ({
        url: `admin/administrator/partners/${id}/discount`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [{ type: TAG_PARTNER_USER, id: "LIST" }],
    }),
  }),
})

export const {
  useGetUserInfosQuery,
  usePostUserInfosMutation,
  useGetAdminUsersWithPaginationQuery,
  useDeleteUserMutation,
  useToggleActiveUserMutation,
  useAddAdminUserMutation,
  useAddPartnerUserMutation,
  useGetPartnerUsersWithPaginationQuery,
  useGetPartnerInfosQuery,
  useAddPartnerBySubMutation,
  useRemovePartnerBySubMutation,
  useEditPartnerDiscountMutation,
} = extendedApiSlice
