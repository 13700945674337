import styled from "@emotion/styled"
import {
  Avatar,
  Divider,
  Fab,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TablePagination,
  Tooltip,
  Typography,
  useTheme,
  Zoom,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import colors from "../../../style/colors"
import GlobalBgStyle from "../../../style/GlobalBgStyle"
import User from "../../../models/user"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleCheck,
  faSearch,
  faTrash,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons"
import { Fragment, useEffect, useState } from "react"
import Loader from "../../../components/Loader"
import InternError from "../../../components/InternError"
import {
  useDeleteUserMutation,
  useGetAdminUsersWithPaginationQuery,
  useToggleActiveUserMutation,
} from "../../../features/userSlice"
import { formatStrToDate, getMessageFromType } from "../../../utils/utils"
import { css } from "@emotion/css"
import ValidateDialog from "../../../components/ValidateDialog"
import { toast } from "react-toastify"
import { ResponseApiException } from "../../../models/responseApiType"
import { useAuth } from "../../../utils/hooks/useAuth"
import AdminUserModal from "../../../components/AdminUserModal"

const StyledDiv = styled.div`
  h1 {
    font-size: 1.4rem;
    color: ${colors.blue};
    text-align: center;
    margin-bottom: 2rem;
  }

  @media all and (min-width: 768px) {
    h1 {
      font-size: 1.7rem;
    }
  }
`
const StyledSpan = styled.span`
  font-weight: bold;
  color: ${colors.blue};
`

const AlignItemsList = ({
  users,
  currentUser,
  onRemove,
  toggleActivate,
}: {
  users: User[]
  currentUser: User | undefined
  onRemove: (user: User) => void
  toggleActivate: (user: User) => void
}) => (
  <List sx={{ width: "100%", maxWidth: 500, bgcolor: "background.paper" }}>
    {users.map((user: User) => (
      <Fragment key={user.id}>
        <ListItem
          alignItems="flex-start"
          className="justify-center flex-wrap md:flex-nowrap"
        >
          <ListItemAvatar>
            <Avatar alt={user.lastname} />
          </ListItemAvatar>
          <ListItemText
            primary={`${user.lastname?.toUpperCase()} ${user.firstname}`}
            secondary={
              <Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {user.email}
                </Typography>{" "}
                -{" "}
                {user.isActive ? (
                  <span className="flex gap-1 align-items-center sm:inline-flex sm:align-middle">
                    <FontAwesomeIcon icon={faCircleCheck} color="green" />{" "}
                    Compte actif
                  </span>
                ) : (
                  <span className="flex gap-1 align-items-center sm:inline-flex sm:align-middle">
                    <FontAwesomeIcon icon={faXmarkCircle} color="darkred" />{" "}
                    Compte désactivé
                  </span>
                )}{" "}
                <br />
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Tél. : {user.phone ? user.phone : "non indiqué"}
                </Typography>
                <br />
                <StyledSpan>Dernière connexion : </StyledSpan>{" "}
                {formatStrToDate(user.lastConnection ?? "", "dd/MM/yyyy HH:mm")}
                <br />
              </Fragment>
            }
          />
        </ListItem>
        <div className="flex justify-end">
          {user.isActive ? (
            <Tooltip title="Désactiver">
              <IconButton
                onClick={() => toggleActivate(user)}
                aria-label="désactiver"
              >
                <FontAwesomeIcon icon={faXmarkCircle} size="xs" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Activer">
              <IconButton
                onClick={() => toggleActivate(user)}
                aria-label="activer"
              >
                <FontAwesomeIcon icon={faCircleCheck} size="xs" color="green" />
              </IconButton>
            </Tooltip>
          )}
          {currentUser?.roles.includes("ROLE_SUPER_ADMIN") &&
            !user?.roles.includes("ROLE_SUPER_ADMIN") && (
              <Tooltip title="Supprimer">
                <IconButton
                  onClick={() => onRemove(user)}
                  aria-label="supprimer"
                  color="error"
                >
                  <FontAwesomeIcon icon={faTrash} color="#e70202" size="xs" />
                </IconButton>
              </Tooltip>
            )}
        </div>
        <Divider variant="fullWidth" component="li" />
      </Fragment>
    ))}
  </List>
)

function AdminAccounts() {
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState("")
  const [skip, setSkip] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showConfirmActive, setShowConfirmActive] = useState(false)
  const [showAddDialog, setShowAddDialog] = useState(false)

  const [userSelected, setUserSelected] = useState<User | null>(null)
  const theme = useTheme()

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  }

  const [order, setOrder] = useState({
    orderBy: "lastname",
    direction: "ASC",
  })

  const {
    data: usersPaginate = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetAdminUsersWithPaginationQuery(
    {
      limit: rowsPerPage,
      page: page,
      orderBy: order.orderBy,
      direction: order.direction,
      search: searchTerm,
    },
    { skip: skip }
  )

  const [deleteUser, { isLoading: isDeleteLoading }] = useDeleteUserMutation()
  const [toggleActiveUser, { isLoading: isActiveLoading }] =
    useToggleActiveUserMutation()

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    setOrder({
      orderBy: selectedColumn.sortField,
      direction: sortDirection,
    })
  }

  const handleDelete = () => {
    if (userSelected?.id) {
      deleteUser(userSelected?.id)
        .unwrap()
        .then(() => {
          setShowConfirmDelete(false)
          toast.success("L'utilisateur a bien été supprimé")
        })
        .catch((error: ResponseApiException) => {
          let msg = getMessageFromType(error)
          toast.error(msg)
        })
    }
  }

  const handleToggleActive = () => {
    if (userSelected?.id) {
      toggleActiveUser({ id: userSelected?.id, active: !userSelected.isActive })
        .unwrap()
        .then(() => {
          setShowConfirmActive(false)
          let msg = userSelected.isActive
            ? "L'utilisateur a bien été désactivé"
            : "L'utilisateur a bien été réactivé"
          toast.success(msg)
        })
        .catch((error: ResponseApiException) => {
          let msg = getMessageFromType(error)
          toast.error(msg)
        })
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSkip(false)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const handleRemove = (user: User) => {
    setUserSelected(user)
    setShowConfirmDelete(true)
  }

  const toggleActivate = (user: User) => {
    setUserSelected(user)
    setShowConfirmActive(true)
  }

  const handleAdd = () => {
    setShowAddDialog(true)
  }

  const {
    loggedUser: { user: currentUser },
  } = useAuth()

  let content

  if (isLoading) {
    content = <Loader />
  } else if (isError) {
    content = <InternError />
  } else if (isSuccess) {
    content =
      usersPaginate.items && usersPaginate.items.length > 0 ? (
        <AlignItemsList
          users={usersPaginate.items}
          onRemove={(user: User) => handleRemove(user)}
          toggleActivate={toggleActivate}
          currentUser={currentUser}
        />
      ) : (
        "Aucun résultat"
      )
  }

  return (
    <StyledDiv className="container">
      <GlobalBgStyle />
      <h1>Administrateurs</h1>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          maxWidth: 400,
          marginBottom: "1rem",
        }}
        className="ms-auto"
        onSubmit={(e: any) => {
          e.preventDefault()
          setSkip(false)
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, width: "100%" }}
          placeholder="Rechercher"
          inputProps={{ "aria-label": "rechercher" }}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setSkip(true)
          }}
        />
        <IconButton
          type="submit"
          sx={{ p: "10px" }}
          aria-label="recherche"
          onClick={() => setSkip(false)}
        >
          <FontAwesomeIcon icon={faSearch} />
        </IconButton>
      </Paper>
      <div className="flex justify-center my-8">{content}</div>
      <TablePagination
        component="div"
        count={usersPaginate?.totalItems ?? 0}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 20, 50, 100]}
        sx={{
          flexWrap: { xs: "wrap" },
          justifyContent: "center",
          pt: 4,
        }}
        className={css`
          .MuiTablePagination-toolbar {
            @media (max-width: 600px) {
              flex-wrap: wrap;
              justify-content: center;
            }
            align-items: baseline;
          }
        `}
      />
      <ValidateDialog
        show={showConfirmDelete}
        handleClose={() => {
          setUserSelected(null)
          setShowConfirmDelete(false)
        }}
        modalTitle="Suppression définitive"
        handleConfirm={handleDelete}
        loading={isDeleteLoading}
      >
        Êtes-vous sûr de vouloir supprimer l'utilisateur{" "}
        <span className="font-bold">
          {userSelected?.firstname} {userSelected?.lastname}
        </span>{" "}
        ? La suppression est définitive.
      </ValidateDialog>
      <ValidateDialog
        show={showConfirmActive}
        handleClose={() => {
          setUserSelected(null)
          setShowConfirmActive(false)
        }}
        modalTitle={`${
          userSelected?.isActive ? "Désactivation" : "Activation"
        } utilisateur`}
        handleConfirm={handleToggleActive}
        loading={isActiveLoading}
      >
        {userSelected?.isActive ? (
          <>
            Êtes-vous sûr de vouloir désactiver l'utilisateur{" "}
            <span className="font-bold">
              {userSelected?.firstname} {userSelected?.lastname}
            </span>{" "}
            ? Son compte ne sera pas supprimé mais il ne pourra plus y accéder.
          </>
        ) : (
          <>
            Êtes-vous sûr de vouloir réactiver l'utilisateur{" "}
            <span className="font-bold">
              {userSelected?.firstname} {userSelected?.lastname}
            </span>{" "}
            ? Il aura de nouveau accès à son compte.
          </>
        )}
      </ValidateDialog>
      {showAddDialog && (
        <AdminUserModal
          show={showAddDialog}
          handleClose={() => setShowAddDialog(false)}
        />
      )}
      <Zoom
        in
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exit}ms`,
        }}
        unmountOnExit
      >
        <Fab
          color="primary"
          aria-label="add"
          onClick={handleAdd}
          sx={{
            position: "fixed",
            bottom: { sm: 20, xs: 10 },
            right: { sm: 20, xs: 10 },
            border: `solid 1px ${colors.blue}`,
            bgcolor: "rgba(255,255,255,0.8)",
            color: colors.blue,
            "&:hover": {
              bgcolor: colors.blue,
              color: "white",
              border: `solid 1px white`,
            },
          }}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </StyledDiv>
  )
}

export default AdminAccounts
