import axios from "axios"
import { store } from "./store"

export const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  responseType: "json",
})

API.interceptors.request.use(function (config: any) {
  const token = store.getState().auth.token
  config.headers.Authorization = "Bearer " + token

  return config
})
