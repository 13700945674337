import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/bg-partenaire.jpg"
import styled from "@emotion/styled"
import question from "../../assets/questions.png"
import euro from "../../assets/euro.png"
import confiance from "../../assets/confiance.png"
import interactions from "../../assets/interactions.png"
import reponses from "../../assets/reponses.png"
import { Form } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { LoadingButton } from "@mui/lab"
import { API } from "../../utils/API"
import { toast } from "react-toastify"
import { ResponseApiException, ResponseApiType } from "../../models/responseApiType"
import colors from "../../style/colors"

const StyledDiv = styled.div`
    p {
        margin: 0;
        padding: 0;
    }
`

function Partenaire() {
    const [validated, setValidated] = useState(false)
    const [errors, setErrors] = useState({} as any)
    const [formState, setFormState] = useState<any>({
        companyName: null,
        email: null,
        phone: null,
        lastname: null,
        firstname: null,
        consent: false
    })
    const [isLoading, setIsLoading] = useState(false)
    const handleChange = ({
        target: { name, value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        let newValue : string|boolean= value.trim()
        if (name === 'consent') {
            newValue = (value === 'on')
        }
        setFormState((prev: any) => ({
            ...prev,
            [name]: newValue
        }))
    }
    const handlePartner = (event:any) => {
        event.preventDefault()
        event.stopPropagation()
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            setValidated(true)
            return
        } else {
            setIsLoading(true)
            console.log(formState);
            API.post('partner/subscription', formState).then((response: any) => {
                toast.success("Votre demande à devenir un partenaire a été envoyée")
                setValidated(false)
            }).catch((error: any) => {
                let response: ResponseApiException = error.response
                if (response.data?.type === ResponseApiType.FormError) {
                    setErrors(response.data?.detail)
                    setValidated(true)
                } else {
                    toast.error("Une erreur s'est produite, veuillez réessayer")
                }
            }).finally(() => setIsLoading(false))
        }
    }
    return (
        <StyledDiv>
            <WaveHeader title="Partenaires" image={bgImage} />

            <div className="flex flex-col items-center w-full mt-10 lg:text-base text-sm">
                <div className="w-11/12 lg:space-y-20 space-y-10">
                    {/* Avantages */}
                    <div className="space-y-6 lg:text-base text-sm">
                        <div className="flex flex-col lg:space-y-4 space-y-2">
                            <p className="text-[#006297] font-black text-sm">IMMOTECH</p>

                            <div className="flex flex-col space-y-1">
                                <p className="text-[#181E4B] lg:text-5xl text-xl font-bold">Les avantages à être partenaire</p>
                                <div className="bg-gradient-to-r from-[#006297] to-[#6CB7E2] h-1 lg:w-60 w-32 rounded"></div>
                            </div>
                        </div>

                        <div>
                            <p className="lg:text-2xl text-base font-semibold">Simplicité, efficacité, réactivité </p>
                            <p>Une application web dédiée aux diagnostics, destinée à vous accompagner dans votre démarche de constitution de dossier. </p>
                        </div>

                        <div className="flex flex-col items-center space-y-10">
                            <div className="w-11/12 flex flex-col items-center space-y-2">
                                <div className="flex flex-row items-end w-full space-x-2">
                                    <img src={question} alt="" className="lg:w-[3%] w-[10%]" />
                                    <p className="lg:text-xl text-base font-semibold">Plus aucune question en suspens :</p>
                                </div>

                                <div className="w-11/12">
                                    <p>Amiante, plomb, DPE etc… ??? Grâce à notre application, vous savez immédiatement quels diagnostics sont à réaliser dans le bien que vous commercialisez.</p>
                                    <p>Vous apporterez une réponse fiable et instantanée à votre client. </p>
                                </div>
                            </div>

                            <div className="w-11/12 flex flex-col items-center space-y-2">
                                <div className="flex flex-row items-end w-full space-x-2">
                                    <img src={euro} alt="" className="lg:w-[3%] w-[10%]" />
                                    <p className="lg:text-xl text-base font-semibold">Le coût :</p>
                                </div>

                                <div className="w-11/12">
                                    <p>Notre système de login vous permettra d’accéder à notre grille tarifaire générale, mais également, le cas échéant à votre grille tarifaire négociée en tant que pro. Vous pourrez ainsi faire valoir de suite notre partenariat et ferez bénéficier vos clients de votre réseau!</p>
                                </div>
                            </div>

                            <div className="w-11/12 flex flex-col items-center space-y-2">
                                <div className="flex flex-row items-end w-full space-x-2">
                                    <img src={confiance} alt="" className="lg:w-[3%] w-[10%]" />
                                    <p className="lg:text-xl text-base font-semibold">Un système d’information fiable :</p>
                                </div>

                                <div className="w-11/12">
                                    <p>Via notre formulaire, Profitez de l’entrevue en direct avec votre client afin de nous communiquer les informations nécessaires à la bonne réalisation de leur diagnostic. N’oubliez pas, la véracité des informations glanées renforce la précision du diagnostic réalisé!!</p>
                                </div>
                            </div>

                            <div className="w-11/12 flex flex-col items-center space-y-2">
                                <div className="flex flex-row items-end w-full space-x-2">
                                    <img src={interactions} alt="" className="lg:w-[3%] w-[10%]" />
                                    <p className="lg:text-xl text-base font-semibold">Une mise en relation immédiate :</p>
                                </div>

                                <div className="w-11/12">
                                    <p>Dès lors que vous remplissez le formulaire de renseignement du bien commercialisé, notre équipe reçoit un mail instantanément avec les coordonnées de votre client nous permettant de prendre contact avec lui directement.</p>
                                </div>
                            </div>

                            <div className="w-11/12 flex flex-col items-center space-y-2">
                                <div className="flex flex-row items-end w-full space-x-2">
                                    <img src={reponses} alt="" className="lg:w-[3%] w-[10%]" />
                                    <p className="lg:text-xl text-base font-semibold">Une réponse personnalisée :</p>
                                </div>

                                <div className="w-11/12">
                                    <p>Car un parc immobilier comprend énormément de spécificités, posez-nous vos questions via l’espace blog afin de traiter toute demande sortant des sentiers battus.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Devenir partenaire */}
                <div className="w-full bg-[#006297] py-10 text-white lg:space-y-20 space-y-10 flex justify-center mt-10">
                    <div className="w-11/12">
                        <div className="space-y-10 lg:text-base text-sm">
                            <div className="flex flex-col lg:space-y-4 space-y-2">
                                <p className="font-black text-sm">IMMOTECH</p>

                                <div className="flex flex-col space-y-1">
                                    <p className="lg:text-5xl text-xl font-bold">Devenir partenaire</p>
                                    <div className="bg-white h-1 lg:w-60 w-32 rounded"></div>
                                </div>
                            </div>

                            <div className="flex justify-center w-full">
                                <div className="lg:w-1/2 w-full bg-white text-[#006297] rounded-md p-4">
                                    <Form onSubmit={handlePartner} className="w-full space-y-2" noValidate validated={validated}>
                                        <div className="flex flex-col">
                                            <label htmlFor="companyName">Nom de la société*</label>
                                            <Form.Control
                                                type="text"
                                                className="border-2 rounded border-[#006297] text-[#006297]"
                                                id="companyName"
                                                name="companyName"
                                                required
                                                isInvalid={validated && errors?.companyName}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid" className="bg-red-100 border-2 border-red-600 text-center rounded-sm">
                                                Le champ "Nom de la société" est requis
                                            </Form.Control.Feedback>
                                        </div>

                                        <div className="flex flex-col">
                                            <label htmlFor="email">Adresse mail de la société*</label>
                                            <Form.Control
                                                type="text"
                                                className="border-2 rounded border-[#006297] text-[#006297]"
                                                id="email"
                                                name="email"
                                                required
                                                isInvalid={validated && errors?.email}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid" className="bg-red-100 border-2 border-red-600 text-center rounded-sm">
                                                Le champ "Adresse mail de la société" est requis
                                            </Form.Control.Feedback>
                                        </div>

                                        <div className="flex flex-col">
                                            <label htmlFor="phone">Téléphone de la société*</label>
                                            <Form.Control
                                                type="text"
                                                className="border-2 rounded border-[#006297] text-[#006297]"
                                                id="phone"
                                                name="phone"
                                                required
                                                isInvalid={validated && errors?.phone}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid" className="bg-red-100 border-2 border-red-600 text-center rounded-sm">
                                                Le champ "Téléphone de la société" est requis
                                            </Form.Control.Feedback>
                                        </div>
                                        
                                        <div className="flex flex-row w-full space-x-6">
                                            <div className="flex flex-col w-1/2">
                                                <label htmlFor="lastname">Nom</label>
                                                <Form.Control
                                                    type="text"
                                                    className="border-2 rounded border-[#006297] text-[#006297]"
                                                    id="lastname"
                                                    name="lastname"
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="flex flex-col w-1/2">
                                                <label htmlFor="firstname">Prénom</label>
                                                <Form.Control
                                                    type="text"
                                                    className="border-2 rounded border-[#006297] text-[#006297]"
                                                    id="firstname"
                                                    name="firstname"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex flex-row space-x-2 lg:text-sm text-xs">
                                            <Form.Check type="checkbox"  id="consent">
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    className="border-2 rounded border-[#006297] text-[#006297]"
                                                    name="consent"
                                                    required
                                                    isInvalid={validated && errors?.consent}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check.Label className="text-[#006297]">En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la demande de contact et de la relation commerciale qui peut en découler.*</Form.Check.Label>
                                                <Form.Control.Feedback type="invalid" className="bg-red-100 border-2 border-red-600 text-center rounded-sm">
                                                    Veuillez valider la réquisition de vos données
                                                </Form.Control.Feedback>
                                            </Form.Check>
                                        </div>

                                        <p className="lg:text-sm text-xs">Ce site est protégé par reCAPTCHA de Google, d'une Politique de Confidentialité et de Conditions d'utilisation.</p>

                                        <div className="space-y-1">
                                            <LoadingButton
                                                variant="contained"
                                                type="submit"
                                                loading={isLoading}
                                                disabled={isLoading}
                                                sx={{backgroundColor: colors.blue}}
                                                >
                                                Envoyer
                                            </LoadingButton>
                                            <p className="lg:text-sm text-xs">*Champs obligatoires</p>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledDiv>
    )
}

export default Partenaire