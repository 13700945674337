import { useEffect, useState } from "react"
import {
  useAddArticleMutation,
  useDeleteArticleMutation,
  useEditArticleMutation,
  useGetArticleQuery,
} from "../../features/articleSlice"
import File from "../../models/file"
import Article from "../../models/article"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  IconButton,
  InputLabel,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { ResponseApiException } from "../../models/responseApiType"
import styled from "@emotion/styled"
import colors from "../../style/colors"
import { LoadingButton } from "@mui/lab"
import { getMessageFromType } from "../../utils/utils"
import { toast } from "react-toastify"
import ValidateDialog from "../ValidateDialog"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWarning } from "@fortawesome/free-solid-svg-icons"

interface ModalProps {
  show: boolean
  handleClose: () => void
  articleId?: number
  isUpdate?: boolean
}

const StyledModal = styled(Dialog)`
  font-size: 0.9rem;
  .modal-title {
    font-size: 1rem !important;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem !important;
    }
  }

  .modal-header {
    background-color: ${colors.blue};
    color: white;
  }
`

function ArticleModal(modalProps: ModalProps) {
  const initialArticleValue: Partial<Article> = {
    description: "",
    title: "",
  }

  const initialFileValue: File = {
    alt: "",
    name: "",
    id: undefined,
    data: "",
  }

  const [addArticle, { isLoading: isPostLoading }] = useAddArticleMutation()
  const [editArticle, { isLoading: isEditLoading }] = useEditArticleMutation()
  const [postImage, setPostImage] = useState(initialFileValue)
  const [postArticle, setPostArticle] = useState(initialArticleValue)
  const [thumbnailPreview, setThumbnailPreview] = useState("")
  const [errors, setErrors] = useState({} as any)
  const [skip, setSkip] = useState(true)
  const [showConfirm, setShowConfirm] = useState(false)

  const {
    data: article,
    isSuccess,
    isError,
  }: {
    data: Article
    isSuccess: boolean
    isError: boolean
  } = useGetArticleQuery(modalProps.articleId, { skip })

  const [deleteArticle, { isLoading: isDeleteLoading }] =
    useDeleteArticleMutation()

  useEffect(() => {
    if (modalProps.isUpdate) {
      setSkip(false)
      if (isSuccess) {
        console.log(article)
        setPostArticle((prev: any) => ({
          ...prev,
          description: article?.description ?? "",
          descriptionDecoded: JSON.parse(article?.descriptionDecoded ?? ""),
          title: article?.title ?? "",
        }))

        setPostImage({
          id: article?.thumbnail?.id,
          data: "",
          name: article?.thumbnail?.name,
          alt: article?.thumbnail?.alt,
        })
        setThumbnailPreview(
          `${process.env.REACT_APP_API_ARTICLE_URL}/${article?.thumbnail?.name}`
        )
      } else if (isError) {
        toast.error(
          "Une erreur s'est produite lors de la récupération de l'article"
        )
      }
    }
  }, [modalProps.articleId, article, modalProps.isUpdate, isSuccess, isError])

  const handleAddOrEditArticle = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    let article = {
      title: postArticle.title,
      description: postArticle.descriptionDecoded,
      thumbnail: postImage,
    } as Article

    if (modalProps.isUpdate) {
      console.log(article)
      editArticle({
        id: modalProps.articleId,
        body: article,
      })
        .unwrap()
        .then(() => {
          toast.success("L'article a bien été modifié")
          modalProps.handleClose()
        })
        .catch((error: ResponseApiException) => {
          let msg = getMessageFromType(error)
          if (error.status === 500) toast.error(msg)
          else setErrors(error.data?.detail)
        })
    } else {
      addArticle(article)
        .unwrap()
        .then(() => {
          toast.success("L'article a bien été sauvegardé")
          modalProps.handleClose()
        })
        .catch((error: ResponseApiException) => {
          let msg = getMessageFromType(error)
          if (error.status === 500) toast.error(msg)
          else setErrors(error.data?.detail)
        })
    }
  }

  const convertToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }
  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0]
    await convertToBase64(file)
      .then((result) => {
        setPostImage((prev: any) => ({
          ...prev,
          data: result,
          name: file.name,
          id: 0,
        }))
        setThumbnailPreview(URL.createObjectURL(file))
      })
      .catch((error) => console.error(error))
  }

  const handleArticleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement> | any) => {
    if (name === "alt") {
      setPostImage((prev: any) => ({
        ...prev,
        alt: value,
      }))
    } else setPostArticle((prev: any) => ({ ...prev, [name]: value }))
  }

  const handleDeleteArticle = (articleId?: number) => {
    if (articleId) {
      deleteArticle(articleId)
        .unwrap()
        .then(() => {
          modalProps.handleClose()
          toast.success("L'actualité a bien été supprimée")
        })
        .catch((error: ResponseApiException) => {
          let msg = getMessageFromType(error)
          toast.error(msg)
        })
        .finally(() => setShowConfirm(false))
    }
  }

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <>
      <StyledModal
        fullWidth
        fullScreen={fullScreen}
        open={modalProps.show}
        onClose={modalProps.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
      >
        <form noValidate onSubmit={handleAddOrEditArticle}>
          <DialogTitle id="responsive-dialog-title" sx={{ color: colors.blue }}>
            {modalProps.isUpdate
              ? "Modification d'une actualité"
              : "Ajout d'une actualité"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Veuillez renseigner les informations sur l'article
            </DialogContentText>
            <TextField
              autoFocus
              margin="normal"
              id="title"
              label="Titre"
              type="text"
              fullWidth
              required
              variant="standard"
              name="title"
              onChange={handleArticleChange}
              error={errors.title}
              value={postArticle?.title}
              helperText={errors.title && "Le titre est requis"}
            />

            <div className="my-2">
              <InputLabel htmlFor="thumb" sx={{ paddingBottom: 1 }}>
                Image *
              </InputLabel>
              <Button
                color="info"
                variant="outlined"
                component="label"
                className="mt-2"
              >
                Charger image
                <input
                  id="thumb"
                  hidden
                  accept="image/*"
                  onChange={handleFileUpload}
                  type="file"
                />
              </Button>
            </div>
            {thumbnailPreview && (
              <div className="md:w-56">
                <img src={thumbnailPreview} alt="" className="img-thumbnail" />
              </div>
            )}
            {errors["thumbnail.file"] && (
              <div>
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {errors["thumbnail.file"]}
                </FormHelperText>
              </div>
            )}
            <TextField
              margin="normal"
              id="alt"
              label="Alt"
              type="text"
              fullWidth
              variant="standard"
              name="alt"
              onChange={handleArticleChange}
              error={errors.alt}
              value={postImage?.alt}
              helperText="Description courte de l'image. Important pour le référencement."
            />
            <TextField
              margin="normal"
              id="desc"
              label="Contenu"
              fullWidth
              variant="standard"
              name="descriptionDecoded"
              multiline
              required
              minRows={4}
              onChange={(e) => handleArticleChange(e)}
              error={errors.description}
              value={postArticle?.descriptionDecoded || ""}
              helperText={errors.description}
            />
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: modalProps.isUpdate
                ? "space-between"
                : "flex-end",
              paddingBottom: 2,
            }}
          >
            {modalProps.isUpdate && (
              <IconButton
                aria-label="Supprimer"
                title="Supprimer"
                size="large"
                color="error"
                onClick={() => setShowConfirm(true)}
              >
                <DeleteIcon />
              </IconButton>
            )}
            <div>
              <Button onClick={modalProps.handleClose}>Annuler</Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isPostLoading || isEditLoading}
                disabled={isPostLoading || isEditLoading}
                color="info"
              >
                Sauvegarder
              </LoadingButton>
            </div>
          </DialogActions>
        </form>
      </StyledModal>
      <ValidateDialog
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        modalTitle="Supprimer article"
        handleConfirm={() => handleDeleteArticle(modalProps.articleId)}
        loading={isDeleteLoading}
      >
        <div className="flex flex-col gap-3 justify-center text-center">
          <FontAwesomeIcon icon={faWarning} color="darkred" size="2x" />
          Êtes-vous sûr de vouloir supprimer l'article ?
        </div>
      </ValidateDialog>
    </>
  )
}

export default ArticleModal
