import Diagnostic from "../models/Diagnostic"
import Pack from "../models/pack"
import { apiSlice, TAG_DIAGNOSTIC, TAG_PRICES } from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDiagnostics: builder.query<Diagnostic[], void>({
      query: () => {
        return { url: "admin/administrator/diagnostics" }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: `${TAG_DIAGNOSTIC}` as const,
                id,
              })),
              { type: TAG_DIAGNOSTIC, id: "LIST" },
            ]
          : [{ type: TAG_DIAGNOSTIC, id: "LIST" }],
    }),
    getPrices: builder.query<Pack[], void>({
      query: () => {
        return { url: "admin/administrator/pack-prices" }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: `${TAG_PRICES}` as const,
                id,
              })),
              { type: TAG_PRICES, id: "LIST" },
            ]
          : [{ type: TAG_PRICES, id: "LIST" }],
    }),
  }),
})

export const { useGetDiagnosticsQuery, useGetPricesQuery } = extendedApiSlice
