import { useMemo, useState } from "react"
import News from "../../models/article"
import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/bg-news.jpg"
import Card from "../../components/Card"
import { Container, Row } from "react-bootstrap"
import { cutText, formatStrToDate } from "../../utils/utils"
import { useGetArticlesWithPaginationQuery } from "../../features/articleSlice"
import InternError from "../../components/InternError"
import Pagination from "@mui/material/Pagination"
import { useNavigate } from "react-router-dom"
import Loader from "../../components/Loader"

function NewsSection() {
  let navigate = useNavigate()

  const [page, setPage] = useState(1)
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value)
    document.getElementById("articles")?.scrollIntoView()
  }
  const {
    data: articlesPaginate = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetArticlesWithPaginationQuery({ limit: 9, page: page })

  const sortedArticles = useMemo(() => {
    const articles = articlesPaginate.items ?? []
    const sortedArticles = articles.slice()
    // Sort posts in descending chronological order
    sortedArticles.sort((a: News, b: News) =>
      b.createdAt.localeCompare(a.createdAt)
    )
    return sortedArticles
  }, [articlesPaginate])

  let content
  let paginate

  if (isLoading) {
    content = <Loader />
  } else if (isSuccess) {
    if (sortedArticles.length > 0) {
      content = sortedArticles.map((newsItem: News) => (
        <Card
          className="col-md-5 col-lg-3"
          key={newsItem.id}
          title={newsItem.title}
          content={cutText(JSON.parse(newsItem.descriptionDecoded), 100)}
          image={{
            path: `${process.env.REACT_APP_API_ARTICLE_URL}/${newsItem.thumbnail?.name}`,
            alt: newsItem.thumbnail?.alt,
            name: newsItem.thumbnail?.name,
          }}
          labelChips={formatStrToDate(newsItem.createdAt, "PPP") ?? ""}
          labelButton="Lire plus"
          buttonOnClick={() => navigate(`/blog/${newsItem.id}`)}
        />
      ))

      if (articlesPaginate.totalPages > 1) {
        paginate = (
          <Row>
            <Pagination
              count={articlesPaginate.totalPages}
              color="primary"
              page={page}
              onChange={handlePageChange}
              className="justify-center flex mb-5"
            />
          </Row>
        )
      }
    } else {
      content = "Aucun article"
    }
  } else if (isError) {
    content = <InternError />
  }
  return (
    <div>
      <WaveHeader title="Blog" image={bgImage} />
      <section id="articles">
        <Container fluid>
          <Row
            style={{ gap: "1rem", paddingTop: "4rem" }}
            className="justify-content-center"
          >
            {content}
          </Row>
          {paginate}
        </Container>
      </section>
    </div>
  )
}

export default NewsSection
