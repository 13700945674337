import {
  faChartSimple,
  faCrown,
  faHandshake,
  faListCheck,
  faNewspaper,
  faPersonCircleQuestion,
  faRectangleList,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons"
import PersonPinIcon from "@mui/icons-material/PersonPin"
import {
  styled as muiStyled,
  useTheme,
  Theme,
  CSSObject,
} from "@mui/material/styles"
import logo from "../../assets/logo2.png"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MuiDrawer from "@mui/material/Drawer"
import Settings from "@mui/icons-material/Settings"
import Logout from "@mui/icons-material/Logout"
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import {
  Link,
  Outlet,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom"
import User from "../../models/user"
import { useAuth } from "../../utils/hooks/useAuth"
import { Fragment, useState } from "react"
import MenuIcon from "@mui/icons-material/Menu"
import styled from "@emotion/styled"
import colors from "../../style/colors"
import { isGranted, RoleType } from "../../utils/utils"

const StyledDiv = styled.div`
  font-size: 0.7rem;
  align-items: center;

  @media (max-width: 576px) {
    button {
      font-size: 0.7rem;
      padding: 0.2rem 0.3rem;
    }
  }

  @media (min-width: 768px) {
    font-size: 0.9rem;
  }
`

const drawerWidth = 300

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = muiStyled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: colors.blue, //"#202020",
  color: "white",
  boxShadow: "none",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const openedMixin = (theme: Theme): CSSObject => ({
  //backgroundColor: "white",
  backgroundColor: colors.blue,
  //color: "#616161",
  color: "white",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
  //backgroundColor: "white",
  backgroundColor: colors.blue,
  //color: "#616161",
  color: "white",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  border: "none!important",
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
})

const Drawer = muiStyled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  zIndex: 200,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

const DrawerHeader = muiStyled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1.5, 1, 0.5, 1.5),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: "50px!important",
}))

function CustomButton({ menu, open, handleMenuItemClick }: any) {
  let resolved = useResolvedPath(menu.path)
  let match = useMatch({ path: resolved.pathname, end: true })

  return (
    <ListItemButton
      sx={{
        minHeight: 48,
        justifyContent: open ? "initial" : "center",
        px: { sm: 1.7, xs: 1.3 },
        backgroundColor: match
          ? /*"#00639833"*/ "rgba(255,255,255,0.3)"
          : "none",
        borderRadius: match ? (open ? "10px" : "0 10px 10px 0") : "0",
        mx: { sm: open ? 1 : 0, xs: open ? 0.3 : 0 },
      }}
      onClick={handleMenuItemClick}
    >
      <ListItemIcon
        title={menu.text}
        sx={{
          minWidth: 0,
          mr: open ? 2 : "auto",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon
          icon={menu.icon}
          style={{
            color: match
              ? "white"
              : "rgba(255,255,255,0.7)" /*colors.blue : "#616161"*/,
            width: "20px",
          }}
        ></FontAwesomeIcon>
      </ListItemIcon>
      <ListItemText
        primary={menu.text}
        sx={{
          opacity: open ? 1 : 0,
          wordBreak: "break-all",
        }}
        style={{
          color: match
            ? "white"
            : "rgba(255,255,255,0.7)" /*colors.blue : "rgba(1,1,1,0.6)"*/,
        }}
      />
    </ListItemButton>
  )
}

function AdminMenu({ user }: { user: User }) {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const { logout } = useAuth()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const menuItems = []

  if (isGranted(user.roles, RoleType.RoleAdmin)) {
    menuItems.push({
      text: "Actualités",
      path: "/admin/news",
      icon: faNewspaper,
    })
  }

  /*menuItems.push({
    text: "Diagnostics",
    path: "/admin/diagnostics",
    icon: faRectangleList,
  })*/

  if (isGranted(user.roles, RoleType.RoleAdmin)) {
    menuItems.push(
      {
        text: "Prescripteurs",
        path: "/admin/prescripteurs",
        icon: faHandshake,
      },
      {
        text: "Demandes de renseignements",
        path: "/admin/renseignements",
        icon: faPersonCircleQuestion,
      }
    )
  }

  menuItems.push({
    text: "Demandes de diagnostics",
    path: "/admin/diagRequest",
    icon: faChartSimple,
  })

  if (isGranted(user.roles, RoleType.RoleAdmin)) {
    menuItems.push({
      text: "Demandes d'inscription",
      path: "/admin/inscriptions",
      icon: faUserPlus,
    })
  }
  menuItems.push({
    text: "Questionnaire",
    path: "/admin/questions",
    icon: faListCheck,
  })
  if (isGranted(user.roles, RoleType.RoleAdmin)) {
    menuItems.push({
      text: "Utilisateurs Admin",
      path: "/admin/administrateurs",
      icon: faCrown,
    })
  }

  const navigate = useNavigate()

  const handleMenuItemClick = (menu: any) => {
    setOpen(false)
    navigate(menu.path)
  }
  const ListItemComponent = (props: any) => {
    let menu = props.menu

    return (
      <ListItem
        key={menu.text}
        disablePadding
        sx={{
          display: "block",
        }}
      >
        <CustomButton
          menu={menu}
          open={open}
          handleMenuItemClick={() => handleMenuItemClick(menu)}
        ></CustomButton>
      </ListItem>
    )
  }

  const drawer = (
    <Fragment>
      <DrawerHeader>
        <div className="flex items-center gap-3 py-1 pb-2">
          <Avatar
            variant="rounded"
            src={logo}
            alt="logo immotech"
            sx={{ width: 70, height: { md: 60, xs: 50 } }}
          />
          <Typography color={"white" /*colors.blue*/} fontWeight={600}>
            IMMOTECH
          </Typography>
        </div>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon style={{ color: "white" /*colors.blue*/ }} />
          ) : (
            <ChevronLeftIcon style={{ color: "white" /*colors.blue*/ }} />
          )}
        </IconButton>
      </DrawerHeader>
      <List>
        {menuItems.map((menu, index) => {
          return <ListItemComponent key={index} menu={menu} />
        })}
      </List>
    </Fragment>
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const accountMenuOpen = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ paddingRight: "13px!important" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              paddingLeft: { sm: 0 },
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <StyledDiv className="flex w-full justify-between gap-1">
            <div>
              <Link to="/" className="no-underline">
                <Button
                  variant="text"
                  sx={{
                    color: "white",
                    borderColor: "white",
                  }}
                >
                  Aller sur le site
                </Button>
              </Link>
            </div>
            <div>
              <Tooltip title="Mon compte">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={accountMenuOpen ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={accountMenuOpen ? "true" : undefined}
                >
                  <PersonPinIcon fontSize="large" sx={{ color: "white" }} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={accountMenuOpen}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 0.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <p
                  className="px-1 text-center pt-1 pb-0 mb-1 fw-bold"
                  style={{ color: colors.blue }}
                >
                  {user?.firstname} {user?.lastname?.toUpperCase()}
                </p>
                <Divider />
                <MenuItem onClick={() => navigate("/admin/profil")}>
                  <Avatar /> Mon profil
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => navigate("/admin/parametres")}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Paramètres
                </MenuItem>
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Déconnexion
                </MenuItem>
              </Menu>
            </div>
          </StyledDiv>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        {drawer}
      </Drawer>
    </Fragment>
  )
}

export default function AdminLayout() {
  const {
    loggedUser: { user },
  } = useAuth()

  let content

  if (user && isGranted(user?.roles, RoleType.RolePartner)) {
    content = (
      <div>
        <AdminMenu user={user} />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <div id="main-container">
            <Outlet />
          </div>
        </Box>
      </div>
    )
  } else {
    content = <div>"Vous n'avez pas les droits pour accéder à cette page"</div>
  }
  return content
}
