import { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { Link, useParams } from "react-router-dom"
import InternError from "../../components/InternError"
import Loader from "../../components/Loader"
import WaveHeader from "../../components/WaveHeader"
import { useGetArticleQuery } from "../../features/articleSlice"
import Article from "../../models/article"
import { cutText, formatStrToDate } from "../../utils/utils"
import tached from "../../assets/forme.png"
import { useNavigate } from "react-router-dom"

const StyledDiv = styled.div`
    p {
        margin: 0;
        padding: 0;
    }
`

function NewsItem() {
    const {id} = useParams()
    console.log(id);
    const [precedent, setPrecedent] = useState<number | null>(null)
    const [suivant, setSuivant] = useState<number | null>(null)
    const {
        data: article,
        isLoading,
        isSuccess,
        isError,
      }:{data: Article; isLoading:boolean; isSuccess:boolean; isError:boolean} = useGetArticleQuery(id)

    useEffect(() => {
      const previous = (article?.id ?? 0) + 1
      setPrecedent(previous)
    }, [article])

    useEffect(() => {
      const next = (article?.id ?? 0) - 1
      setSuivant(next)
    }, [article])

    console.log(suivant)

    let buttonSuivant:any
    let buttonPrecedent:any

    // if (suivant != 0) {
    //   buttonSuivant = <Link to={`/blog/${suivant}`} className="no-underline bg-[#006297] text-white rounded p-2">Suivant</Link>;
    // } else {
    //   buttonSuivant = <div></div>;
    // }

    let content:any 

    if (isLoading) {
        content = <Loader />
      } else if (isSuccess) {
          if (article.id != null) {
            console.log(article.id)
            content = (
              <div className="flex flex-col items-center justify-center space-y-5 w-full">
                  <div className="flex flex-col items-start justify-center w-[90%] space-y-5">
                      <p>{JSON.parse(article.descriptionDecoded)}</p>

                      <div className="flex justify-center w-full">
                          <div className="grid grid-cols-1 gap-y-2 w-1/3">
                              <img src={`${process.env.REACT_APP_API_ARTICLE_URL}/${article?.thumbnail?.name}`} alt="" />
                          </div>
                      </div>
                  </div>

                  <div className="w-full">
                      <p className="text-[#999999]">Créé le : {formatStrToDate(article.createdAt, "PPP") ?? ""}</p>
                      <p className="text-[#999999]">Dernière modification le : {formatStrToDate(article.updatedAt, "PPP") ?? ""}</p>
                  </div>

                  {/* <div className="w-full flex flex-row justify-between my-5">
                      <div className="">
                        {buttonPrecedent}
                      </div>

                      <div className="">
                        {buttonSuivant}
                      </div>
                  </div> */}
                  <div className="w-full flex flex-row justify-between my-5">
                    <Link to={`/blog`} className="no-underline bg-[#006297] text-white rounded p-2">Retour aux articles</Link>
                  </div>
              </div>
            )
          } else {
            content (
              <div>
                <p>Cette article n'existe pas</p>
              </div>
            )
          }
        } else if (isError) {
        content = <InternError />
      }

      return (
          <div className="mb-12 overflow-x-hidden">
            <WaveHeader title={article?.title} image={`${process.env.REACT_APP_API_ARTICLE_URL}/${article?.thumbnail?.name}`} />

            <div className="w-full flex flex-col items-center px-20 mt-10">
              <StyledDiv className="flex flex-col items-start justify-center space-y-5 w-full relative">
                  <p className="text-[#999999]">Article du blog</p>
                  
                  {content}

                  <img src={tached} alt="tache droite" className="absolute -z-10 right-0 top-0 w-1/3 -mr-[15vw]" />
              </StyledDiv>
            </div>
          </div>
        )
}

export default NewsItem