import styled from "@emotion/styled"
import { createTheme, ThemeProvider } from "@mui/material"
import { Outlet, Route, Routes } from "react-router-dom"
import Header from "../components/Header"
import Footer from "../components/Footer"
import BackToTop from "../components/BackToTop"
import NoMatch from "./NoMatch"
import { Navigate } from "../utils/Navigate"
import { PrivateRoute } from "../utils/PrivateRoute"
import AdminLayout from "../components/AdminLayout"
import Home from "./Home"
import Login from "./Login"
import { Fragment } from "react"
import News from "./Admin/News"
import { frFR } from "@mui/material/locale"
import ResetPassword from "./ResetPassword"
import ResetPasswordToken from "./ResetPasswordToken"
import Parameters from "./Admin/Parameters"
import Profile from "./Admin/Profile"
import MentionsLegales from "./MentionsLegales"
import ConfidentialPolitic from "./ConfidentialPolitic"
import NewsSection from "./News"
import AdminAccounts from "./Admin/AdminAccounts"
import { AdminRoute, PartnerRoute } from "../utils/AdminRoute"
import PartnerAccounts from "./Admin/PartnerAccounts"
import PartnerDetail from "./Admin/PartnerDetail"
import NewsItem from "./NewsItem"
import Contact from "./Contact"
import Presentation from "./Presentation"
import Partenaire from "./Partenaire"
import Services from "./Services"
import DiagnosticsAdmin from "./Admin/Diagnostic"
import Diagnostics from "./Diagnostics"
import Subscriptions from "./Admin/Subscriptions"
import SubsDetail from "./Admin/SubsDetail"
import Renseignements from "./Admin/Renseignements"
import Questions from "./Admin/Questions"
import DiagRequestContent from "./Admin/DiagRequest"
import RenseignementsDetail from "./Admin/RenseignementsDetail"
import DiagRequestDetail from "./Admin/DiagRequestDetail"

const MainDiv = styled.div``

function Layout() {
  return (
    <Fragment>
      <Header />
      <BackToTop />
      <MainDiv>
        <Outlet />
      </MainDiv>
      <Footer />
    </Fragment>
  )
}

function Content() {
  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route
          path="/reset-password/:token"
          element={<ResetPasswordToken />}
        ></Route>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />}></Route>
          <Route path="blog" element={<NewsSection />}></Route>
          <Route path="mentions-legales" element={<MentionsLegales />}></Route>
          <Route
            path="politique-confidentialite"
            element={<ConfidentialPolitic />}
          ></Route>
          <Route path="presentation" element={<Presentation />}></Route>
          <Route path="diagnostics" element={<Diagnostics />}></Route>
          <Route path="services" element={<Services />}></Route>
          <Route path="blog/:id" element={<NewsItem />}></Route>
          <Route path="contact" element={<Contact />}></Route>
          <Route path="partenaire" element={<Partenaire />}></Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route
          path="admin"
          element={
            <PrivateRoute>
              <AdminLayout />
            </PrivateRoute>
          }
        >
          <Route
            path="news"
            element={
              <AdminRoute>
                <News />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="diagnostics"
            element={
              <PartnerRoute>
                <DiagnosticsAdmin />
              </PartnerRoute>
            }
          ></Route>
          <Route
            path="prescripteurs"
            element={
              <AdminRoute>
                <PartnerAccounts />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="prescripteurs/:id"
            element={
              <AdminRoute>
                <PartnerDetail />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="renseignements"
            element={
              <AdminRoute>
                <Renseignements />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="renseignements/:id"
            element={
              <AdminRoute>
                <RenseignementsDetail />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="diagRequest"
            element={
              <PartnerRoute>
                <DiagRequestContent />
              </PartnerRoute>
            }
          ></Route>
          <Route
            path="diagRequest/:id"
            element={
              <PartnerRoute>
                <DiagRequestDetail />
              </PartnerRoute>
            }
          ></Route>
          <Route
            path="inscriptions"
            element={
              <AdminRoute>
                <Subscriptions />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="inscriptions/:id"
            element={
              <AdminRoute>
                <SubsDetail />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="questions"
            element={
              <PartnerRoute>
                <Questions />
              </PartnerRoute>
            }
          ></Route>
          <Route
            path="administrateurs"
            element={
              <AdminRoute>
                <AdminAccounts />
              </AdminRoute>
            }
          ></Route>
          <Route path="profil" element={<Profile />}></Route>
          <Route path="parametres" element={<Parameters />}></Route>
          <Route index element={<Navigate to="profil" replace />} />
        </Route>
      </Routes>
    </div>
  )
}

export default function App() {
  const theme = createTheme(
    {
      typography: {
        fontFamily: `"Nunito", sans-serif;`,
      },
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    frFR
  )

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Content />
      </div>
    </ThemeProvider>
  )
}
