export enum ResponseApiType {
  BadCredentials = "invalid_credentials",
  TokenExpired = "token_expired",
  CustomError = "custom_error",
  FormError = "error_form",
  InternalError = "about:blank",
}

export interface ResponseApiException {
  data: DataContent
  status: number
}

interface DataContent {
  status: number
  title: string
  type: ResponseApiType
  detail?: any
}
