import styled from "@emotion/styled"
import { LoadingButton } from "@mui/lab"
import { Breadcrumbs, Typography } from "@mui/material"
import { Link, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import InternError from "../../../components/InternError"
import Loader from "../../../components/Loader"
import { useGetDiagnosticsQuery } from "../../../features/diagnosticSlice"
import {
  useGetPublicRequestInfosQuery,
  usePendingPublicRequestMutation,
  useTraitedPublicRequestMutation,
} from "../../../features/publicRequestSlice"
import Diagnostic from "../../../models/Diagnostic"
import PublicRequest, {
  getStatusTypeLabel,
  StatusPubDiagType,
} from "../../../models/publicRequest"
import { ResponseApiException } from "../../../models/responseApiType"
import colors from "../../../style/colors"
import GlobalBgStyle from "../../../style/GlobalBgStyle"
import { formatStrToDate, getMessageFromType } from "../../../utils/utils"
import fleche from "../../../assets/right-arrow.png"

const StyledDiv = styled.div`
  h1 {
    font-size: 1.4rem;
    text-align: center;
  }

  @media all and (min-width: 768px) {
    h1 {
      font-size: 1.7rem;
    }
  }

  h2 {
    color: ${colors.blue};
  }

  h3 {
    font-size: 1.3rem;
    font-weight: bolder;
    color: ${colors.blue};
    text-decoration: underline;
  }
`
const StyledSpan = styled.span`
  font-weight: bold;
`

const NoneSpan = styled.span`
  color: darkgrey;
  font-style: italic;
`

const DiagSpan = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin: 5px 0;
`

function RenseignementsDetail() {
  let { id } = useParams()

  const [TraitedPub, { isLoading: isTraitedLoading }] =
    useTraitedPublicRequestMutation()
  const [PendingPub, { isLoading: isPendingLoading }] =
    usePendingPublicRequestMutation()

  const Traitedpub = () => {
    console.log(pubReq.id)
    TraitedPub(pubReq?.id)
      .unwrap()
      .then(() => {
        toast.success("La demande de diagnostic a été traitée")
      })
      .catch((error: ResponseApiException) => {
        let msg = getMessageFromType(error)
        toast.error(msg)
      })
  }
  const Pendingpub = () => {
    console.log(pubReq.id)
    PendingPub(pubReq?.id)
      .unwrap()
      .then(() => {
        toast.success("La demande de diagnostic est en cours de traitement")
      })
      .catch((error: ResponseApiException) => {
        let msg = getMessageFromType(error)
        toast.error(msg)
      })
  }

  const {
    data: pubReq,
    isSuccess,
    isError,
    isLoading,
  }: {
    data: PublicRequest
    isSuccess: boolean
    isError: boolean
    isLoading: boolean
  } = useGetPublicRequestInfosQuery(id)

  let content

  if (isLoading) {
    content = <Loader />
  } else if (isSuccess) {
    content = (
      <>
        <h2 className="text-center mb-4">
          {pubReq?.lastname} {pubReq.firstname}
        </h2>
        <div className="shadow bg-white p-2 p-sm-4">
          <h3>Informations</h3>
          <div className="my-4">
            <div>
              <StyledSpan>Nom : </StyledSpan>
              {pubReq.lastname}
            </div>
            <div>
              <StyledSpan>Prénom : </StyledSpan>
              {pubReq.firstname}
            </div>
            <div>
              <StyledSpan>Adresse Email : </StyledSpan>
              {pubReq.email}
            </div>
            <div>
              <StyledSpan>Numéro : </StyledSpan>
              {pubReq.number}
            </div>
            <div>
              <StyledSpan>Numéro de Téléphone : </StyledSpan>
              {pubReq.phone}
            </div>
            <div>
              <StyledSpan>Demande effectuée le : </StyledSpan>{" "}
              {formatStrToDate(pubReq.createdAt, "dd/MM/yyyy")}
            </div>
          </div>
          <h3>Demande de renseignements</h3>
          <div className="my-4 flex flex-col flex-wrap justify-between">
            <div className="flex flex-row space-x-2">
              <StyledSpan>Status :</StyledSpan>
              {pubReq.status === "ToHandle" ? (
                <p className="bg-[#777777] text-white rounded-full px-2">
                  {getStatusTypeLabel(StatusPubDiagType.TO_HANDLE)}
                </p>
              ) : pubReq.status === "Traited" ? (
                <p className="bg-[#007700] text-white rounded-full px-2">
                  {getStatusTypeLabel(StatusPubDiagType.TRAITED)}
                </p>
              ) : (
                <p className="bg-[#dfe608] rounded-full px-2">
                  {getStatusTypeLabel(StatusPubDiagType.PENDING)}
                </p>
              )}
            </div>
          </div>
          <h3>Vérifications à faire</h3>

          <div className="my-4 flex flex-col flex-wrap justify-between">
            {pubReq.diagnostics?.map((diag: Diagnostic) => (
              <div className="flex flex-row space-x-2">
                <DiagSpan>
                  <img
                    src={fleche}
                    alt="Fleche droite"
                    className="w-[2%] mr-3"
                  />{" "}
                  {diag.name}
                </DiagSpan>
              </div>
            ))}
            <div className="flex lg:flex-row flex-col text-center justify-center">
              {" "}
              {pubReq.status === "ToHandle" ? (
                <div className="flex flex-col items-center">
                  <p>
                    Cette demande est-elle traitée ou en cours de traitement ?
                  </p>
                  <div className="flex flex-row space-x-8">
                    <LoadingButton
                      type="submit"
                      loading={isTraitedLoading}
                      disabled={isTraitedLoading}
                      onClick={Traitedpub}
                      className="text-green-800 border-2 rounded px-2 py-1 border-green-800"
                    >
                      Traitée
                    </LoadingButton>
                    <LoadingButton
                      type="submit"
                      loading={isPendingLoading}
                      disabled={isPendingLoading}
                      onClick={Pendingpub}
                      className="text-[#dfe608] border-2 rounded px-2 py-1 border-[#dfe608]"
                    >
                      En cours de traitement
                    </LoadingButton>
                  </div>
                </div>
              ) : pubReq.status === "Pending" ? (
                <div className="flex flex-col items-center">
                  <p>Cette demande à été traitée ?</p>
                  <div className="flex flex-row space-x-8">
                    <LoadingButton
                      type="submit"
                      loading={isTraitedLoading}
                      disabled={isTraitedLoading}
                      onClick={Traitedpub}
                      className="text-green-800 border-2 rounded px-2 py-1 border-green-800"
                    >
                      Traitée
                    </LoadingButton>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <NoneSpan>Demande déjà traitée.</NoneSpan>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  } else if (isError) {
    content = <InternError />
  }

  return (
    <StyledDiv className="container">
      <GlobalBgStyle />
      <Breadcrumbs>
        <Link to="/admin/renseignements" style={{ color: colors.darkBlue }}>
          Renseignements
        </Link>
        <Typography color="text.primary">Fiche détaillée</Typography>
      </Breadcrumbs>
      <h1>Fiche détaillée</h1>
      {content}
    </StyledDiv>
  )
}

export default RenseignementsDetail
