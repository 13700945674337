const colors = {
  violet: "#532c6e",
  orange: "#ffb72b",
  blue: "#006398",
  green: "#c3d631",
  darkViolet: "#270041",
  darkBlue: "#014b73",
  grey: "#333333",
  white: "#FFFFFF"
}

export default colors
