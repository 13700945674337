import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import colors from "../../style/colors"

interface DialogProps {
  show: boolean
  handleClose: () => void
  handleConfirm: () => void
  modalTitle?: string
  isUpdate?: boolean
  loading?: boolean
  children: any
}

function ValidateDialog(modalProps: DialogProps) {
  const { children } = modalProps
  return (
    <>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={modalProps.show}
      >
        <DialogTitle sx={{ backgroundColor: colors.blue, color: "white" }}>
          {modalProps.modalTitle ?? "Confirmation"}
        </DialogTitle>
        <DialogContent dividers sx={{ paddingTop: 3, paddingBottom: 3 }}>
          {children}
        </DialogContent>
        <DialogActions>
          <Button color="error" autoFocus onClick={modalProps.handleClose}>
            Annuler
          </Button>
          <LoadingButton
            variant="outlined"
            color="success"
            loading={modalProps.loading}
            startIcon={<FontAwesomeIcon icon={faCircleCheck} />}
            onClick={modalProps.handleConfirm}
          >
            Valider
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ValidateDialog
