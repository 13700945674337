import styled from "@emotion/styled"
import { LoadingButton } from "@mui/lab"
import { Breadcrumbs, Typography } from "@mui/material"
import { Link, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import InternError from "../../../components/InternError"
import Loader from "../../../components/Loader"
import { useGetSubInfosQuery } from "../../../features/partnerSubscriptionSlice"
import {
  useAddPartnerBySubMutation,
  useRemovePartnerBySubMutation,
} from "../../../features/userSlice"
import PartnerSubscription, {
  getStatusTypeLabel,
  StatusSubType,
} from "../../../models/partnerSubscription"
import { ResponseApiException } from "../../../models/responseApiType"
import colors from "../../../style/colors"
import GlobalBgStyle from "../../../style/GlobalBgStyle"
import { formatStrToDate, getMessageFromType } from "../../../utils/utils"

const StyledDiv = styled.div`
  h1 {
    font-size: 1.4rem;
    text-align: center;
  }

  @media all and (min-width: 768px) {
    h1 {
      font-size: 1.7rem;
    }
  }

  h2 {
    color: ${colors.blue};
  }

  h3 {
    font-size: 1.3rem;
    font-weight: bolder;
    color: ${colors.blue};
    text-decoration: underline;
  }
`

const StyledSpan = styled.span`
  font-weight: bold;
`

const NoneSpan = styled.span`
  color: darkgrey;
  font-style: italic;
`

function SubsDetail() {
  let { id } = useParams()

  const [addPartnerBySub, { isLoading: isAddLoading }] =
    useAddPartnerBySubMutation()
  const [removePartnerBySub, { isLoading: isRemoveLoading }] =
    useRemovePartnerBySubMutation()

  const approvedSub = () => {
    console.log(sub.id)
    addPartnerBySub(sub?.id)
      .unwrap()
      .then(() => {
        toast.success("La demande de partenaire a bien été confirmée")
      })
      .catch((error: ResponseApiException) => {
        let msg = getMessageFromType(error)
        toast.error(msg)
      })
  }

  const disapprovedSub = () => {
    console.log(sub.id)
    removePartnerBySub(sub?.id)
      .unwrap()
      .then(() => {
        toast.success("La demande de partenaire a bien été refusée")
      })
      .catch((error: ResponseApiException) => {
        let msg = getMessageFromType(error)
        toast.error(msg)
      })
  }

  const {
    data: sub,
    isSuccess,
    isError,
    isLoading,
  }: {
    data: PartnerSubscription
    isSuccess: boolean
    isError: boolean
    isLoading: boolean
  } = useGetSubInfosQuery(id)

  let content

  if (isLoading) {
    content = <Loader />
  } else if (isSuccess) {
    content = (
      <>
        <h2 className="text-center mb-4">{sub?.companyName}</h2>
        <div className="shadow bg-white p-2 p-sm-4">
          <h3>Informations</h3>
          <div className="my-4">
            <div>
              <StyledSpan>Nom société : </StyledSpan>
              {sub.companyName}
            </div>
            <div>
              <StyledSpan>Numéro : </StyledSpan>
              {sub.number}
            </div>
            <div>
              <StyledSpan>Email : </StyledSpan>
              {sub.email}
            </div>
            <div>
              <StyledSpan>Téléphone : </StyledSpan>
              {sub.phone}
            </div>
            <div>
              <StyledSpan>Contact : </StyledSpan>{" "}
              {sub.firstname || sub.lastname ? (
                `${sub.firstname} ${sub.lastname}`
              ) : (
                <NoneSpan>Aucun indiqué</NoneSpan>
              )}
            </div>
            <div>
              <StyledSpan>Demande effectuée le : </StyledSpan>{" "}
              {formatStrToDate(sub.createdAt, "dd/MM/yyyy")}
            </div>
          </div>
          <h3>Demande à devenir partenaire</h3>
          <div className="my-4 flex flex-col flex-wrap justify-between">
            <div className="flex flex-row space-x-2">
              <StyledSpan>Status :</StyledSpan>
              {sub.status === "ToHandle" ? (
                <p className="bg-[#777777] text-white rounded-full px-2">
                  {getStatusTypeLabel(StatusSubType.TO_HANDLE)}
                </p>
              ) : sub.status === "Approved" ? (
                <p className="bg-[#007700] text-white rounded-full px-2">
                  {getStatusTypeLabel(StatusSubType.APPROVED)}
                </p>
              ) : (
                <p className="bg-[#CC0000] text-white rounded-full px-2">
                  {getStatusTypeLabel(StatusSubType.DISAPPROVED)}
                </p>
              )}
            </div>
            <div className="flex lg:flex-row flex-col text-center justify-center">
              {" "}
              {sub.status === "ToHandle" ? (
                <div className="flex flex-col items-center">
                  <p>Voulez-vous faire de cette société votre partenaire ?</p>
                  <div className="flex flex-row space-x-8">
                    <LoadingButton
                      type="submit"
                      loading={isAddLoading}
                      disabled={isAddLoading}
                      onClick={approvedSub}
                      className="text-green-800 border-2 rounded px-2 py-1 border-green-800"
                    >
                      Accepter
                    </LoadingButton>
                    <LoadingButton
                      type="submit"
                      loading={isRemoveLoading}
                      disabled={isRemoveLoading}
                      onClick={disapprovedSub}
                      className="text-red-600 border-2 rounded px-2 py-1 border-red-600"
                    >
                      Refuser
                    </LoadingButton>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <NoneSpan>Société déjà traitée.</NoneSpan>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  } else if (isError) {
    content = <InternError />
  }

  return (
    <StyledDiv className="container">
      <GlobalBgStyle />
      <Breadcrumbs>
        <Link to="/admin/inscriptions" style={{ color: colors.darkBlue }}>
          Inscriptions
        </Link>
        <Typography color="text.primary">Fiche détaillée</Typography>
      </Breadcrumbs>
      <h1>Fiche détaillée</h1>
      {content}
    </StyledDiv>
  )
}

export default SubsDetail
