import PartnerRequest from "../models/partnerRequest"
import PartnerRequestPagination from "../models/partnerRequestPagination"
import { apiSlice, TAG_PARTNER_REQUEST } from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPartnerRequestInfos: builder.query<PartnerRequest, number>({
      query: (id) => ({
        url: `/admin/partner-request/${id}`,
      }),
      providesTags: (result) => [
        { type: TAG_PARTNER_REQUEST, id: result?.id },
        { type: TAG_PARTNER_REQUEST, id: "LIST" },
      ],
    }),
    deletePartnerRequest: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `/admin/partner-request/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TAG_PARTNER_REQUEST, id: "LIST" },
      ],
    }),
    getPartnerRequestsWithPagination: builder.query<
      PartnerRequestPagination,
      {
        limit: number
        page: number
        orderBy: string
        direction: string
        from: string
        to: string
      }
    >({
      query: (param) => {
        return {
          url: `/admin/partner-request`,
          params: param,
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: `${TAG_PARTNER_REQUEST}` as const,
                id,
              })),
              { type: TAG_PARTNER_REQUEST, id: "LIST" },
            ]
          : [{ type: TAG_PARTNER_REQUEST, id: "LIST" }],
    }),
    traitedPartnerRequest: builder.mutation<PartnerRequest, number>({
      query: (id) => ({
        url: `admin/traited-request/partner/${id}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: TAG_PARTNER_REQUEST, id: "LIST" }],
    }),
    pendingPartnerRequest: builder.mutation<PartnerRequest, number>({
      query: (id) => ({
        url: `admin/pending-request/partner/${id}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: TAG_PARTNER_REQUEST, id: "LIST" }],
    }),
  }),
})

export const {
  useGetPartnerRequestsWithPaginationQuery,
  useGetPartnerRequestInfosQuery,
  useDeletePartnerRequestMutation,
  useTraitedPartnerRequestMutation,
  usePendingPartnerRequestMutation,
} = extendedApiSlice
