import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/gallerie/IMG_0316.jpg"
import styled from "@emotion/styled"
import logo from "../../assets/logo.png"
import user from "../../assets/user.png"
import question from "../../assets/questions.png"
import euro from "../../assets/euro.png"
import confiance from "../../assets/confiance.png"
import interactions from "../../assets/interactions.png"
import reponses from "../../assets/reponses.png"

const StyledDiv = styled.div`
  p {
    margin: 0;
    padding: 0;
  }
`

function Presentation() {
  return (
    <StyledDiv>
      <WaveHeader title="Immotech" image={bgImage} />

      <div className="flex flex-col items-center w-full mt-10 lg:text-base text-sm">
        <div className="w-11/12 lg:space-y-20 space-y-10">
          {/* Présentation */}
          <div className="space-y-6">
            <div className="flex flex-col lg:space-y-4 space-y-2">
              <p className="text-[#006297] font-black text-sm">IMMOTECH</p>

              <div className="flex flex-col space-y-1">
                <p className="text-[#181E4B] lg:text-5xl text-xl font-bold">
                  Présentation
                </p>
                <div className="bg-gradient-to-r from-[#006297] to-[#6CB7E2] h-1 lg:w-60 w-32 rounded"></div>
              </div>
            </div>

            <div className="w-full flex lg:flex-row flex-col items-center space-y-10 lg:space-y-0">
              <div className="lg:w-3/5 w-full">
                <p>
                  Notre cabinet, spécialisé dans les diagnostics immobiliers de
                  tous types, est installé depuis sa création au 1er Octobre
                  2021, à Rubécourt et Lamécourt (commune de Bazeilles 08140).
                  <br></br> Notre équipe est composée de 3 techniciens, tous
                  certifiés dans leurs domaines de compétences et d’une
                  assistante administrative. Ensemble, nous cumulons plus de 20
                  ans d’expérience dans le domaine des diagnostics immobiliers.
                  <br></br> Nous sommes en mesure d’assister tous clients,
                  particuliers comme professionnels dans leurs transactions
                  immobilières, dans le suivi règlementaire de leurs locaux,
                  mais également dans leurs projets de travaux, de rénovation et
                  de démolitions.<br></br> Nous assurons également nos
                  prestations auprès des communes, collectivités locales et
                  associations.{" "}
                </p>
              </div>

              <div className="lg:w-2/5 w-full flex justify-center relative">
                <img
                  src={logo}
                  alt="Logo Immotech"
                  className="w-1/2 rotate-[9deg]"
                />
                <div className="absolute bg-[#006297] w-1/2 h-full -z-10"></div>
              </div>
            </div>
          </div>

          {/* Equipe */}
          <div className="flex flex-col items-center space-y-10">
            <div className="flex flex-col items-center lg:space-y-4 space-y-2">
              <p className="text-[#006297] font-black text-sm">IMMOTECH</p>

              <div className="flex flex-col items-center space-y-1">
                <p className="text-[#181E4B] lg:text-5xl text-xl font-bold">
                  Notre équipe
                </p>
                <div className="bg-gradient-to-r from-[#006297] to-[#6CB7E2] h-1 lg:w-60 w-32 rounded"></div>
              </div>
            </div>

            <div className="grid lg:grid-cols-4 grid-cols-1 lg:w-full w-5/6 lg:gap-16 gap-6">
              <div className="grid grid-rows-2 border-4 border-[#006297] rounded p-2 gap-y-4 bg-[#FCFCFC]">
                <div className="flex flex-col items-center space-y-4">
                  <img src={user} alt="" className="lg:w-1/4 w-1/5" />
                  <p className="text-center">
                    <span className="font-bold text-[#006297]">Anne</span> :
                    Assistante administrative
                  </p>
                </div>

                <div className="w-full flex flex-col items-start h-full">
                  <p className="flex flex-row items-center">
                    <div className="bg-[#006297] w-2 h-2 rounded-full mr-2"></div>
                    Formation AIPR
                  </p>
                </div>
              </div>

              <div className="grid grid-rows-2 border-4 border-[#006297] rounded p-2 gap-y-4 bg-[#FCFCFC]">
                <div className="flex flex-col items-center space-y-4">
                  <img src={user} alt="" className="lg:w-1/4 w-1/5" />
                  <p className="text-center">
                    <span className="font-bold text-[#006297]">Stéphane</span> :
                    Technicien tous types de diagnostics
                  </p>
                </div>

                <div className="w-full flex flex-col items-start">
                  <p className="flex flex-row items-center">
                    <div className="bg-[#006297] w-2 h-2 rounded-full mr-2"></div>
                    Mention DPE
                  </p>
                  <p className="flex flex-row items-center">
                    <div className="bg-[#006297] w-2 h-2 rounded-full mr-2"></div>
                    Mention amiante
                  </p>
                  <p className="flex flex-row items-center">
                    <div className="bg-[#006297] w-2 h-2 rounded-full mr-2"></div>
                    CACES Nacelle
                  </p>
                  <p className="flex flex-row items-center">
                    <div className="bg-[#006297] w-2 h-2 rounded-full mr-2"></div>
                    Formation amiante SS4 opérateur
                  </p>
                </div>
              </div>

              <div className="grid grid-rows-2 border-4 border-[#006297] rounded p-2 gap-y-4 bg-[#FCFCFC]">
                <div className="flex flex-col items-center space-y-4">
                  <img src={user} alt="" className="lg:w-1/4 w-1/5" />
                  <p className="text-center">
                    <span className="font-bold text-[#006297]">Sylvain</span> :
                    DTG
                  </p>
                </div>

                <div className="w-full flex flex-col items-start">
                  <p className="flex flex-row items-center">
                    <div className="bg-[#006297] w-2 h-2 rounded-full mr-2"></div>
                    Formation AIPR
                  </p>
                  <p className="flex flex-row items-center">
                    <div className="bg-[#006297] w-2 h-2 rounded-full mr-2"></div>
                    Mention amiante
                  </p>
                  <p className="flex flex-row items-center">
                    <div className="bg-[#006297] w-2 h-2 rounded-full mr-2"></div>
                    Diagnostics déchets
                  </p>
                  <p className="flex flex-row items-center">
                    <div className="bg-[#006297] w-2 h-2 rounded-full mr-2"></div>
                    Radioprotection (PCR)
                  </p>
                </div>
              </div>

              <div className="grid grid-rows-2 border-4 border-[#006297] rounded p-2 gap-y-4 bg-[#FCFCFC]">
                <div className="flex flex-col items-center space-y-4">
                  <img src={user} alt="" className="lg:w-1/4 w-1/5" />
                  <p className="text-center">
                    <span className="font-bold text-[#006297]">Basile</span> :
                    Gérant / Technicien tous types de diagnostics
                  </p>
                </div>

                <div className="w-full flex flex-col items-start">
                  <p className="flex flex-row items-center">
                    <div className="bg-[#006297] w-2 h-2 rounded-full mr-2"></div>
                    Formation AIPR
                  </p>
                  <p className="flex flex-row items-center">
                    <div className="bg-[#006297] w-2 h-2 rounded-full mr-2"></div>
                    Mention amiante
                  </p>
                  <p className="flex flex-row items-center">
                    <div className="bg-[#006297] w-2 h-2 rounded-full mr-2"></div>
                    Formation amiante SS3
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Slogan */}
        <div className="bg-[#006297] w-full mt-10 text-white text-4xl flex justify-center p-6 text-center">
          <p>Cliquez, renseignez, vendez!! </p>
        </div>
      </div>
    </StyledDiv>
  )
}

export default Presentation
