import styled from "@emotion/styled"
import {
  Avatar,
  Button,
  Divider,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  TablePagination,
  Typography,
  useTheme,
  Zoom,
} from "@mui/material"
import React, { Fragment, useState } from "react"
import colors from "../../../style/colors"
import AddIcon from "@mui/icons-material/Add"
import { css } from "@emotion/css"
import ArticleModal from "../../../components/ArticleModal"
import GlobalBgStyle from "../../../style/GlobalBgStyle"
import Article from "../../../models/article"
import InternError from "../../../components/InternError"
import { cutText, formatStrToDate } from "../../../utils/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons"
import { useGetArticlesWithPaginationQuery } from "../../../features/articleSlice"

const StyledDiv = styled.div`
  h1 {
    font-size: 1.4rem;
    color: ${colors.blue};
    text-align: center;
    margin-bottom: 2rem;
  }

  @media all and (min-width: 768px) {
    h1 {
      font-size: 1.7rem;
    }
  }
`

export function AlignItemsList({
  items,
  loading,
  direction,
  handleFilter,
  onItemClick,
}: {
  items: Article[]
  loading: boolean
  direction: string
  handleFilter: () => void
  onItemClick: (id: number) => void
}) {
  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 700,
        bgcolor: "rgba(255,255,255,0.9)",
      }}
    >
      <div className="flex justify-end flex-wrap gap-1 items-baseline py-1 pe-3">
        <span>Trier par : </span>
        <Button
          color="info"
          endIcon={
            <FontAwesomeIcon
              icon={direction === "DESC" ? faSortDown : faSortUp}
            />
          }
          aria-label="tri par date"
          size="small"
          onClick={handleFilter}
        >
          Date
        </Button>
      </div>
      <Divider />
      {items.map((item: Article) => (
        <Fragment key={item.id}>
          <ListItem
            onClick={() => onItemClick(item.id)}
            alignItems="flex-start"
            sx={{
              paddingBottom: 2,
              flexWrap: { xs: "wrap", sm: "nowrap" },
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <ListItemAvatar
              sx={{
                width: { xs: "100%", sm: "auto" },
              }}
            >
              {loading ? (
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={50}
                  height={50}
                />
              ) : (
                <Avatar
                  variant="rounded"
                  sx={{
                    width: { sm: 170, xs: "100%" },
                    height: { sm: 100, xs: 150 },
                    marginRight: { sm: 2, xs: 0 },
                  }}
                  alt={item.thumbnail?.alt}
                  src={`${process.env.REACT_APP_API_ARTICLE_URL}/${item.thumbnail?.name}`}
                />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                loading ? (
                  <Skeleton animation="wave" />
                ) : (
                  <span className="italic">
                    Mise en ligne le{" "}
                    {formatStrToDate(item.createdAt, "dd/MM/yyyy")}
                  </span>
                )
              }
              secondary={
                loading ? (
                  <Skeleton animation="wave" />
                ) : (
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline", fontWeight: "bold" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {item.title}
                    </Typography>
                    <span className="whitespace-pre-line">
                      {` - ${cutText(
                        JSON.parse(item.descriptionDecoded),
                        100
                      )}`}
                    </span>
                  </React.Fragment>
                )
              }
            />
          </ListItem>
          <Divider variant="middle" component="li" />
        </Fragment>
      ))}
    </List>
  )
}

function News() {
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [showDialog, setShowDialog] = React.useState(false)
  const [articleId, setArticleId] = React.useState<number | undefined>()
  const [order, setOrder] = useState({
    orderBy: "createdAt",
    direction: "DESC",
  })
  const toggleOrderDirection = () => {
    if (order.direction === "DESC") {
      setOrder((prev: any) => ({
        ...prev,
        direction: "ASC",
      }))
    } else {
      setOrder((prev: any) => ({
        ...prev,
        direction: "DESC",
      }))
    }
  }
  const {
    data: articlesPaginate = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetArticlesWithPaginationQuery({
    limit: rowsPerPage,
    page: page,
    orderBy: order.orderBy,
    direction: order.direction,
  })

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const handleShow = () => {
    setArticleId(undefined)
    setShowDialog(true)
  }

  const handleItemClick = (id: number) => {
    setArticleId(id)
    setShowDialog(true)
  }
  const handleClose = () => setShowDialog(false)
  const theme = useTheme()

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  }

  let content = isSuccess ? (
    <>
      <div className="flex justify-center">
        <AlignItemsList
          items={articlesPaginate.items}
          loading={isLoading}
          direction={order.direction}
          handleFilter={toggleOrderDirection}
          onItemClick={(id: number) => handleItemClick(id)}
        />
      </div>
      <TablePagination
        component="div"
        count={articlesPaginate.totalItems}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        sx={{
          flexWrap: { xs: "wrap" },
          justifyContent: "center",
          pt: 4,
        }}
        className={css`
          .MuiTablePagination-toolbar {
            @media (max-width: 600px) {
              flex-wrap: wrap;
              justify-content: center;
            }
            align-items: baseline;
          }
        `}
      />
    </>
  ) : isError ? (
    <InternError />
  ) : (
    ""
  )

  return (
    <StyledDiv className="container">
      <GlobalBgStyle />
      <h1>Actualités</h1>
      {content}
      {showDialog && (
        <ArticleModal
          articleId={articleId}
          show={showDialog}
          handleClose={handleClose}
          isUpdate={articleId !== undefined}
        />
      )}
      <Zoom
        in
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exit}ms`,
        }}
        unmountOnExit
      >
        <Fab
          color="primary"
          aria-label="add"
          onClick={handleShow}
          sx={{
            position: "fixed",
            bottom: { sm: 20, xs: 10 },
            right: { sm: 20, xs: 10 },
            border: `solid 1px ${colors.blue}`,
            bgcolor: "rgba(255,255,255,0.8)",
            color: colors.blue,
            "&:hover": {
              bgcolor: colors.blue,
              color: "white",
              border: `solid 1px white`,
            },
          }}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </StyledDiv>
  )
}

export default News
