import styled from "@emotion/styled"
import InternError from "../../../components/InternError"
import Loader from "../../../components/Loader"
import {
  useGetDiagnosticsQuery,
  useGetPricesQuery,
} from "../../../features/diagnosticSlice"
import Diagnostic from "../../../models/Diagnostic"
import colors from "../../../style/colors"
import GlobalBgStyle from "../../../style/GlobalBgStyle"
import DataTable from "react-data-table-component"
import Pack from "../../../models/pack"
import { formatPrice } from "../../../utils/utils"

const StyledMain = styled.div`
  h1 {
    font-size: 1.4rem;
    color: ${colors.blue};
    text-align: center;
  }
  h2 {
    font-size: 1.3rem;
    font-weight: bolder;
    color: ${colors.blue};
    text-decoration: underline;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: bolder;
    color: ${colors.darkBlue};
  }

  @media all and (min-width: 768px) {
    h1 {
      font-size: 1.7rem;
    }
  }
`

function DiagnosticsAdmin() {
  const {
    data: diagnostics = [],
    isLoading,
    isSuccess,
    isError,
  }: {
    data: Diagnostic[]
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
  } = useGetDiagnosticsQuery()

  const columnsDiag: any = [
    {
      name: "Nom",
      selector: (row: Diagnostic) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Validité location",
      minWidth: "9rem",
      selector: (row: Diagnostic) => row.rentValidity?.value,
      sortable: true,
      wrap: true,
    },
    {
      name: "Validité vente",
      minWidth: "8rem",
      selector: (row: Diagnostic) => row.sellValidity?.value,
      sortable: true,
      wrap: true,
    },
  ]

  let content

  if (isError) {
    content = <InternError />
  } else if (isSuccess) {
    content = (
      <DataTable
        columns={columnsDiag}
        data={diagnostics}
        striped
        dense
        highlightOnHover
        fixedHeader
      />
    )
  } else if (isLoading) {
    content = <Loader />
  }

  const {
    data: prices = [],
    isLoading: isPriceLoading,
    isSuccess: isPriceSuccess,
    isError: isPriceError,
  }: {
    data: Pack[]
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
  } = useGetPricesQuery()

  const columnsPrice: any = [
    {
      name: "",
      selector: (row: Pack) => row.nbDiag,
      cell: (row: Pack) =>
        `Pack ${row.nbDiag} diagnostic${row.nbDiag > 1 ? "s" : ""}`,
      wrap: true,
    },
    {
      name: "T1",
      selector: (row: Pack) => row.priceT1,
      format: (row: Pack) => formatPrice(row.priceT1),
      wrap: true,
    },
    {
      name: "T2",
      selector: (row: Pack) => row.priceT2,
      format: (row: Pack) => formatPrice(row.priceT2),
      wrap: true,
    },
    {
      name: "T3",
      selector: (row: Pack) => row.priceT3,
      format: (row: Pack) => formatPrice(row.priceT3),
      wrap: true,
    },
    {
      name: "T4",
      selector: (row: Pack) => row.priceT4,
      format: (row: Pack) => formatPrice(row.priceT4),
      wrap: true,
    },
    {
      name: "T5",
      selector: (row: Pack) => row.priceT5,
      format: (row: Pack) => formatPrice(row.priceT5),
      wrap: true,
    },
    {
      name: "T6",
      selector: (row: Pack) => row.priceT6,
      format: (row: Pack) => formatPrice(row.priceT6),
      wrap: true,
    },
  ]

  let contentPrice

  if (isPriceError) {
    contentPrice = <InternError />
  } else if (isPriceSuccess) {
    contentPrice = (
      <>
        <h3>Amiante, Plomb, DPE, Gaz, Electricité, Superficie :</h3>
        <DataTable
          columns={columnsPrice}
          data={prices}
          striped
          dense
          highlightOnHover
          fixedHeader
        />
      </>
    )
  } else if (isPriceLoading) {
    contentPrice = <Loader />
  }

  return (
    <StyledMain className="container-fluid px-2">
      <GlobalBgStyle />
      <h1>Diagnostics</h1>
      <h2>Informations</h2>
      <div className="flex justify-center my-4 lg:px-8">{content}</div>
      <h2>Tarifs</h2>
      <div className="flex flex-col justify-center my-4 lg:px-8">
        {contentPrice}
        <div className="flex flex-wrap gap-2 mt-3">
          <h3>ERP :</h3>
          <p>offert</p>
        </div>
        <div className="flex flex-wrap gap-2 mb-3">
          <h3>Assainissement :</h3>
          <p>? €</p>
        </div>
      </div>
    </StyledMain>
  )
}

export default DiagnosticsAdmin
