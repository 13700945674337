import { faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function InternError() {
  return (
    <div className="text-center bg-white p-4">
      <FontAwesomeIcon color="#dc3545" icon={faCircleXmark}></FontAwesomeIcon>
      <div className="p-5">Une erreur s'est produite, veuillez réessayer</div>
    </div>
  )
}

export default InternError
