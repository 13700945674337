import immotech from "../../assets/home/immo.png"
import logoimmo from "../../assets/logo-cut.png"
import immotech5 from "../../assets/home/immo5.png"
import couple from "../../assets/home/couple.png"
import tached from "../../assets/home/tache_droite.svg"
import note from "../../assets/home/note.svg"
import travaux from "../../assets/home/travaux.svg"
import check from "../../assets/home/verifier.png"
import tracé from "../../assets/home/trace.png"
import question from "../../assets/home/question.png"
import tacheg from "../../assets/home/tache_gauche.svg"
import ensemble from "../../assets/home/ensemble.png"
import tpreseaux from "../../assets/home/logo/TPReseaux.png"
import amc from "../../assets/home/logo/amc.png"
import cab from "../../assets/home/logo/cab.png"
import ymc from "../../assets/home/logo/ymc.png"
import escm from "../../assets/home/logo/ESCM.png"
import cssa from "../../assets/home/logo/cssa.png"
import bazeilles from "../../assets/home/logo/bazeilles.png"
import immo from "../../assets/home/logo/logoimmo.png"
import batiment from "../../assets/gallerie/IMG_0316.jpg"
import conduit from "../../assets/gallerie/conduit de ventilation amainte ciment.jpg"
import traces from "../../assets/gallerie/traces d'infestation insectes.jpg"
import plaques from "../../assets/gallerie/couverture plaques ondulées amainte ciment.jpg"
import compteur from "../../assets/gallerie/IMG_1611.jpg"
import ampoule from "../../assets/gallerie/IMG_1612.jpg"
import escalier from "../../assets/gallerie/IMG_0949.jpg"
import agricole from "../../assets/gallerie/IMG_0453.jpg"
import styled from "@emotion/styled"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import fondis from "../../assets/partenaire/fondis.png"
import atlibitum from "../../assets/partenaire/atlibitum.png"
import preventimmo from "../../assets/partenaire/preventimmo.png"
import afil from "../../assets/partenaire/afil.jpg"
import eurofins from "../../assets/partenaire/eurofins.jpg"

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  }
}

const SliderAuto = [
  {
    id: 1,
    immo: "IMMOTECH",
    titre: "Loi climat et résilience 2021",
    description:
      "Issue des travaux de la Convention citoyenne pour le climat, la loi portant lutte contre le dérèglement climatique et le renforcement de la résilience face à ses effets a été promulguée et publiée au Journal officiel le 24 août 2021.",
    lien: "En savoir plus",
  },
  {
    id: 2,
    immo: "IMMOTECH",
    titre: "Biens en vente",
    description:
      "Les logements disponibles à la vente, considérés comme passoires énergétiques sont actuellement les biens évalués en étiquette F & G. Ces logements sont ou vont être soumis à des changements de règlementation.",
    lien: "En savoir plus",
  },
  {
    id: 3,
    immo: "IMMOTECH",
    titre: "Biens en location",
    description:
      "Depuis le 24 Aout 2022, les propriétaires de passoires thermiques seront obligés de réaliser des travaux de rénovation énergétique s’ils souhaitent augmenter le loyer de leur logement en location. Il s’agit d’un premier signal important avant l’entrée en vigueur des interdictions de mise en location des logements les plus consommateurs d’énergie.",
    lien: "En savoir plus",
  },
]

const StyledText = styled.div`
  p {
    margin: 0;
  }

  .slick-arrow::before {
    color: #006297 !important;
    font-size: 40px !important;
  }

  .slick-prev {
    left: -55px !important;
  }

  .slick-next {
    right: -35px !important;
  }

  .slick-dots {
    bottom: -35px !important;
  }

  .slick-dots li button::before {
    color: #006297 !important;
  }

  .slick-slide {
    height: 100%;
  }

  .marg {
    margin-top: -10vh;
  }

  .margin {
    margin-top: 0;
  }

  .active {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    z-index: 10;
    top: 100%;
    left: 0;
    background-color: #232856;
    width: 100%;
    height: 150px;
    margin: 0;
  }

  @media screen and (max-width: 1250px) {
    .marg {
      margin-top: -3vh;
    }
  }

  @media screen and (max-width: 1024px) {
    .slick-arrow::before {
      color: #006297 !important;
      font-size: 20px !important;
    }

    .slick-prev {
      left: -35px !important;
    }

    .marg {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 700px) {
    .marg {
      margin-top: 5vh;
    }
  }
`

const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const ContainerCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

function Home() {
  const settingsAuto = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplayspeed: 2000,
    arrows: false,
  }

  const settingsArrows = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
        },
      },
    ],
  }
  return (
    <StyledText className="overflow-x-hidden">
      {/* Back To Top */}
      {/* Header + navbar */}
      <ContainerRow>
        <div className="w-1/2 mt-5">
          <Slider {...settingsAuto} className="margin">
            {SliderAuto.map((item, index) => (
              <div key={index}>
                <div className="flex justify-center">
                  <div className="flex flex-col lg:space-y-8 space-y-4 py-2 lg:w-4/5 w-11/12">
                    <div className="flex flex-col lg:space-y-4 space-y-2">
                      <p className="text-[#006297] font-black text-sm">
                        {item.immo}
                      </p>
                      <div className="flex flex-col space-y-1">
                        <p className="text-[#181E4B] lg:text-5xl text-xl font-bold">
                          {item.titre}
                        </p>
                        <div className="bg-gradient-to-r from-[#006297] to-[#6CB7E2] h-1 lg:w-60 w-32 rounded"></div>
                      </div>
                    </div>

                    <p className="lg:text-base text-sm">{item.description}</p>

                    <div>
                      <a
                        href="https://www.ecologie.gouv.fr/loi-climat-resilience"
                        target="_blank"
                        className="bg-[#006297] no-underline text-white rounded py-1 px-2 lg:text-base text-sm"
                        rel="noreferrer"
                      >
                        {item.lien}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div className="w-1/2">
          <img
            src={immotech}
            alt="Ensemble Immotech"
            className="ml-[5vw] lg:-mt-[30vh] -mt-[20vh] hidden lg:block"
          />
          <img
            src={immotech5}
            alt="Ensemble Immotech"
            className="ml-[3vw] -mt-[13vh] lg:hidden"
          />
        </div>
      </ContainerRow>

      {/* Le Cabinet */}
      <ContainerRow className="marg">
        <div className="w-1/2 -ml-[7vw]">
          <img src={couple} alt="couple visite appart" />
        </div>

        <div className="flex flex-col lg:px-5 pr-2 w-1/2 lg:ml-20 ml-5 lg:space-y-8 space-y-4 relative">
          <div className="flex flex-col lg:space-y-4 space-y-2">
            <p className="text-[#006297] font-black text-sm">IMMOTECH</p>
            <div className="flex flex-col space-y-1">
              <p className="text-[#181E4B] lg:text-5xl text-xl font-bold">
                Notre cabinet
              </p>
              <div className="bg-gradient-to-r from-[#006297] to-[#6CB7E2] h-1 lg:w-60 w-32 rounded"></div>
            </div>
          </div>

          <p className="text-[#5E6282] lg:text-base text-sm">
            Installé depuis le 1er Octobre 2021 à Rubécourt-et-Lamécourt,
            Immotech est un cabinet spécialisé dans les diagnostics immobiliers
            de tous types.
          </p>

          <div>
            <a
              href="/presentation"
              className="bg-[#006297] no-underline text-white rounded py-1 px-2 lg:text-base text-sm"
            >
              En savoir plus
            </a>
          </div>

          <img
            src={tached}
            alt="Tache coté droit"
            className="absolute top-8 ml-[25vw] -z-10 lg:flex hidden"
          />
        </div>
      </ContainerRow>

      {/* Les Services */}
      <ContainerCol className="lg:space-y-20 space-y-8 mt-5">
        <div className="flex flex-col items-center lg:space-y-4 space-y-2">
          <p className="text-[#006297] font-black text-sm">IMMOTECH</p>
          <div className="flex flex-col items-center space-y-1">
            <p className="text-[#181E4B] lg:text-5xl text-xl font-bold">
              Nos services
            </p>
            <div className="bg-gradient-to-r from-[#006297] to-[#6CB7E2] h-1 lg:w-60 w-32 rounded"></div>
          </div>
        </div>

        <div className="flex lg:flex-row flex-col items-center justify-center lg:space-y-0 space-y-4 w-full">
          <div className="flex flex-col items-center lg:w-1/2 w-11/12 text-sm text-[#5E6282]">
            <div className="flex flex-col lg:w-2/3 w-11/12 items-start lg:space-y-8 space-y-4">
              <div className="flex flex-col items-center w-full lg:space-y-4 space-y-2">
                <div className="flex flex-row items-center w-full lg:space-x-8 space-x-4">
                  <img src={note} alt="Tablette de note" className="w-1/12" />
                  <p className="text-[#181E4B] lg:text-2xl font-bold">
                    Transaction / Location
                  </p>
                </div>

                <div className="lg:text-base text-sm space-y-1">
                  <p className="flex flex-row items-center">
                    <img src={check} alt="Check" className="w-[10%]" />
                    DPE (tous types de biens)
                  </p>
                  <p className="flex flex-row items-center">
                    <img src={check} alt="Check" className="w-[10%]" />
                    Contrôle des installations gaz
                  </p>
                  <p className="flex flex-row items-center">
                    <img src={check} alt="Check" className="w-[10%]" />
                    Contrôle des installations électriques
                  </p>
                  <p className="flex flex-row items-center">
                    <img src={check} alt="Check" className="w-[10%]" />
                    Et d’autres…
                  </p>
                </div>
              </div>

              <div className="flex flex-col items-center w-full lg:space-y-4 space-y-2">
                <div className="flex flex-row items-center w-full lg:space-x-8 space-x-4">
                  <img
                    src={travaux}
                    alt="Tablette de note"
                    className="w-1/12"
                  />
                  <p className="text-[#181E4B] lg:text-2xl font-bold">
                    Avant travaux / Démolition
                  </p>
                </div>

                <div className="lg:text-base text-sm space-y-1">
                  <p className="flex flex-row items-center">
                    <img src={check} alt="Check" className="w-[10%]" />
                    Repérage amiante
                  </p>
                  <p className="flex flex-row items-center">
                    <img src={check} alt="Check" className="w-[10%]" />
                    Constat de risque d’exposition au plomb
                  </p>
                  <p className="flex flex-row items-center">
                    <img src={check} alt="Check" className="w-[10%]" />
                    Et d’autres…
                  </p>
                </div>
              </div>

              <div className="flex justify-center w-full">
                <a
                  href="/services"
                  className="bg-[#006297] no-underline text-white lg:text-base rounded py-1 px-2 text-sm"
                >
                  En savoir plus
                </a>
              </div>
            </div>
          </div>

          <div className="lg:w-1/2 w-11/12 flex justify-center">
            <ImageList variant="quilted" cols={4} rowHeight={121}>
              {itemData.map((item) => (
                <ImageListItem
                  key={item.img}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                >
                  <img
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </div>
      </ContainerCol>

      {/* Redirection questionnaire */}
      <ContainerCol className="mt-5">
        <img src={tracé} alt="Tracé ondulé" />

        <div className="flex lg:flex-row flex-col items-center bg-[#006297] w-full text-white py-5 relative lg:space-y-0 space-y-8">
          <div className="lg:w-[48%] w-full flex justify-center">
            <div className="bg-[#212121] lg:w-1/2 w-3/4 flex flex-col items-center rounded-xl py-2 space-y-4">
              <div className="w-1/3 h-1 bg-slate-600 rounded"></div>
              {/* <video className="w-11/12">
                    <source />
                  </video> */}
              <div className="w-11/12 md:h-[90vh] lg:h-[70vh] h-[50vh] bg-slate-50 z-10 flex flex-col items-center justify-center space-y-8">
                <img
                  src={logoimmo}
                  alt="Logo Immotech"
                  className="w-2/3 md:w-1/2 lg:w-2/3 rounded-xl"
                />
                <p className="text-black text-xl">
                  Vidéo en cours de création...
                </p>
              </div>
              <div className="w-8 h-8 bg-slate-600 rounded-full"></div>
            </div>
          </div>

          <div className="lg:w-[52%] w-11/12 z-10">
            <div className="lg:w-5/6 pr-2 lg:space-y-8 space-y-4">
              <div className="lg:text-3xl text-base font-bold space-y-1">
                <p>
                  Vous vendez ou louez un bien immobilier et vous ne savez pas
                  quels diagnostics réglementaires vous devez faire ?
                </p>
                <div className="bg-white h-1 lg:w-60 w-32 rounded"></div>
              </div>

              <p className="lg:text-lg text-sm">
                Utilisez notre outil en ligne gratuitement !<br></br> Après
                avoir répondu à un questionnaire il vous donne la liste des
                diagnostics que vous avez à faire en fonction de votre bien.
              </p>

              <div className="w-full">
                <a
                  href="/diagnostics"
                  className="bg-white no-underline text-[#006297] rounded py-1 px-3 font-semibold lg:text-base text-sm"
                >
                  Répondre au questionnaire
                </a>
              </div>
            </div>
          </div>

          <img
            src={question}
            alt="Homme avec question"
            className="absolute bottom-0 right-0 w-1/5 lg:flex hidden"
          />
        </div>
      </ContainerCol>

      {/* Fournisseurs */}
      <div className="flex justify-around w-full mt-12">
        <div className="flex flex-col lg:w-[88%] w-[95%] lg:space-y-8 space-y-2">
          <div className="flex flex-col lg:space-y-4 space-y-2">
            <p className="text-[#006297] font-black text-sm">IMMOTECH</p>
            <div className="flex flex-col space-y-1">
              <p className="text-[#181E4B] lg:text-5xl text-xl font-bold">
                Nos fournisseurs
              </p>
              <div className="bg-gradient-to-r from-[#006297] to-[#6CB7E2] h-1 lg:w-60 w-32 rounded"></div>
            </div>
          </div>

          <div className="flex lg:flex-row flex-col w-full lg:space-y-0 space-y-8">
            <div className="lg:w-2/5 w-full relative">
              <p className="w-11/12 text-[#5E6282] lg:text-base text-sm pt-3">
                Afin de garantir une prestation de qualité à nos clients, nous
                avons soigneusement sélectionné nos partenaires, qu’ils soient
                fournisseurs de matériels ou prestataires de services.
              </p>
              <img
                src={tacheg}
                alt="Tache à gauche"
                className="absolute -ml-[20vw] -top-16 -z-10 lg:flex hidden"
              />
            </div>

            <div className="lg:w-3/5 w-full flex justify-center relative">
              <Slider
                {...settingsArrows}
                className="lg:w-3/4 w-full border-8 rounded border-[#006297] bg-white"
              >
                {/* Slider 1 */}
                <div>
                  <div className="flex justify-center">
                    <div className="flex flex-col items-center  justify-center lg:h-[40vh] h-[55vh] lg:space-y-4 space-y-2 py-3 w-11/12 text-[#5E6282]">
                      <div className="flex justify-center w-3/5">
                        <img src={tpreseaux} alt="entreprise" />
                      </div>

                      <p className="text-sm">
                        Société spécialisée dans les domaines de la voirie et
                        des réseaux enterrés. Fondé en 2021, TP Réseaux est
                        certifiée en détection par Bureau Veritas pour les
                        activités suivantes :
                      </p>

                      <div className="flex flex-col text-sm space-y-4">
                        <div className="flex flex-col">
                          <div className="flex lg:flex-row flex-col flex-wrap justify-between">
                            <p className="flex flex-row items-center lg:w-1/2 w-full">
                              <img
                                src={check}
                                alt="check1"
                                className="w-1/12"
                              />
                              Diagnostics assainissement
                            </p>
                            <p className="flex flex-row items-center lg:w-1/2 w-full">
                              <img
                                src={check}
                                alt="check2"
                                className="w-1/12"
                              />
                              Tests d’étanchéité des canalisations
                            </p>
                          </div>

                          <div className="flex lg:flex-row flex-col flex-wrap justify-between">
                            <p className="flex flex-row items-center lg:w-1/2 w-full">
                              <img
                                src={check}
                                alt="check3"
                                className="w-1/12"
                              />
                              Détection de réseaux enterrés
                            </p>
                            <p className="flex flex-row items-center lg:w-1/2 w-full">
                              <img
                                src={check}
                                alt="check4"
                                className="w-1/12"
                              />
                              Hydrocurage
                            </p>
                          </div>
                        </div>

                        <div>
                          <p className="text-sm">Et d'autres encore...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Slider 2 */}
                <div>
                  <div className="flex justify-center">
                    <div className="flex flex-col items-center justify-center lg:h-[40vh] h-[55vh] lg:space-y-4 space-y-2 py-3 w-11/12 text-[#5E6282]">
                      <div className="w-5/6 flex justify-center">
                        <img src={eurofins} alt="Logo eurofins" />
                      </div>

                      <p>
                        Laboratoire d'analyses spécialisé en analyse d’amiante.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Slider 3 */}
                <div>
                  <div className="flex justify-center">
                    <div className="flex flex-col items-center justify-center lg:h-[40vh] h-[55vh] lg:space-y-4 space-y-2 py-3 w-11/12 text-[#5E6282]">
                      <div className="w-1/4">
                        <img src={fondis} alt="Logo fondis" />
                      </div>

                      <p>
                        Distributeur et fabricant d’instruments de mesures
                        scientifiques portables.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Slider 4 */}
                <div className="h-full">
                  <div className="flex justify-center h-full">
                    <div className="flex flex-col items-center justify-center lg:h-[40vh] h-[55vh] lg:space-y-4 space-y-2 py-3 w-11/12 text-[#5E6282]">
                      <div className="w-full flex justify-center">
                        <img src={atlibitum} alt="Logo atlibitum" />
                      </div>

                      <p>
                        Editeur de logiciel pour diagnostiqueurs immobiliers.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Slider 5 */}
                <div>
                  <div className="flex justify-center h-full">
                    <div className="flex flex-col items-center justify-center lg:h-[40vh] h-[55vh] lg:space-y-4 space-y-2 py-3 w-11/12 text-[#5E6282]">
                      <div className="w-full flex justify-center">
                        <img src={preventimmo} alt="Logo preventimmo" />
                      </div>

                      <p>Solution de documentation d’urbanisme en ligne.</p>
                    </div>
                  </div>
                </div>

                {/* Slider 6 */}
                <div>
                  <div className="flex justify-center h-full">
                    <div className="flex flex-col items-center justify-center lg:h-[40vh] h-[55vh] lg:space-y-4 space-y-2 py-3 w-11/12 text-[#5E6282]">
                      <div className="w-full flex justify-center">
                        <img src={afil} alt="Logo afil" />
                      </div>

                      <p>
                        Fournitures industrielles et Equipements de protections
                        individuelles.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>

              <div className="bg-[#006297] rounded absolute lg:w-3/4 w-full h-full rotate-[8deg] -z-10"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Partenaires */}
      <ContainerCol className="lg:space-y-20 space-y-8 mt-20">
        <div className="flex flex-col items-center lg:space-y-4 space-y-2">
          <p className="text-[#006297] font-black text-sm">IMMOTECH</p>
          <div className="flex flex-col items-center space-y-1">
            <p className="text-[#181E4B] lg:text-5xl text-xl font-bold">
              Nos partenaires
            </p>
            <div className="bg-gradient-to-r from-[#006297] to-[#6CB7E2] h-1 lg:w-60 w-32 rounded"></div>
          </div>
        </div>

        <ContainerRow>
          <div className="w-1/2 flex flex-col items-center text-[#5E6282] lg:space-y-8 space-y-4">
            <div className="lg:w-3/4 w-11/12 lg:text-base text-sm">
              <p>
                Notre société souhaite s’inscrire en tant qu’acteur de la vie
                associative et culturelle locale. Ainsi nous accompagnons
                différentes associations et clubs locaux dans leur
                développement.
              </p>
            </div>

            <div className="lg:w-3/4 w-11/12 grid grid-cols-3 grid-rows-2 lg:gap-x-8 lg:gap-y-4 gap-y-2 gap-x-2">
              <div className="flex justify-center items-center">
                <img
                  src={amc}
                  alt="Logo Ardenne Métropole Cyclisme"
                  className="w-4/5"
                />
              </div>

              <div className="flex justify-center items-center">
                <img src={cab} alt="Logo Cabaret Vert" className="w-4/5" />
              </div>

              <div className="flex justify-center items-center">
                <img src={ymc} alt="Logo Ymc" className="w-4/5" />
              </div>

              <div className="flex justify-center items-center">
                <img src={escm} alt="Logo Entente" className="w-4/5" />
              </div>

              <div className="flex justify-center items-center">
                <img src={cssa} alt="Logo Cssa" className="w-4/5" />
              </div>

              <div className="flex justify-center items-center">
                <img src={bazeilles} alt="Logo Bazeilles" className="w-4/5" />
              </div>
            </div>

            <div>
              <p className="lg:text-base text-sm">Et bien d'autres ...</p>
            </div>
          </div>

          <div className="w-1/2 flex justify-center">
            <img src={ensemble} alt="Tous ensemble" className="w-11/12" />
          </div>
        </ContainerRow>
      </ContainerCol>

      {/* Redirection Contact */}
      <ContainerCol className="mt-5">
        <img src={tracé} alt="Tracé ondulé" />

        <div className="flex flex-row bg-[#006297] w-full text-white">
          <div className="lg:w-1/5 w-1/2 flex lg:items-start items-center">
            <img src={immo} alt="Logo Immotech" />
          </div>

          <div className="lg:w-3/5 w-1/2 flex flex-col items-center justify-center text-center lg:space-y-12 space-y-6 lg:py-32 py-20">
            <div className="flex flex-col items-center lg:space-y-4 space-y-2">
              <p className="lg:text-4xl text-lg font-bold">
                Besoin d'un rensignement ?
              </p>
              <p className="lg:text-lg text-sm">
                N'hésitez pas à nous contacter
              </p>
            </div>
            <a
              href="/contact"
              className="no-underline bg-white text-[#006297] rounded py-1 px-3 font-semibold lg:text-base text-sm"
            >
              Contactez-nous
            </a>
          </div>

          <div className="lg:w-1/5 w-1/3 lg:flex items-end hidden">
            <img src={immo} alt="Logo Immotech" />
          </div>
        </div>
      </ContainerCol>
    </StyledText>
  )
}

const itemData = [
  {
    img: batiment,
    title: "Bâtiment",
    rows: 2,
    cols: 2,
  },
  {
    img: conduit,
    title: "conduit",
  },
  {
    img: traces,
    title: "traces",
  },
  {
    img: plaques,
    title: "plaques",
    cols: 2,
  },
  {
    img: compteur,
    title: "compteur",
  },
  {
    img: ampoule,
    title: "ampoule",
  },
  {
    img: escalier,
    title: "escalier",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
  },
  {
    img: agricole,
    title: "agricole",
    cols: 2,
  },
]

export default Home
