import { createSlice } from "@reduxjs/toolkit"
import User from "../../models/user"
import { RootState } from "../../utils/store"
import { apiSlice } from "../api/apiSlice"

type AuthState = {
  userid: string
  token: string
  user?: User
}
const initialState = {
  userid: localStorage.getItem("userid") || "",
  token: localStorage.getItem("token") || "",
  user: undefined,
} as AuthState

const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logout(state) {
      state.user = undefined
      state.token = ""
      state.userid = ""
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
        state.userid = payload.userid
      }
    )
    builder.addMatcher(
      apiSlice.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.user
      }
    )
  },
})

export default slice.reducer

export const selectUser = (state: RootState) => state.auth.user
export const selectUserId = (state: RootState) => state.auth.userid
export const selectToken = (state: RootState) => state.auth.token

export const { logout } = slice.actions
