import styled from "@emotion/styled"
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LoadingButton } from "@mui/lab"
import { Button, Divider } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { toast } from "react-toastify"
import InternError from "../../../components/InternError"
import Loader from "../../../components/Loader"
import ValidateDialog from "../../../components/ValidateDialog"
import {
  useGetUserInfosQuery,
  usePostUserInfosMutation,
} from "../../../features/userSlice"
import Agency from "../../../models/agency"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../../models/responseApiType"
import User from "../../../models/user"
import colors from "../../../style/colors"
import GlobalBgStyle from "../../../style/GlobalBgStyle"

const StyledForm = styled(Form)`
  background-color: white;
  font-size: 1rem;
  box-shadow: 3px 3px 10px lightgray;
  width: 100%;
  margin: 2rem 0.5rem;
  padding: 1rem 1rem;

  @media all and (min-width: 768px) {
    max-width: 40rem;
    padding: 2rem 2rem;
  }
  @media all and (min-width: 1200px) {
    max-width: 42rem;
  }
`

const StyledMain = styled.div`
  h1 {
    font-size: 1.4rem;
    color: ${colors.blue};
    text-align: center;
  }

  @media all and (min-width: 768px) {
    h1 {
      font-size: 1.7rem;
    }
  }
`

const StyledCol = styled(Col)`
  font-weight: bold;
  font-size: 1.1rem;
  color: ${colors.darkBlue};
`
const NoneSpan = styled.span`
  color: grey;
`

function Profile() {
  const [isUpdate, setIsUpdate] = useState(false)
  const [infos, setInfos] = useState<User>()
  const [showConfirm, setShowConfirm] = useState(false)
  const [validated, setValidated] = useState(false)
  const [msgError, setMsgError] = useState<string>()
  const [errors, setErrors] = useState({} as any)

  const {
    data: user,
    isLoading,
    isSuccess,
    isError,
  }: {
    data: User
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
  } = useGetUserInfosQuery()

  const [postUserInfos, { isLoading: isUpdateLoading }] =
    usePostUserInfosMutation()

  const handleInfosChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInfos((prev: any) => ({ ...prev, [name]: value.trim() }))
  }

  const handleValidate = () => {
    setShowConfirm(false)
    postUserInfos(infos)
      .unwrap()
      .then(() => {
        setIsUpdate(false)
        setValidated(false)
        toast.success("Modifications enregistrées")
      })
      .catch((error: ResponseApiException) => {
        if (error.data?.type === ResponseApiType.CustomError) {
          setMsgError(error.data?.detail)
          setErrors(error.data?.detail)
          setValidated(true)
        } else if (error.data?.type === ResponseApiType.FormError) {
          setMsgError("Veuillez vérifier les données")
          setErrors(error.data?.detail)
          setValidated(true)
        } else {
          setMsgError("Une erreur interne s'est produite.")
        }
      })
  }

  const handleSubmit = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
      setMsgError("Veuillez vérifier les données")
    } else {
      setMsgError("")
      setShowConfirm(true)
    }
  }

  useEffect(() => {
    if (!isUpdate) {
      setMsgError("")
      setErrors(null)
      setValidated(false)
    }
    setInfos(user)
  }, [isUpdate, user])

  let content

  if (isError) {
    content = <InternError />
  } else if (isSuccess) {
    content = (
      <StyledForm
        onSubmit={handleSubmit}
        noValidate
        validated={validated}
        className="d-grid gap-1"
      >
        <Row className="justify-content-center">
          <StyledCol sm={6} md={5}>
            Nom :
          </StyledCol>
          <Col sm={6} md={7}>
            {!isUpdate ? (
              user?.lastname
            ) : (
              <Fragment>
                <Form.Control
                  required
                  name="lastname"
                  placeholder="Votre nom"
                  defaultValue={user?.lastname}
                  onChange={handleInfosChange}
                  isInvalid={errors && errors.lastname}
                />
                <Form.Control.Feedback type="invalid">
                  Ce champ est requis
                </Form.Control.Feedback>
              </Fragment>
            )}
          </Col>
        </Row>
        <Divider />
        <Row className="justify-content-center">
          <StyledCol sm={6} md={5}>
            Prénom :
          </StyledCol>
          <Col sm={6} md={7}>
            {!isUpdate ? (
              user?.firstname
            ) : (
              <Fragment>
                <Form.Control
                  name="firstname"
                  required
                  placeholder="Votre prénom"
                  defaultValue={user?.firstname}
                  onChange={handleInfosChange}
                  isInvalid={errors && errors.firstname}
                />
                <Form.Control.Feedback type="invalid">
                  Ce champ est requis
                </Form.Control.Feedback>
              </Fragment>
            )}
          </Col>
        </Row>
        <Divider />
        <Row className="justify-content-center">
          <StyledCol sm={6} md={5}>
            Téléphone :
          </StyledCol>
          <Col sm={6} md={7}>
            {!isUpdate ? (
              user?.phone ?? <NoneSpan>Aucun renseigné</NoneSpan>
            ) : (
              <Fragment>
                <Form.Control
                  name="phone"
                  placeholder="Numéro de téléphone"
                  defaultValue={user?.phone}
                  onChange={handleInfosChange}
                  isInvalid={errors && errors.phone}
                />
              </Fragment>
            )}
          </Col>
        </Row>

        {user.roles?.includes("ROLE_PARTNER") && (
          <>
            <Divider />
            <Row className="justify-content-center">
              <StyledCol sm={6} md={5}>
                Nom société :
              </StyledCol>
              <Col sm={6} md={7}>
                {!isUpdate ? (
                  user?.companyName ?? <NoneSpan>Aucun renseigné</NoneSpan>
                ) : (
                  <Fragment>
                    <Form.Control
                      name="companyName"
                      placeholder="Nom société"
                      defaultValue={user?.companyName}
                      onChange={handleInfosChange}
                      isInvalid={errors && errors.companyName}
                    />
                    <Form.Control.Feedback type="invalid">
                      Ce champ est requis
                    </Form.Control.Feedback>
                  </Fragment>
                )}
              </Col>
            </Row>
          </>
        )}

        <Divider />
        <Row className="justify-content-center">
          <StyledCol sm={6} md={5}>
            Agence :
          </StyledCol>
          <Col sm={6} md={7}>
            {user.agencies?.map((agency: Agency) => (
              <div key={agency.id}>
                {agency?.name}
                <br />
                {agency?.address && (
                  <>
                    {agency?.address.number} {agency?.address.street}
                    <br />
                    {agency?.address.additionalAddress && (
                      <>
                        {agency?.address.additionalAddress}
                        <br />
                      </>
                    )}
                    {agency?.address.postalCode} {agency?.address.city}
                  </>
                )}
              </div>
            ))}
          </Col>
        </Row>

        <Form.Group className="mt-2 text-danger">{msgError}</Form.Group>
        <div className="d-flex flew-wrap justify-content-end gap-1 mt-3">
          {!isUpdate && (
            <Button
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faPencilAlt} />}
              onClick={() => setIsUpdate(true)}
            >
              Modifier
            </Button>
          )}
          {isUpdate && (
            <>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isUpdateLoading}
                disabled={isUpdateLoading}
                color="info"
              >
                Valider
              </LoadingButton>
              <Button onClick={() => setIsUpdate(false)} variant="outlined">
                Annuler
              </Button>
            </>
          )}
        </div>
      </StyledForm>
    )
  } else if (isLoading) {
    content = <Loader />
  }

  return (
    <StyledMain className="container-fluid px-0">
      <GlobalBgStyle />
      <h1>Mon profil</h1>
      <div className="flex justify-center">{content}</div>
      <ValidateDialog
        show={showConfirm}
        handleClose={() => {
          setShowConfirm(false)
        }}
        modalTitle="Modification du profil"
        handleConfirm={handleValidate}
        loading={isUpdateLoading}
      >
        Êtes-vous sûr de vouloir modifier vos informations ?
      </ValidateDialog>
    </StyledMain>
  )
}

export default Profile
