import Diagnostic from "./Diagnostic"
import User from "./user"

export enum StatusType {
  TO_HANDLE = "ToHandle",
  PENDING = "Pending",
  TRAITED = "Traited",
  ARCHIVED = "Archived",
  APPROVED = "Approved",
  DISAPPROVED = "Disapproved",
}

export function getStatusPartDiagOrder() {
  return [StatusType.TO_HANDLE, StatusType.TRAITED, StatusType.PENDING]
}

export function getStatusTypeLabel(status: StatusType | string) {
  switch (status) {
    case StatusType.TO_HANDLE:
      return "À traiter"
    case StatusType.TRAITED:
      return "Traitée"
    case StatusType.PENDING:
      return "En cours de traitement"
    default:
      return status
  }
}

export default interface PartnerRequest {
  id: number
  email?: string
  phone?: string
  postalCode?: string
  city?: string
  status: StatusType
  number: string
  partner?: User
  createdAt?: string
  diagnostics: Diagnostic[]
}
