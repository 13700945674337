import { useLocation } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import { Navigate } from "../Navigate"
import { isGranted, RoleType } from "../utils"

export function AdminRoute({ children }: { children: JSX.Element }) {
  const {
    loggedUser: { user },
  } = useAuth()
  const location = useLocation()

  if (user && isGranted(user.roles, RoleType.RoleAdmin)) {
    return children
  } else {
    return <Navigate to="/admin" replace state={{ from: location }} />
  }
}

export function PartnerRoute({ children }: { children: JSX.Element }) {
  const {
    loggedUser: { user },
  } = useAuth()
  const location = useLocation()

  if (user && isGranted(user.roles, RoleType.RolePartner)) {
    return children
  } else {
    return <Navigate to="/admin" replace state={{ from: location }} />
  }
}
