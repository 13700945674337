import PublicRequest from "../models/publicRequest"
import PublicRequestPagination from "../models/publicRequestPagination"
import { apiSlice, TAG_PUBLIC_REQUEST } from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPublicRequestInfos: builder.query<PublicRequest, number>({
      query: (id) => ({
        url: `/admin/administrator/public-request/${id}`,
      }),
      providesTags: (result) => [
        { type: TAG_PUBLIC_REQUEST, id: result?.id },
        { type: TAG_PUBLIC_REQUEST, id: "LIST" },
      ],
    }),
    deletePublicRequest: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `/admin/administrator/public-request/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TAG_PUBLIC_REQUEST, id: "LIST" },
      ],
    }),
    getPublicRequestWithPagination: builder.query<
      PublicRequestPagination,
      {
        limit: number
        page: number
        orderBy: string
        direction: string
        from: string
        to: string
      }
    >({
      query: (param) => {
        return {
          url: `/admin/administrator/public-request`,
          params: param,
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: `${TAG_PUBLIC_REQUEST}` as const,
                id,
              })),
              { type: TAG_PUBLIC_REQUEST, id: "LIST" },
            ]
          : [{ type: TAG_PUBLIC_REQUEST, id: "LIST" }],
    }),
    traitedPublicRequest: builder.mutation<PublicRequest, number>({
      query: (id) => ({
        url: `admin/administrator/traited-request/public/${id}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: TAG_PUBLIC_REQUEST, id: "LIST" }],
    }),
    pendingPublicRequest: builder.mutation<PublicRequest, number>({
      query: (id) => ({
        url: `admin/administrator/pending-request/public/${id}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: TAG_PUBLIC_REQUEST, id: "LIST" }],
    }),
  }),
})

export const {
  useGetPublicRequestWithPaginationQuery,
  useGetPublicRequestInfosQuery,
  useDeletePublicRequestMutation,
  useTraitedPublicRequestMutation,
  usePendingPublicRequestMutation,
} = extendedApiSlice
