import Diagnostic from "./Diagnostic"

export enum StatusPubDiagType {
  TO_HANDLE = "ToHandle",
  TRAITED = "Traited",
  PENDING = "Pending",
  ARCHIVED = "Archived",
}

export function getStatusPubDiagOrder() {
  return [
    StatusPubDiagType.TO_HANDLE,
    StatusPubDiagType.TRAITED,
    StatusPubDiagType.PENDING,
  ]
}

export function getStatusTypeLabel(status: StatusPubDiagType | string) {
  switch (status) {
    case StatusPubDiagType.TO_HANDLE:
      return "À traiter"
    case StatusPubDiagType.TRAITED:
      return "Traitée"
    case StatusPubDiagType.PENDING:
      return "En cours de traitement"
    default:
      return status
  }
}

export default interface PublicRequest {
  id: number
  email?: string
  phone?: string
  firstname?: string
  lastname?: string
  status: StatusPubDiagType
  number: string
  createdAt?: string
  diagnostics?: Diagnostic[]
}
