import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useGetUserQuery, useLoginMutation } from "../../features/api/apiSlice"
import {
  selectToken,
  selectUserId,
  logout as handleLogout,
} from "../../features/auth/authSlice"

export const useAuth = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const token = useSelector(selectToken)
  const userid = useSelector(selectUserId)

  // automatically handles logout once the token and userid are removed from redux
  useEffect(() => {
    localStorage.setItem("token", token)
    localStorage.setItem("userid", userid)
  }, [token, userid])

  const {
    data: userData,
    isLoading: isLoadingLoggedUser,
    isSuccess: isUserSuccess,
    error,
    isError,
  } = useGetUserQuery(userid ?? "", {
    skip: !userid,
  })

  const [
    login,
    {
      isError: isLoginRejected,
      error: loginError,
      isSuccess: isLoginSuccessful,
      isLoading,
    },
  ] = useLoginMutation()

  useEffect(() => {
    if (isLoginSuccessful) {
      navigate("/admin")
    }
  }, [isLoginSuccessful, navigate, isUserSuccess])

  const logout = () => {
    localStorage.clear()
    dispatch(handleLogout())
    navigate("/login")
  }

  return {
    loggedUser: useMemo(
      () => ({
        token,
        user: userData?.user,
        isLoading: isLoadingLoggedUser,
        error,
        isError,
      }),
      [token, userData, isLoadingLoggedUser, isError, error]
    ),
    tryLogin: {
      login,
      loginError,
      isLoginRejected,
      isLoginSuccessful,
      isLoading,
    },
    logout,
  }
}
