import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/mentions-bg.jpg"
import { Row } from "react-bootstrap"
import styled from "@emotion/styled"
import colors from "../../style/colors"

const StyledDiv = styled.div`
  padding: 1rem;
  @media (min-width: 768px) {
    background-size: 100%, 40rem, 18rem;
    background-position: left, top 3rem left 1rem, bottom 0 right 1rem;
  }

  a {
    color: ${colors.blue};
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.3rem;
    color: ${colors.darkBlue};
  }

  p {
    color: black;
  }

  ol {
    padding-left: 2.5rem;
    list-style: outside decimal;
    li {
      padding-left: 0.5rem;
    }
  }
`

function ConfidentialPolitic() {
  return (
    <div>
      <WaveHeader title="Politique de confidentialité" image={bgImage} />
      <StyledDiv className="container-fluid">
        <Row className="justify-content-start fs-6 fs-md-5 pb-5 m-md-5 mt-md-3">
          <p>
            Adresse du site :{" "}
            <a href="https://immotech08.com">
              <strong>immotech08.com</strong>
            </a>
            <br />
            Dénomination sociale : IMMOTECH
          </p>
          <p>Type de site : application web</p>
          <h2>Le but de cette politique de confidentialité</h2>
          <p>
            Le but de cette politique de confidentialité est d’informer les
            utilisateurs de notre site des données personnelles que nous
            recueillerons ainsi que les informations suivantes, le cas échéant :
          </p>
          <ol>
            <li>Les données personnelles que nous recueillerons</li>
            <li>L’utilisation des données recueillies</li>
            <li>Qui a accès aux données recueillies</li>
            <li>Les droits des utilisateurs du site</li>
            <li>La politique de cookies du site</li>
          </ol>
          <p>
            Cette politique de confidentialité fonctionne parallèlement aux
            conditions générales d’utilisation de notre site.
          </p>
          <h2>Lois applicables</h2>
          <p>
            Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n°
            2004-801 du 6 août 2004 relative à l’informatique, aux fichiers et
            aux libertés.
          </p>
          <p>
            Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie
            numérique.
          </p>
          <p>
            Conformément au{" "}
            <em>Règlement général sur la protection des données</em> (RGPD),
            cette politique de confidentialité est conforme aux règlements
            suivants.{" "}
          </p>
          <p>Les données à caractère personnel doivent être :</p>
          <ol>
            <li>
              traitées de manière licite, loyale et transparente au regard de la
              personne concernée (licéité, loyauté, transparence) ;
            </li>
            <li>
              collectées pour des finalités déterminées, explicites et
              légitimes, et ne pas être traitées ultérieurement d’une manière
              incompatible avec ces finalités; le traitement ultérieur à des
              fins archivistiques dans l’intérêt public, à des fins de recherche
              scientifique ou historique ou à des fins statistiques n’est pas
              considéré, conformément à l’article 89, paragraphe 1, comme
              incompatible avec les finalités initiales (limitation des
              finalités) ;
            </li>
            <li>
              adéquates, pertinentes et limitées à ce qui est nécessaire au
              regard des finalités pour lesquelles elles sont traitées
              (minimisation des données) ;
            </li>
            <li>
              exactes et, si nécessaire, tenues à jour; toutes les mesures
              raisonnables doivent être prises pour que les données à caractère
              personnel qui sont inexactes, eu égard aux finalités pour
              lesquelles elles sont traitées, soient effacées ou rectifiées sans
              tarder (exactitude) ;
            </li>
            <li>
              conservées sous une forme permettant l’identification des
              personnes concernées pendant une durée n’excédant pas celle
              nécessaire au regard des finalités pour lesquelles elles sont
              traitées; les données à caractère personnel peuvent être
              conservées pour des durées plus longues dans la mesure où elles
              seront traitées exclusivement à des fins archivistiques dans
              l’intérêt public, à des fins de recherche scientifique ou
              historique ou à des fins statistiques conformément à l’article 89,
              paragraphe 1, pour autant que soient mises en œuvre les mesures
              techniques et organisationnelles appropriées requises par le
              règlement afin de garantir les droits et libertés de la personne
              concernée (limitation de la conservation) ;
            </li>
            <li>
              traitées de façon à garantir une sécurité appropriée des données à
              caractère personnel, y compris la protection contre le traitement
              non autorisé ou illicite et contre la perte, la destruction ou les
              dégâts d’origine accidentelle, à l’aide de mesures techniques ou
              organisationnelles appropriées (intégrité et confidentialité).
            </li>
          </ol>
          <p>
            Le traitement n’est licite que si, et dans la mesure où, au moins
            une des conditions suivantes est remplie :
          </p>
          <ol>
            <li>
              la personne concernée a consenti au traitement de ses données à
              caractère personnel pour une ou plusieurs finalités spécifiques ;
            </li>
            <li>
              le traitement est nécessaire à l’exécution d’un contrat auquel la
              personne concernée est partie ou à l’exécution de mesures
              précontractuelles prises à la demande de celle-ci ;
            </li>
            <li>
              le traitement est nécessaire au respect d’une obligation légale à
              laquelle le responsable du traitement est soumis ;
            </li>
            <li>
              le traitement est nécessaire à la sauvegarde des intérêts vitaux
              de la personne concernée ou d’une autre personne physique ;
            </li>
            <li>
              le traitement est nécessaire à l’exécution d’une mission d’intérêt
              public ou relevant de l’exercice de l’autorité publique dont est
              investi le responsable du traitement ;
            </li>
            <li>
              le traitement est nécessaire aux fins des intérêts légitimes
              poursuivis par le responsable du traitement ou par un tiers, à
              moins que ne prévalent les intérêts ou les libertés et droits
              fondamentaux de la personne concernée qui exigent une protection
              des données à caractère personnel, notamment lorsque la personne
              concernée est un enfant.
            </li>
          </ol>
          <p>
            Si nous constatons des incohérences, nous modifierons notre
            politique pour nous conformer à la loi pertinente.
          </p>
          <p>
            Tout litige en relation avec l’utilisation du site{" "}
            <a href="https://immotech08.com">immotech08.com</a>
            est soumis au droit français. Il est fait attribution exclusive de
            juridiction aux tribunaux compétents de Paris.
          </p>
          <h2>Consentement</h2>
          <p>
            Les utilisateurs conviennent qu’en utilisant notre site, ils
            consentent à :
          </p>
          <ol>
            <li>
              les conditions énoncées dans la présente politique de
              confidentialité et
            </li>
            <li>
              la collecte, l’utilisation et la conservation des données
              énumérées dans la présente politique.
            </li>
          </ol>
          <h2>Données personnelles que nous collectons</h2>
          <h3>Données collectées automatiquement</h3>
          <p>Aucune donnée collectée automatiquement</p>
          <h3>Données recueillies de façon non automatique</h3>
          <p>
            Nous pouvons également collecter les données suivantes lorsque vous
            effectuez certaines fonctions sur notre site :
          </p>
          <ol>
            <li>Prénom et nom</li>
            <li>Email</li>
            <li>Date de dernière connexion</li>
            <li>Commandes</li>
            <li>Demandes de remboursement</li>
            <li>
              Informations personnelles indiquées via votre profil : membres de
              la famille (nom, prénom, date de naissance), documents
            </li>
          </ol>
          <p>
            Ces données peuvent être recueillies au moyen des méthodes suivantes
            :
          </p>
          <p>Utilisation du formulaire de contact</p>
          <p>Utilisation du formulaire pour ajouter une commande</p>
          <p>
            Utilisation du formulaire pour ajouter une demande de remboursement
          </p>
          <p>Renseignement du profil utilisateur</p>
          <p>Ajout de documents dans le profil utilisateur</p>
          <p>
            Veuillez noter que nous ne collectons que les données qui nous
            aident à atteindre l’objectif énoncé dans cette politique de
            confidentialité. Nous ne recueillerons pas de données
            supplémentaires sans vous en informer au préalable.
          </p>
          <h2>Comment nous utilisons les données personnelles</h2>
          <p>
            Les données personnelles recueillies sur notre site seront utilisées
            uniquement aux fins précisées dans la présente politique ou
            indiquées sur les pages pertinentes de notre site. Nous
            n’utiliserons pas vos données au-delà de ce que nous divulguerons.
          </p>
          <p>
            Les données que nous recueillons lorsque l’utilisateur exécute
            certaines fonctions peuvent être utilisées aux fins suivantes :
          </p>
          <ol>
            <li>Recontacter</li>
            <li>Traiter les commandes et demandes de remboursement</li>
          </ol>
          <h2>Avec qui nous partageons les données personnelles</h2>
          <h3>
            Employés responsable de la gestion d'Immotech et ces partenaires
          </h3>
          <p>
            Nous pouvons divulguer à tout membre de notre organisation les
            données utilisateur dont il a raisonnablement besoin pour réaliser
            les objectifs énoncés dans la présente politique.
          </p>
          <h3>Tierces parties</h3>
          <p>
            Nous ne partageons pas vos données personnelles à des parties
            tierces
          </p>
          <h3>Autres divulgations</h3>
          <p>
            Nous nous engageons à ne pas vendre ou partager vos données avec des
            tiers, sauf dans les cas suivants :
          </p>
          <ol>
            <li>si la loi l’exige</li>
            <li>si elle est requise pour toute procédure judiciaire</li>
            <li>pour prouver ou protéger nos droits légaux</li>
          </ol>
          <p>
            Si vous suivez des hyperliens de notre site vers un autre site,
            veuillez noter que nous ne sommes pas responsables et n’avons pas de
            contrôle sur leurs politiques et pratiques de confidentialité.
          </p>
          <h2>Combien de temps nous stockons les données personnelles</h2>
          <p>
            Nous ne conservons pas les données des utilisateurs au-delà de ce
            qui est nécessaire pour atteindre les fins pour lesquelles elles
            sont recueillies.
          </p>
          <h2>Comment nous protégeons vos données personnelles</h2>
          <p>
            Afin d’assurer la protection de votre sécurité, nous utilisons le
            protocole de sécurité de la couche transport pour transmettre des
            renseignements personnels dans notre système.
          </p>
          <h2>Mineurs</h2>
          <p>
            Le RGPD précise que les personnes de moins de 15 ans sont
            considérées comme des mineurs aux fins de la collecte de données.
            Les mineurs doivent avoir le consentement d’un représentant légal
            pour que leurs données soient recueillies, traitées et utilisées.
          </p>
          <h2>Vos droits en tant qu’utilisateur</h2>
          <p>
            En vertu du RGPD, les utilisateurs ont les droits suivants en tant
            que personnes concernées :
          </p>
          <ol>
            <li>droit d’accès</li>
            <li>droit de rectification</li>
            <li>droit à l’effacement</li>
            <li>droit de restreindre le traitement</li>
            <li>droit à la portabilité des données</li>
            <li>droit d’objection</li>
          </ol>
          <p>
            Vous trouverez de plus amples informations sur ces droits au
            chapitre 3 (art 12-23) du RGPD.
            <br />
            <a href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3">
              https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3
            </a>
          </p>
          <h2>
            Comment modifier, supprimer ou contester les données recueillies
          </h2>
          <p>
            Si vous souhaitez que vos renseignements soient supprimés ou
            modifiés d’une façon ou d’une autre, veuillez communiquer avec notre
            agent de protection de la vie privée ici :
          </p>
          <p>
            <a href="mailto:contact@immotech08.com">contact@immotech08.com</a>
          </p>
          <h2>Politique sur les cookies</h2>
          <p>
            Un cookie est un petit fichier, stocké sur le disque dur d’un
            utilisateur par le site Web. Son but est de recueillir des données
            relatives aux habitudes de navigation de l’utilisateur.
          </p>
          <p>Nous utilisons les types de cookies suivants sur notre site :</p>
          <strong>Cookies fonctionnels</strong>
          <br />
          <p>
            Nous les utilisons pour mémoriser toutes les sélections que vous
            faites sur notre site afin qu’elles soient sauvegardées pour vos
            prochaines visites.
          </p>
          <h2>Modifications</h2>
          <p>
            Cette politique de confidentialité peut être modifiée à l’occasion
            afin de maintenir la conformité avec la loi et de tenir compte de
            tout changement à notre processus de collecte de données. Nous
            recommandons à nos utilisateurs de vérifier notre politique de temps
            à autre pour s’assurer qu’ils soient informés de toute mise à jour.
            Au besoin, nous pouvons informer les utilisateurs par courriel des
            changements apportés à cette politique.
          </p>
          <h2>Contact</h2>
          <p>
            Si vous avez des questions à nous poser, n’hésitez pas à communiquer
            avec nous en utilisant ce qui suit :<br />
            <a href="mailto:contact@immotech08.com">contact@immotech08.com</a>
            <br />
            IMMOTECH, 5 Place Guy de Montagnac, 08140 Bazeilles
          </p>
          <p>
            <em>Date d’entrée en vigueur :</em> le 14 décembre 2022
          </p>
        </Row>
      </StyledDiv>
    </div>
  )
}

export default ConfidentialPolitic
