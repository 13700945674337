import { css, Global } from "@emotion/react"
import bgShape from "../assets/forme.png"

function GlobalBgStyle(props: any) {
  //let bgImg = props.img
  return (
    <Global
      styles={css`
        #main-container {
          background-color: whitesmoke;
          background-image: url(${bgShape}), url(${bgShape});
          background-size: 29rem auto, 36rem auto !important;
          background-position: left -15rem top -3rem, right -15rem bottom -6rem;
          background-repeat: no-repeat;
        }
      `}
    />
  )
}

export default GlobalBgStyle
