import styled from "@emotion/styled"
import { faEye } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@mui/material"
import { Row } from "react-bootstrap"
interface ImageProps {
  path: string
  alt: string
  name: string
}

interface CardProps {
  key: number
  title: string
  content: string
  image: ImageProps
  labelChips: string
  labelButton: string
  className: string
  buttonOnClick?: () => void
}

const StyledCard = styled.div`
  box-shadow: 0px 5px 35px #00000008;
  border-radius: 7px;
  background-color: white;
  margin-bottom: 2rem;
`

const StyledChips = styled.div`
  position: relative;
`

const StyledSpan = styled.span`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  color: #6c6c6c;
  font-size: 0.8rem;
  position: absolute;
  right: 0rem;
  top: 0.5rem;
  padding: 0.2rem 0.8rem;
`

function Card(cardProps: CardProps) {
  return (
    <StyledCard className={cardProps.className}>
      <StyledChips>
        <StyledSpan className="shadow-md">{cardProps.labelChips}</StyledSpan>
      </StyledChips>
      <div className="flex flex-col justify-between h-full">
        <div>
          <Row>
            <img
              src={cardProps.image?.path}
              alt={cardProps.image?.alt}
              className="img-fluid"
              style={{ padding: 0, height: "14rem", objectFit: "cover" }}
            />
          </Row>
          <Row
            style={{ padding: "1rem", fontWeight: "bold", fontSize: "1.1rem" }}
          >
            <span>{cardProps.title}</span>
          </Row>
          <Row style={{ padding: "0 1rem 1rem 1rem", fontWeight: 100 }}>
            <span className="whitespace-pre-line">{cardProps.content}</span>
          </Row>{" "}
        </div>
        <Row className="p-3">
          <Button
            onClick={cardProps.buttonOnClick}
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faEye} />}
          >
            {cardProps.labelButton}
          </Button>
        </Row>
      </div>
    </StyledCard>
  )
}

export default Card
