import { Form } from "react-bootstrap"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Link, useParams } from "react-router-dom"
import { API } from "../../utils/API"
import styled from "@emotion/styled"
import colors from "../../style/colors"
import { LoadingButton } from "@mui/lab"
import { ResponseApiException } from "../../models/responseApiType"
import { getMessageFromType } from "../../utils/utils"
import { toast } from "react-toastify"
import bgLogin from "../../assets/bg-login.jpg"

const StyledDiv = styled.div`
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90vw;
  align-self: center;
  border-radius: 15px;
  box-shadow: grey 2px 2px 15px;
  @media (min-width: 576px) {
    padding: 2rem;
    width: 30rem;
  }

  h2 {
    color: ${colors.blue};
    font-weight: bold;
  }
`

const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${colors.blue};
  background-image: linear-gradient(
      180deg,
      rgba(0, 99, 152, 0.6),
      rgba(0, 99, 152, 0.5)
    ),
    url(${bgLogin});
  background-repeat: no-repeat;
  background-position: left 0 top 0, center center;
  background-size: cover, 100%;
  min-height: 100vh; ;
`

function ResetPasswordToken() {
  const [validated, setValidated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [match, setMatch] = useState(false)
  const [msg, setMsg] = useState("")
  const [formState, setFormState] = useState<any>({
    plainPassword: {
      first: "",
      second: "",
    },
  })

  let { token } = useParams()

  const canSubmit = !isLoading

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev: any) => ({
      ...prev,
      plainPassword: { ...prev.plainPassword, [name]: value },
    }))
  }
  useEffect(() => {
    setMatch(formState.plainPassword?.first === formState.plainPassword?.second)
  }, [formState])

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
      return
    } else {
      setIsLoading(true)
      //reset
      API.post(`reset-password/reset/${token}`, formState)
        .then((response: any) => {
          if (response.data) {
            API.post(`reset-password/reset`, {
              form: formState,
              id: response.data?.session_id,
            })
              .then((response: any) => {
                setMsg(response.data)
              })
              .catch((error: any) => {
                let response: ResponseApiException = error.response
                let msg = getMessageFromType(response)
                toast.error(msg)
              })
              .finally(() => {
                setIsLoading(false)
              })
          }
        })
        .catch((error: any) => {
          setIsLoading(false)
          let response: ResponseApiException = error.response
          let msg = getMessageFromType(response)
          toast.error(msg)
        })
    }
  }

  return (
    <StyledContainer>
      <h1 className="text-center p-4 pb-5" style={{ color: "white" }}>
        Immotech
      </h1>
      <StyledDiv>
        <h2 className="text-center pb-4 fs-4">
          Réinitialisation du mot de passe
        </h2>
        <Form
          onSubmit={(e) => handleSubmit(e)}
          validated={validated}
          noValidate
        >
          {!msg && (
            <div className="mb-3">
              Veuillez indiquer votre nouveau mot de passe (min. 8 caractères)
            </div>
          )}
          {msg && (
            <div className="mb-3 fst-italic text-center">
              <FontAwesomeIcon icon={faCheckCircle} color="green" /> {msg}{" "}
              <br />
              <Link to="/login" style={{ color: colors.blue }}>
                Se connecter
              </Link>
            </div>
          )}
          <Form.Group className="mb-3" controlId="formBasicPlainPassword">
            <Form.Control
              required
              minLength={8}
              type="password"
              placeholder="Entrez votre mot de passe"
              onChange={handleChange}
              name="first"
              isInvalid={
                validated && formState.plainPassword?.first?.length < 8
              }
            />
            <Form.Control.Feedback type="invalid">
              Le mot de passe doit faire au moins 8 caractères
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPlainPassword">
            <Form.Control
              required
              minLength={8}
              type="password"
              placeholder="Répétez votre mot de passe"
              onChange={handleChange}
              name="second"
              isInvalid={validated && !match}
            />
            <Form.Control.Feedback type="invalid">
              Les mots de passe ne correspondent pas
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex justify-content-center mt-4 w-100">
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              disabled={!canSubmit}
              color="info"
            >
              Valider
            </LoadingButton>
          </div>
        </Form>
        <div className="mt-4 text-end">
          <Link to="/login" style={{ color: colors.blue, fontSize: "0.9rem" }}>
            Retour à la page de connexion
          </Link>
        </div>
      </StyledDiv>
    </StyledContainer>
  )
}

export default ResetPasswordToken
