import { css, Global } from "@emotion/react"
import colors from "./colors"

function GlobalStyle() {
  return (
    <Global
      styles={css`
        @import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,700;1,200;1,900&display=swap");

        body {
          font-family: "Nunito", Helvetica, sans-serif !important;
          font-size: 100%;
          margin: 0;
          padding: 0;
          background-color: #fafafa;
        }

        main {
          margin-left: 36px !important;
          padding: 4rem 0.8rem 0.8rem 0.6rem;
          min-height: 100vh;
          justify-content: center;
          display: flex;
          background-color: ${colors.blue};

          @media (min-width: 576px) {
            margin-left: 49px !important;
          }
        }

        #main-container {
          background: whitesmoke; /*rgba(1, 97, 152, 0.5);*/
          border-radius: 20px;
          width: 100%;
          padding: 1rem 0 1rem 0;
          justify-content: center;
          display: flex;
          margin-bottom: 2rem;

          @media (min-width: 768px) {
            padding: 1rem;
          }
        }
      `}
    />
  )
}

export default GlobalStyle
