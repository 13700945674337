import Article from "../models/article"
import ArticlePagination from "../models/articlePagination"
import { apiSlice, TAG_ARTICLE } from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getArticlesWithPagination: builder.query<
      ArticlePagination,
      { limit: number; page: number; orderBy: string; direction: string }
    >({
      query: (param) => {
        return { url: "articles", params: param }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: `${TAG_ARTICLE}` as const,
                id,
              })),
              { type: TAG_ARTICLE, id: "LIST" },
            ]
          : [{ type: TAG_ARTICLE, id: "LIST" }],
    }),
    addArticle: builder.mutation<Article, Article>({
      query: (body) => ({
        url: `admin/administrator/articles`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: TAG_ARTICLE, id: "LIST" }],
    }),
    editArticle: builder.mutation<Article, any>({
      query: ({ id, body }) => ({
        url: `admin/administrator/articles/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TAG_ARTICLE, id: "LIST" },
        { type: TAG_ARTICLE, id: arg.id },
      ],
    }),
    getArticle: builder.query<Article, string>({
      query: (id) => `articles/${id}`,
      providesTags: (result, error, id) => [{ type: TAG_ARTICLE, id }],
    }),
    deleteArticle: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `admin/administrator/articles/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TAG_ARTICLE, id: "LIST" },
      ],
    }),
  }),
})

export const {
  useGetArticlesWithPaginationQuery,
  useGetArticleQuery,
  useAddArticleMutation,
  useEditArticleMutation,
  useDeleteArticleMutation,
} = extendedApiSlice
